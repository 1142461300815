import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../_metronic/layout"
import { loadUserLogin } from "../../../service/userManagementAction"
import { useUrlParam } from "../../../hooks/useUrlParams"
import { useLocation } from "react-router"
import { useHistory } from "react-router-dom"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { formatterRowComponent } from "../../../../component/atoms/Tabel"
import { sortCaret } from "../../../../_metronic/_helpers"
import { GetListFoCoordinator } from "../../../../redux/actions/FieldOfficerAction"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon, EditSvgIcon } from "../../../../component/atoms/Icons/SVG"
import { EmptyCard, Search, TableSkeleton, TableView } from "../../../../component"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import { linkWithCtrl } from "../../../helpers/Utils"
import {
  DATE_TYPE_ENUM,
  FIELD_OFFICER_ROLE_ENUM,
  ROUTING_STATUS
} from "../../../../constants/StringConstant"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"
import { checkValue } from "../../../helpers/TextHelper.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"

export const ListFoCoordinator = () => {
  const { t } = useTranslation()
  const [dataList, setDataList] = useState([])
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [search, setSearch] = useState({ string_filter: "" })
  const [queryStringObj] = useState(undefined)
  const { FieldOfficer, General } = useSelector((state) => state)
  const subheader = useSubheader()
  const privileges = loadUserLogin().privilege
  const formRef = useRef()
  const urlParam = useUrlParam()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    subheader.setTitle(t("sub_header.field_supervisor"))
    subheader.setButton(null)
  }, [])

  useEffect(() => {
    if (FieldOfficer?.pageData) {
      setDataList(FieldOfficer.pageData)
    }
  }, [General, FieldOfficer])

  useEffect(() => {
    if (queryStringObj?.string_filter) {
      setSearch({ string_filter: queryStringObj?.string_filter })
      setIsActiveSearch(true)
    }
  }, [queryStringObj])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param.sortField + "," + param.sortOrder,
          string_filter: search.string_filter
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          string_filter: search.string_filter
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [search]
  )

  function onSearch(value) {
    setSearch({ string_filter: value.string_filter })
    setIsActiveSearch(true)
    let param = {
      string_filter: value.string_filter
    }
    const url = urlParam.stringifyObj(param)
    history.push({ pathname: location.pathname, search: url })
  }

  function fetchData(param) {
    return dispatch(GetListFoCoordinator(param))
  }

  function resetSearch() {
    urlParam.resetStringFilter()
    setSearch({ string_filter: "" })
  }

  const uiCode = (e) => {
    return <div className={"font-weight-bolder"}>{checkValue(e?.code)}</div>
  }

  const uiName = (e) => {
    return (
      <div>
        <div className={"font-weight-bolder"}>{checkValue(e?.name)}</div>
        <div>{checkValue(e?.phone_number)}</div>
      </div>
    )
  }

  const UiDate = (e) => {
    return (
      <div>
        <div>{checkValue(timeStampToDate(e.approve_date, DATE_TYPE_ENUM.ISO_DATE_STRAP))}</div>
        <div>{checkValue(timeStampToDate(e.approve_date, DATE_TYPE_ENUM.ISO_TIME_STRAP))}</div>
      </div>
    )
  }

  const uiStatus = (e) => {
    return (
      <Badge
        variant={e?.status_enum === ROUTING_STATUS.STATUS_ENUM.ACTIVE ? "primary" : "secondary"}>
        {checkValue(e.status_string)}
      </Badge>
    )
  }

  const UiActions = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_COORDINATOR) > 1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() =>
                history.push(
                  RoutesConstants.FIELD_OFFICER.DETAIL_FO(
                    FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR,
                    e?.id
                  )
                )
              }>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_FIELD_OFFICER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO(e.id))}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  const columnProperties = [
    {
      dataField: "fo_code",
      text: t("table_header.id_fs").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "name",
      text: t("label.name").toUpperCase(),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "full_address",
      text: t("label.address").toUpperCase(),
      headerStyle: () => {
        return { width: "45%" }
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "createdDate",
      text: t("label.created_date").toUpperCase(),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiDate
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiActions
      },
      headerStyle: () => {
        return { width: "12%" }
      }
    }
  ]

  return (
    <>
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={t("placeholder.insert_field_supervisor_search")}
            onSubmitForm={onSearch}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            trigerReset={resetSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : dataList?.length == 0 ? (
            <EmptyCard
              image={
                isActiveSearch
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_DATA
              }
              body={
                isActiveSearch
                  ? t("messages.response.your_search_was_not_found")
                  : t("messages.response.empty_state", { name: t("label.field_supervisor") })
              }
              subtitle={isActiveSearch ? t("messages.response.search_not_found_subtitle") : ""}
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={dataList ?? []}
            loadingGetData={General?.loading}
            callbackAfterPageChange={changePage}
            columnProperties={columnProperties}
            currentPage={FieldOfficer?.page?.currentPage ?? 0}
            currentElement={FieldOfficer?.page?.currentElement ?? 10}
            totalCount={FieldOfficer?.page?.totalCount ?? 0}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_COORDINATOR) > 1) {
                  if (e.target.cellIndex < 5) {
                    const url = RoutesConstants.FIELD_OFFICER.DETAIL_FO(
                      FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR,
                      row?.id
                    )
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
