import { t } from "i18next"
import React, { useCallback, useEffect, useState } from "react"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG"
import { formatterRowComponent, initialFilterTable } from "../../../../../component/atoms/Tabel"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { getListHistoryTarget } from "../../../../../redux/actions/FieldOfficerAction.jsx"
import { checkValue } from "../../../../helpers/TextHelper"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
export function KpiGoalSettingHistory() {
  const dispatch = useDispatch()
  const { General, FieldOfficer } = useSelector((state) => state)
  const history = useHistory()
  const location = useLocation()
  const user = loadUserLogin()
  const regionalId = user?.regional_id

  const [currentParam] = useState({
    ...initialFilterTable
  })


  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...currentParam,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : ""
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...currentParam,
          ...param
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, currentParam]
  )

  useEffect(() => {
    const result = location?.search
    if (regionalId) {
      dispatch(getListHistoryTarget(regionalId, result))
    }
  }, [location.search])

  const tableColumn = [
    {
      text: t("label.year"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>{checkValue(e?.year)}</div>
          </>
        )
      }
    },
    {
      text: t("label.paddy") + " (HA)",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>{checkValue(e?.paddy)} HA</div>
          </>
        )
      }
    },
    {
      text: t("label.corn") + " (HA)",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>{checkValue(e?.corn)} HA</div>
          </>
        )
      }
    },
    {
      text: t("label.corn_production") + " (HA)",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>
              {checkValue(e?.corn_production)} HA
            </div>
          </>
        )
      }
    },
    {
      text: t("label.action"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <Link to={RoutesConstants.FIELD_OFFICER.DETAIL_KPI_TARGET_KPI_BY_YEAR(e.year)}>
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Detail {e.regional_name}</Tooltip>}>
              <div className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
                <DetailSvgIcon />
              </div>
            </OverlayTrigger>
          </Link>
        )
      }
    }
  ]



  return (
    <div>
      <Card className="mt-4">
        <CardBody>
          {General?.loading ? (
            <TableSkeleton column={5} row={10} />
          ) : FieldOfficer?.pageData && FieldOfficer?.pageData.length === 0 ? (
            <EmptyCard
              subtitle={t("messages.response.empty_search_subtitle")}
              image={

                ILLUSTRATION_ASSETS_V2.mitra.IL_EMPTY_STATE
              }
              body={
                t("messages.response.empty_state", { name: "riwayat target" })
              }
            />
          ) : (
            <TableView
              dataTable={FieldOfficer?.pageData || []}
              currentPage={FieldOfficer?.currentPage ?? 1}
              currentElement={FieldOfficer?.currentElement ?? 10}
              totalCount={FieldOfficer?.totalCount ?? 0}
              columnProperties={tableColumn}
              callbackAfterPageChange={changePage}
              loadingGetData={General?.loading}
              initialFilterTable={currentParam}
              ignoreSinglePage
              rowClasses={() => {
                return "cursor-pointer"
              }}
            />
          )}
        </CardBody>
      </Card>
    </div>
  )
}
