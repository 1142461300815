import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import React from "react"

export const MenuBack = ({ onClick, title }) => {
  return (
    <div className={"d-flex align-items-center"}>
      <img className={"pr-6 cursor-pointer"} src={ICON_CONSTANS.IC_BACK} onClick={onClick} />
      <div
        className={
          "h3 mb-0 bold pl-6 border-1 border-left border-left-light-dark font-weight-bolder"
        }>
        {title}
      </div>
    </div>
  )
}
