import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LogList } from "../../atoms/LogList"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../_metronic/_partials/controls"
import { CalenderSvgIcon } from "../../atoms/Icons/SVG/calender"
import { DateRangePicker, defaultStaticRanges } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { addDays, format, isSameDay, startOfDay } from "date-fns"
import { getLogData } from "../../../redux/actions/LogAction"
import { dateSelect } from "../../../app/helpers/DateHelper"
import { EmptyCard } from "../../atoms"
import { Skeleton } from "@material-ui/lab"
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../constants/ImageConstants.js"
import SVG from "react-inlinesvg"
import { useTranslation } from "react-i18next"
import { ENUM_LOG } from "../../../constants/InitTypeConstants.js"

export const TabLog = ({ historyTabEnum, id }) => {
  const dispatch = useDispatch()
  const { Log, General } = useSelector((state) => state)
  const [dateRange, setDateRange] = useState("")
  const [openDate, setOpenDate] = useState(false)
  const [filterDate, setFilterDate] = useState(false)
  const [dataLog, setDataLog] = useState([])
  const { t } = useTranslation()

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ])

  useEffect(() => {
    if (id) {
      const param = {
        id: id
      }
      dispatch(getLogData(historyTabEnum, param))
    }
  }, [id])

  useEffect(() => {
    if (Log?.dataLog) {
      setDataLog(Log.dataLog)
    }
  }, [Log, General])

  const selectDate = (
    <div className={"d-flex justify-content-end mb-8"}>
      <div
        onClick={() => setOpenDate(!openDate)}
        className={"select-date d-flex justify-content-between align-items-center cursor-pointer"}>
        <label className={"mb-0 txt-12 cursor-pointer"}>
          {dateRange ? dateRange : t("placeholder.select_date_range")}
        </label>
        <CalenderSvgIcon />
      </div>
    </div>
  )

  const submitLog = () => {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, "yyyy-MM-dd")) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, "yyyy-MM-dd"))
    setDateRange(selectedDate)
    setFilterDate(true)
    let param = {
      start_date: format(state[valueArray].startDate, "yyyy-MM-dd"),
      end_date: format(state[valueArray].endDate, "yyyy-MM-dd"),
      id: id
    }
    dispatch(getLogData(historyTabEnum, param))
    setOpenDate(false)
  }
  const resetDate = () => {
    setOpenDate(false)
    setFilterDate(false)
    setDateRange("")
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ])
    let param = {
      id: id
    }
    dispatch(getLogData(historyTabEnum, param))
  }

  const noResult = (
    <div className={"d-flex justify-content-center flex-column align-items-center"}>
      <SVG src={toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_EMPTY_STATE_LOG_NO_DATA)} />
      <div className={"mt-6 font-size-h5 font-weight-bolder"}>
        {t("messages.response.log_search_not_found")}
      </div>
      <div className={"text-dark-50 mt-4 mb-4"}>
        {t("messages.response.please_check_the_filter_and_try_again")}
      </div>
      <button onClick={resetDate} type={"button"} className={"btn btn-primary"}>
        Reset Filter
      </button>
    </div>
  )

  const threeLastMont = (
    <div className={"d-flex flex-column align-items-center"}>
      <SVG src={toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_EMPTY_STATE_LOG)} />
      <div className={"mt-6 font-size-h5 font-weight-bolder"}>
        {historyTabEnum === ENUM_LOG.TARGET
          ? t("messages.response.there_have_been_no_recent_logs")
          : t("messages.response.there_have_been_no_recent_logs_in_3_months")}
      </div>
    </div>
  )

  const dateRangePicker = (
    <div className={"position-relative"}>
      <div className={"date-picker-custom"}>
        <DateRangePicker
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="backwards"
          inputRanges={[]}
          staticRanges={[
            ...defaultStaticRanges,
            {
              label: t("label.last_30_days"),
              range: () => ({
                startDate: startOfDay(addDays(new Date(), -30)),
                endDate: startOfDay(addDays(new Date(), 0))
              }),
              isSelected(range) {
                const definedRange = this.range()
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
            },
            {
              label: t("label.last_90_days"),
              range: () => ({
                startDate: startOfDay(addDays(new Date(), -90)),
                endDate: startOfDay(addDays(new Date(), 0))
              }),
              isSelected(range) {
                const definedRange = this.range()
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                )
              }
            }
          ]}
        />
        <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
          <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
            {t("label.reset_date")}
          </button>
          <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
            {t("label.apply")}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Card>
      <CardBody className={"body-log"}>
        {selectDate}
        {openDate && dateRangePicker}
        {General?.loading ? (
          <>
            {Array.from({ length: 10 }).map((item, index) => {
              return (
                <div
                  key={index}
                  className={"log-item d-flex justify-content-between align-items-center"}>
                  <Skeleton animation="wave" variant={"text"} width={300} />
                  <Skeleton animation="wave" variant={"text"} width={100} />
                </div>
              )
            })}
          </>
        ) : dataLog.length === 0 ? (
          <EmptyCard bodyCustom={filterDate ? noResult : threeLastMont} />
        ) : (
          dataLog.map((value, index) => {
            return (
              <Fragment key={index}>
                <LogList data={value.history_data} dateTime={value.date_time} />
              </Fragment>
            )
          })
        )}
      </CardBody>
    </Card>
  )
}
