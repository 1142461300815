import {
  Card,
  CardBody,
  CardHeader
} from "../../../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useEffect } from "react"
import { TextInput } from "../../../../../../../component/index.jsx"
import { useFormikContext } from "formik"
import { t } from "i18next"

export const DataEditPassword = ({ isReadOnly, setIsDisbaled }) => {
  const { values } = useFormikContext()
  useEffect(() => {
    if (values?.password && values?.old_password && values?.confirm_password) {
      setIsDisbaled(false)
    }
  }, [values])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Edit Password" />
        <CardBody>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.old_password")}
              name="old_password"
              placeholder={t("placeholder.input_your_old_password")}
              type={"password"}
              withTogglePass={true}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.new_password")}
              name="password"
              placeholder={t("placeholder.input_your_new_password")}
              type={"password"}
              withTogglePass={true}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label={t("label.confirm_new_password")}
              name="confirm_password"
              placeholder={t("placeholder.input_confirm_your_new_password")}
              type={"password"}
              withTogglePass={true}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
