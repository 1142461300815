import React, { Fragment, useEffect, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { t } from "i18next"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../../../constants/StringConstant.js"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { FormikProvider, useFormik } from "formik"
import { Card } from "react-bootstrap"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { HeaderStepper } from "../../../../../component/atoms/HeaderStepper.jsx"
import { TextFieldAutoComplete } from "../../../../../component/template/GoogleMaterialExamples/inputs/TextFieldAutoComplete.jsx"
import { useDispatch, useSelector } from "react-redux"
import { SelectInput } from "../../../../../component/index.jsx"
import {
  getListFieldManagerByUserLogin,
  getListRegionalHeadByUserLogin
} from "../../../../../redux/actions/MasterDataAction.jsx"
import { requestGet } from "../../../../../config/index.jsx"
import { MASTER_URL } from "../../../../../constants/UrlConstants.js"
import { Add } from "@material-ui/icons"
import * as Yup from "yup"
import { createTeamAssign } from "../../../../../redux/actions/FieldOfficerAction.jsx"
import swal from "sweetalert"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { FIELD_OFFICER } from "../../../../../constants/InitTypeConstants.js"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { useHistory } from "react-router-dom"
import { Popup } from "../../../../../component/atoms/Popup/index.jsx"
import Divider from "@material-ui/core/Divider"

export function CreateEditAssignOpsAdmin() {
  const subheader = useSubheader()
  const dispatch = useDispatch()
  const history = useHistory()
  const { MasterData, General } = useSelector((state) => state)

  const [dataRh, setDataRh] = useState()

  const [listFm, setListFm] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [showModalAssignment, setShowModalAssignment] = useState(false)

  const initValues = {
    field_manager: [
      {
        id: "",
        field_supervisor: [
          {
            id: "",
            list_fo: [],
            field_officer: [""]
          }
        ]
      }
    ],
    field_manager_ids: [],
    regional_head_id: ""
  }

  useEffect(() => {
    if (dataRh?.id) {
      formik.setFieldValue("regional_head_id", dataRh?.id)
    }
  }, [dataRh])

  const validationSchema = Yup.object().shape({
    field_manager: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          field_supervisor: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string().required(),
                list_fo: Yup.array().min(1),
                field_officer: Yup.array().of(Yup.string().required()).min(1)
              })
            )
            .min(1)
        })
      )
      .min(1),
    field_manager_ids: Yup.array().min(1),
    regional_head_id: Yup.string().required()
  })

  useEffect(() => {
    if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === FIELD_OFFICER.CREATE_ASSIGN_TEAM
    ) {
      swal(t("messages.title.success_assign_ops_team"), {
        icon: ILLUSTRATION_ASSETS_V2.SUCCESS_ASSIGN_GENERAL
      }).then()
      history.push(RoutesConstants.FIELD_OFFICER.ASSIGN_OPS_ADMIN())
    }
  }, [General.isFinishUpdateOrDelete, General.actionInitType])

  useEffect(() => {
    if (MasterData?.listRegionalHeadByUserLogin) {
      setDataRh(MasterData.listRegionalHeadByUserLogin[0])
    }
  }, [MasterData?.listRegionalHeadByUserLogin])

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setShowModalAssignment(false)
      const payloadData = {
        regional_head_id: values.regional_head_id,
        field_managers: values.field_manager.map((item) => {
          return {
            id: item.id,
            field_supervisors: item.field_supervisor.map((fs) => {
              return {
                id: fs.id,
                field_officers: fs.field_officer
              }
            })
          }
        })
      }
      dispatch(createTeamAssign(payloadData))
    }
  })

  useEffect(() => {
    dispatch(getListFieldManagerByUserLogin())
    dispatch(getListRegionalHeadByUserLogin())
  }, [])

  useEffect(() => {
    if (MasterData?.listFieldManagerByUserLogin) {
      setListFm(
        MasterData.listFieldManagerByUserLogin.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    }
  }, [MasterData?.listFieldManagerByUserLogin])

  const breadcrumbData = [
    {
      pathname: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
      title: t("menu_aside.ops_team")
    },
    {
      pathname: RoutesConstants.FIELD_OFFICER.ASSIGN_OPS_ADMIN(),
      title: t("label.assign_ops_team")
    },
    {
      title: t("label.assign_team")
    }
  ]

  useEffect(() => {
    subheader.setBreadcrumbs(breadcrumbData)
    subheader.setTitle(t("sub_header.assign_team"))
  }, [])

  useEffect(() => {
    subheader.setButton(buttonSubmit)
  }, [formik.isValid, formik.dirty, activeTab])

  function buttonSubmit() {
    return (
      <button
        onClick={() => setShowModalAssignment(true)}
        className={"btn btn-primary"}
        disabled={!(activeTab === 1 && formik.isValid && formik.dirty)}>
        {t("button.submit")}
      </button>
    )
  }

  const headerData = [
    {
      title: t("label.select_fm"),
      description: t("label.data_fm")
    },
    {
      title: t("label.input_fs_dan_fo"),
      description: t("label.fs_and_fo_data")
    }
  ]

  function checkData(fm, fmId) {
    let initFs = [
      {
        id: "",
        field_officer: [""]
      }
    ]
    if (fm?.id === fmId) {
      const listFs = fm?.list_fs ? fm?.list_fs : []
      const field_supervisor =
        fm?.field_supervisor && fm?.field_supervisor
          ? fm?.field_supervisor.map((e) => {
              return {
                id: e.id,
                field_officer: e.field_officer,
                list_fo: e.list_fo
              }
            })
          : initFs
      return { listFs, field_supervisor }
    } else {
      return {
        field_supervisor: initFs
      }
    }
  }

  function onNext() {
    const initFm = formik.values.field_manager || []
    const dataFm = formik.values.field_manager_ids.map((item, i) => {
      const fm = initFm[i]
      const data = checkData(fm, item)
      return {
        id: listFm.find((e) => e.value === item)?.value,
        name: listFm.find((e) => e.value === item)?.label,
        list_fs: data?.listFs || [],
        field_supervisor: data?.field_supervisor
      }
    })
    formik.setFieldValue("field_manager", dataFm)
    dataFm.map((item, index) => {
      requestGet(MASTER_URL.GET_LIST_FIELD_SUPERVISOR_BY_FM(item.id)).then((res) => {
        const data = res.data.map((e) => {
          return {
            label: e.name,
            value: e.id
          }
        })
        formik.setFieldValue(`field_manager[${index}].list_fs`, data)
      })
    })

    setActiveTab(1)
  }

  function formFm() {
    return (
      <div>
        <div className={"mb-10"}>
          <Card>
            <CardBody>
              <div>
                <h1>{checkValue(dataRh?.name)}</h1>
                <p>
                  {t("label.regional_head")} {checkValue(dataRh?.regional_name)}
                </p>
              </div>
            </CardBody>
          </Card>
        </div>
        <RowModule>
          <TextFieldAutoComplete
            required
            name={"field_manager_ids"}
            editable={true}
            label={t("label.field_manager")}
            placeholder={t("placeholder.select_one")}
            options={listFm}
            value={formik.values.field_manager_ids}
          />
        </RowModule>
        <div className={"d-flex justify-content-end"}>
          <button
            disabled={formik.values.field_manager_ids.length === 0}
            onClick={onNext}
            className={"btn btn-primary"}>
            {t("button.next_full")}
          </button>
        </div>
      </div>
    )
  }

  function onChangeFs(value, name, i, index) {
    formik.setFieldValue(`field_manager[${i}].field_supervisor[${index}].id`, value)
    requestGet(MASTER_URL.GET_LIST_FIELD_OFFICER_BY_FS(value)).then((res) => {
      const listFo = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      formik.setFieldValue(`field_manager[${i}].field_supervisor[${index}].list_fo`, listFo)
    })
  }

  function onClickSupervisor(index) {
    const dataFm = {
      id: "",
      list_fo: [],
      field_officer: [""]
    }
    formik.setFieldValue(`field_manager[${index}].field_supervisor`, [
      ...formik.values.field_manager[index].field_supervisor,
      dataFm
    ])

    requestGet(MASTER_URL.GET_LIST_FIELD_SUPERVISOR_BY_FM(dataFm.id)).then((res) => {
      const data = res.data.map((e) => {
        return {
          label: e.name,
          value: e.id
        }
      })
      formik.setFieldValue(`field_manager[${index}].list_fs`, data)
    })
  }

  function onBack() {
    setActiveTab(0)
  }

  function formFs() {
    return (
      <div>
        {formik.values.field_manager.map((item, i) => (
          <div className={"border-bottom pb-10 mb-10 "} key={item.id}>
            <RowModule>
              <SelectInput
                name={`field_manager[${i}].id`}
                required
                editable={false}
                label={t("label.field_manager")}
                placeholder={t("placeholder.select_one")}
                options={listFm}
                chooseOptionWithLabel={false}
                value={item.id}
              />
            </RowModule>
            {item.field_supervisor.map((supervisor, index) => (
              <Fragment key={index}>
                <RowModule>
                  <SelectInput
                    onChange={(e) => {
                      onChangeFs(e, `field_manager[${i}].field_supervisor[${index}].id`, i, index)
                    }}
                    name={`field_manager[${i}].field_supervisor[${index}].id`}
                    required
                    editable={true}
                    label={t("label.field_supervisor")}
                    options={item.list_fs}
                    chooseOptionWithLabel={false}
                    value={supervisor.id}
                    placeholder={t("placeholder.select_one")}
                  />
                </RowModule>
                <RowModule>
                  <TextFieldAutoComplete
                    required
                    name={`field_manager[${i}].field_supervisor[${index}].field_officer`}
                    editable={true}
                    label={t("label.field_officer")}
                    placeholder={t("placeholder.select_one")}
                    options={supervisor.list_fo}
                    value={
                      formik.values?.field_manager?.[i]?.field_supervisor?.[index]?.field_officer
                    }
                  />
                </RowModule>
              </Fragment>
            ))}

            <div className={"w-100 d-flex justify-content-center "}>
              <div
                onClick={() => onClickSupervisor(i)}
                className={"d-flex align-items-center p-3"}
                style={{ color: "#6CC049", cursor: "pointer" }}>
                <Add fontSize={"large"} style={{ marginRight: "10px" }} />
                <span>{t("button.add_field_supervisor_normal")}</span>
              </div>
            </div>
          </div>
        ))}
        <div className={"d-flex "}>
          <button
            disabled={formik.values.field_manager_ids.length === 0}
            onClick={onBack}
            className={"btn-outline-maxxi btn"}>
            {t("button.previous")}
          </button>
        </div>
      </div>
    )
  }

  function checkComponent() {
    switch (activeTab) {
      case 1:
        return formFs()
      default:
        return formFm()
    }
  }

  function bodyModalSubmit() {
    const height = 48
    return (
      <div>
        <div>
          <div>{t("label.regional_head")}</div>
          <div style={{ fontWeight: "bold", fontSize: 16 }}>{checkValue(dataRh?.name)}</div>
          <Divider />
        </div>
        <div style={{ maxHeight: 400, overflowY: "auto", overflowX: "hidden" }}>
          {formik.values &&
            formik.values.field_manager.map((item, i) => (
              <div key={i} className={"py-4"}>
                <RowModule>
                  <div>
                    <div className={"capitalize"}>{t("label.field_manager")}</div>
                    <div
                      style={{ color: "black", fontWeight: "500" }}
                      className={"fw-bold text-black"}>
                      {item.name}
                    </div>
                  </div>
                  <div>
                    {item.field_supervisor.map((fs, fIndex) => (
                      <div key={fIndex}>
                        <div style={{ height: height }}>
                          <div className={"capitalize"}>{t("label.field_supervisor")}</div>
                          <div
                            className={"fw-bold text-black"}
                            style={{ color: "black", fontWeight: "500" }}>
                            {fs?.id ? item.list_fs.find((e) => e.value === fs.id)?.label : "-"}
                          </div>
                        </div>
                        <div>
                          {Array.from({ length: fs.field_officer.length - 1 }).map((_, i) => (
                            <div key={i} style={{ height: height }}></div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    {item.field_supervisor.map((fs, fIndex) => (
                      <Fragment key={fIndex}>
                        {fs.field_officer.map((fo, foIndex) => (
                          <div key={foIndex} style={{ height: height }}>
                            <div className={"capitalize"}>{t("label.field_officer")}</div>
                            <div
                              className={"fw-bold text-black"}
                              style={{ color: "black", fontWeight: "500" }}>
                              {fo ? fs?.list_fo?.find((e) => e.value === fo)?.label : "-"}
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    ))}
                  </div>
                </RowModule>
                <Divider />
              </div>
            ))}
        </div>
        <div className={"mt-4 capitalize"}>
          <RowModule>
            <button
              onClick={() => setShowModalAssignment(false)}
              className={"btn btn-outline-light w-100"}>
              {t("button.cancel")}
            </button>
            <button onClick={() => formik.handleSubmit()} className={"btn btn-primary w-100"}>
              {t("button.submit")}
            </button>
          </RowModule>
        </div>
      </div>
    )
  }

  return (
    <>
      <Popup
        size={"xl"}
        show={showModalAssignment}
        onCancel={() => setShowModalAssignment(false)}
        title={t("label.confirmation").toUpperCase()}
        bodyCustom={bodyModalSubmit()}
        showButton={false}
        onClick={() => {
          setShowModalAssignment(false)
        }}
      />
      <Card>
        <div className={"border-bottom d-flex"}>
          {headerData.map((item, i) => (
            <HeaderStepper
              activeIndex={activeTab}
              key={i}
              index={i}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
        <CardBody>
          <FormikProvider value={formik}>{checkComponent()}</FormikProvider>
        </CardBody>
      </Card>
    </>
  )
}
