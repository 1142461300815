import { getUrlParsingLog } from "../../config/EndpointCollection.js"
import { LogSlice } from "../reducers/Log"
import { GeneralSlice } from "../reducers/General"
import { ENUM_LOG } from "../../constants/InitTypeConstants"
import { requestGet } from "../../config"
import { LOG_URL } from "../../constants/UrlConstants"

const { actions: log } = LogSlice
const { actions: general } = GeneralSlice

export const getLogData = (dataEnum, param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingLog(param)
    dispatch(
      general.setLoading({ loading: true, actionInitType: dataEnum ? dataEnum : ENUM_LOG.FARMER })
    )
    try {
      let res = await requestGet(LOG_URL.GET_LOG + dataEnum + urlData)
      dispatch(log.setData(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: dataEnum ? dataEnum : ENUM_LOG.FARMER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
