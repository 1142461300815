import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import {
  clearDataFO,
  deactivateStatusUserFO,
  getDetail,
  updateImage
} from "../../../../redux/actions/FieldOfficerAction.jsx"
import { clearUploadAction } from "../../../../redux/actions/MasterDataAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { TabsComponent } from "../../../../component/atoms/Tab/index.jsx"
import { DetailOfficer } from "./tab/DetailFo.jsx"
import { ListMitra } from "./tab/DetailMitra.jsx"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { ENUM_LOG, FIELD_OFFICER, GENERAL } from "../../../../constants/InitTypeConstants.js"
import { TabLog } from "../../../../component/index.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import {
  FIELD_OFFICER_ROLE_ENUM,
  ROUTING_STATUS,
  FO_UPLOAD_ENUM
} from "../../../../constants/StringConstant.js"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"
import { ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants.js"
import { checkValue } from "../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"

export const DetailWrapper = ({
  history,
  match: {
    params: { id, role, tab }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { FieldOfficer, General, MasterData } = useSelector((state) => state)
  const [showPopupChangeStatus, setShowPopupChangeStatus] = useState(false)
  const [reasonChangeStatus, setReasonChangeStatus] = useState(null)
  const { t } = useTranslation()

  useEffect(async () => {
    dispatch(getDetail(id))
  }, [id])

  useEffect(() => {
    role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA
      ? subHeader.setBreadcrumbs([
          {
            pathname: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
            title: t("sub_header.fo")
          },
          {
            pathname:
              role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA &&
              RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.ACTIVE),
            title:
              role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA && t("sub_header.fo_quality_assurance")
          },
          role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA && {
            pathname:
              tab === ROUTING_STATUS.STATUS_STRING.ACTIVE
                ? RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.ACTIVE)
                : RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.INACTIVE),
            title:
              tab === ROUTING_STATUS.STATUS_STRING.ACTIVE ? t("label.active") : t("label.inactive")
          },
          {
            title: FieldOfficer?.detail?.name
          }
        ])
      : subHeader.setBreadcrumbs([
          {
            pathname: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
            title: t("sub_header.fo")
          },
          {
            pathname:
              role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST
                ? RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("list-fo")
                : RoutesConstants.FIELD_OFFICER.LIST_FO(role),
            title: checkValue(FieldOfficer?.detail.role_string)
          },
          {
            title: FieldOfficer?.detail?.name
          }
        ])

    subHeader.setTitle(null)
  }, [FieldOfficer?.detail, role, id])

  useEffect(() => {
    dispatch(clearDataFO())
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getDetail(id))
      if (privileges.indexOf(PRIVILEGE_ENUM.EDIT_FIELD_OFFICER) > -1)
        subHeader.setButton(
          <Button
            variant="secondary"
            onClick={() =>
              typeof tab !== "undefined"
                ? history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO_QA(role, tab, id))
                : history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO(id))
            }>
            Edit
          </Button>
        )
    }
  }, [dispatch])

  useEffect(() => {
    subHeader.setButton(
      <Button
        className={
          FieldOfficer.detail?.field_officer_activation === ROUTING_STATUS.STATUS_ENUM.ACTIVE
            ? "btn btn-outline-primary"
            : "btn btn-dark"
        }
        disabled={
          FieldOfficer.detail?.field_officer_activation === ROUTING_STATUS.STATUS_ENUM.INACTIVE
        }
        onClick={() => {
          if (FieldOfficer.detail?.field_officer_activation === ROUTING_STATUS.STATUS_ENUM.ACTIVE) {
            typeof tab !== "undefined"
              ? history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO_QA(role, tab, id))
              : history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO(id))
          }
        }}>
        Edit
      </Button>
    )
  }, [FieldOfficer.detail?.field_officer_activation])

  useEffect(() => {
    dispatch(clearUploadAction())
  }, [])

  useEffect(() => {
    if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === FIELD_OFFICER.DEACTIVATE_FIELD_OFFICER
    ) {
      setShowPopupChangeStatus(false)
      setReasonChangeStatus(null)
      dispatch(getDetail(id))
      subHeader.setButton(
        <Button className="btn btn-dark" disabled={true}>
          Edit
        </Button>
      )
      swal({
        text: t("messages.response.field_officer_successfully_deactivated"),
        icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS)
      })
    } else if (General.isFinishUpdateOrDelete && General.actionInitType === GENERAL.UPLOAD) {
      const data = {
        upload_enum: FO_UPLOAD_ENUM.AVATAR,
        url: MasterData.urlResponseUpload
      }
      dispatch(updateImage({ id, data }))
    } else if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === FIELD_OFFICER.UPDATE_IMAGE
    ) {
      dispatch(getDetail(id))
    }
  }, [General.isFinishUpdateOrDelete])

  const labels = ["DETAIL FO", "MITRA", "LOG"]
  const components = [
    <DetailOfficer
      key={0}
      data={FieldOfficer.detail}
      activeUser={FieldOfficer.detail?.field_officer_activation}
      inactiveUser={inactiveUser}
      id={id}
    />,
    <ListMitra history={history} key={1} data={FieldOfficer.detail.mitra_child_list || []} />,
    <TabLog key={2} historyTabEnum={ENUM_LOG.STAFF} id={id} />
  ]

  function inactiveUser() {
    setShowPopupChangeStatus(true)
  }

  function handleSubmitChangeStatusUser() {
    dispatch(deactivateStatusUserFO(id, { reason: reasonChangeStatus }))
  }

  return (
    <>
      <PopupQuestion
        show={showPopupChangeStatus}
        title={t("messages.title.change_status_fo")}
        bodyCustom={
          <Form.Group className="mb-3">
            <Form.Label>
              {t("messages.confirmation.add_reason_change_fo_status")}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reasonChangeStatus}
              onChange={(e) => {
                setReasonChangeStatus(e.target.value)
              }}
              placeholder={t("placeholder.input_reason_change_fo_status")}
            />
            <div className="p-5 mt-3 rounded" style={{ background: "#F8F8F8" }}>
              <i className="fa fa-info-circle mr-2" style={{ color: "#808080" }}></i>
              Setiap action akan terekam dalam log history.
            </div>
          </Form.Group>
        }
        variantCancelButton="outline-secondary"
        variantOkButton={reasonChangeStatus ? "primary" : "secondary"}
        disable={reasonChangeStatus === null || reasonChangeStatus === ""}
        textOk={t("button.next")}
        onCancel={() => {
          setShowPopupChangeStatus(false)
          setReasonChangeStatus(null)
        }}
        onOk={handleSubmitChangeStatusUser}
      />
      {FieldOfficer?.detail.role === FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD ||
      FieldOfficer?.detail.role === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER ? (
        <>{components[0]}</>
      ) : (
        <TabsComponent labelTab={labels} componentTab={components} />
      )}
    </>
  )
}