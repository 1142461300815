import AddIcon from "@material-ui/icons/Add"
import { t } from "i18next"
import React, { useCallback, useEffect, useState } from "react"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { Link, useHistory } from "react-router-dom"
import { sortCaret } from "../../../../_metronic/_helpers"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { useSubheader } from "../../../../_metronic/layout"
import { EmptyCard, Search, TableSkeleton, TableView } from "../../../../component"
import { EditSvgIcon } from "../../../../component/atoms/Icons/SVG"
import { formatterRowComponent, initialFilterTable } from "../../../../component/atoms/Tabel"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { getListAssignOpsAdmin } from "../../../../redux/actions/FieldOfficerAction.jsx"
import { getListRegionalHeadByUserLogin } from "../../../../redux/actions/MasterDataAction.jsx"
import { checkValue } from "../../../helpers/TextHelper"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"

export function AssignOpsAdminListPage() {
  const dataListHeight = 50
  const subheader = useSubheader()
  const dispatch = useDispatch()
  const urlParams = useUrlParam()
  const location = useLocation()
  const history = useHistory()
  const { FieldOfficer, General, MasterData } = useSelector((state) => state)

  const [dataList, setDataList] = useState([])
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [search, setSearch] = useState({ keyword: "" })
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [dataRh, setDataRh] = useState(undefined)

  useEffect(() => {
    subheader.setTitle(t("sub_header.assign_ops_team"))
    subheader.setButton(buttonAssign)
    dispatch(getListRegionalHeadByUserLogin())
  }, [])

  useEffect(() => {
    if (MasterData?.listRegionalHeadByUserLogin) {
      setDataRh(MasterData.listRegionalHeadByUserLogin[0])
    }
  }, [MasterData?.listRegionalHeadByUserLogin])

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setIsActiveSearch(true)
      setSearch({ keyword: queryStringObj?.keyword })
    }
  }, [queryStringObj])

  useEffect(() => {
    if (dataRh?.id) {
      const result = location?.search
      setQueryStringObj(urlParams.getUrlParamsObj)
      fetchData(result, dataRh.id)
    }
  }, [location.search, urlParams.getUrlParamsObj, dataRh])

  const buttonAssign = (
    <Link to={RoutesConstants.FIELD_OFFICER.FORM_ASSIGN_OPS_ADMIN()}>
      <button type="button" className="btn btn-primary">
        <div className={"d-flex justify-content-center align-items-center"}>
          <AddIcon />
          <div className={"pl-3"}>{t("button.assign_team")}</div>
        </div>
      </button>
    </Link>
  )

  function fetchData(param, id) {
    dispatch(getListAssignOpsAdmin(param, id))
  }

  useEffect(() => {
    if (FieldOfficer?.pageData) {
      setDataList(FieldOfficer.pageData)
    }
  }, [FieldOfficer?.pageData])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param.sortField + "," + param.sortOrder,
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search]
  )
  const handleResetSearch = () => {
    let param = {
      ...initialFilterTable,
      keyword: ""
    }
    setSearch({ keyword: "" })
    setIsActiveSearch(false)
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const searchFunc = (values) => {
    setSearch({ keyword: values.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }


  const columns = [
    {
      dataField: "field_manager",
      text: t("table_header.field_manager"),
      sort: false,
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "30%" }
      },
      formatExtraData: {
        externalStyle: (e) => (
          <div style={{ height: "fit-content" }}>
            <div className="text-dark font-weight-bolder mb-1 font-size-lg">
              {checkValue(e?.name)}
            </div>
            {e?.sub_regional_list && <div>{e.sub_regional_list.join(", ")}</div>}
          </div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "field_supervisor",
      text: t("table_header.field_supervisor"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="h-100 ">
            {e?.field_supervisor &&
              e.field_supervisor.map((item, i) => (
                <div
                  key={i}
                  style={{ height: dataListHeight * e.field_supervisor.length }}
                  className={` py-4 ${
                    i !== e.field_supervisor.length - 1 ? "border-bottom" : " "
                  }`}>
                  <div className="text-dark  font-weight-bolder  font-size-lg">
                    {checkValue(item?.name)}
                  </div>
                  {item?.sub_regional_list && <div>{item.sub_regional_list.join(", ")}</div>}
                </div>
              ))}
          </div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "field_officer",
      text: t("label.field_officer"),
      sort: false,
      formatter: formatterRowComponent,

      formatExtraData: {
        externalStyle: (e) => (
          <div className="h-100 ">
            {e.field_supervisor &&
              e.field_supervisor.map((item, i) => (
                <div
                  key={i}
                  style={{ height: dataListHeight * e.field_supervisor.length }}
                  className={` py-4 d-flex flex-column justify-content-between ${
                    i !== e.field_supervisor.length - 1 ? "border-bottom" : " "
                  }`}>
                  {item?.field_officer &&
                    item?.field_officer.map((value, idx) => (
                      <div key={idx} style={{ height: dataListHeight }}>
                        <div className="text-dark  font-weight-bolder  font-size-lg">
                          {checkValue(value)}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "action",
      text: t("table_header.action"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="h-100 ">
            {e.field_supervisor &&
              e.field_supervisor.map((item, i) => (
                <div
                  key={i}
                  style={{ height: dataListHeight * e.field_supervisor.length }}
                  className={` py-4 ${
                    i !== e.field_supervisor.length - 1 ? "border-bottom" : " "
                  }`}>
                  <>
                    <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
                      <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() =>
                          history.push(
                            RoutesConstants.FIELD_OFFICER.FORM_EDIT_ASSIGN_OPS_ADMIN(item.id)
                          )
                        }>
                        <EditSvgIcon></EditSvgIcon>
                      </a>
                    </OverlayTrigger>
                  </>
                </div>
              ))}
          </div>
        )
      },
      sortCaret: sortCaret
    }
  ]

  return (
    <div style={{ display: "grid", gap: 16 }}>
      <Card>
        <CardBody>
          <div>
            <h2 className="font-weight-bolder">{checkValue(dataRh?.name)}</h2>
            <p>Regional Head {checkValue(dataRh?.regional_name)}</p>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className={"filter-container"}>
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
              <Search
                isActiveSearch={isActiveSearch}
                placeholder={t("placeholder.assign_ops_admin_search_placeholder")}
                onSubmitForm={searchFunc}
                initialValues={search}
                name={"keyword"}
                trigerReset={handleResetSearch}
              />
            </div>
          </div>
          {General.loading ? (
            <TableSkeleton column={4} row={10} />
          ) : dataList.length === 0 ? (
            <EmptyCard
              subtitle={isActiveSearch ? t("messages.response.empty_search_subtitle") : ""}
              image={
                isActiveSearch
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_REGIONAL
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_FARMER
              }
              body={
                isActiveSearch
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", { name: t("label.field_manager") })
              }
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={dataList}
            loadingGetData={General?.loading}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            currentPage={FieldOfficer?.page?.currentPage ?? 1}
            currentElement={FieldOfficer?.page?.currentElement ?? 10}
            totalCount={FieldOfficer?.page?.totalCount ?? 0}
            ignoreSinglePage
            disabledCentered={true}
            rowClasses={() => {
              return "cursor-pointer"
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
