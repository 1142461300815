import { Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import swal from "sweetalert"
import * as Yup from "yup"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { ErpNumber } from "../../../../../component/atoms/ErpNumber/ErpNumber.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  DetailText,
  InlineText,
  RegionSelect,
  RowText,
  Status
} from "../../../../../component/index.jsx"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { ACTIVITY_ICONS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { AREA, GENERAL } from "../../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import {
  activitySendToErp,
  getDetailPlanting,
  updateAreaActivityImage
} from "../../../../../redux/actions/AreaAction.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import { toMeter } from "../../../../helpers/TextHelper.js"
import {
  ACTIVITY_STATUS_ENUM,
  STATUS_VARIANT_CONSTANT
} from "../../../../../constants/StringConstant.js"

export const PlantingDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef()
  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({})
  const [idDocument, setIdDocument] = useState(null)
  const [showModalDialog, setShowModalDialog] = useState(false)

  const [showModalDialogRegion, setShowModalDialogRegion] = useState(false)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [regionalValue, setRegionalValue] = useState("")

  const validationSchema = Yup.object().shape({
    regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
  })

  const btnApprove = (
    <Button className={"btn btn-primary"} onClick={() => setShowModalDialogRegion(true)}>
      {t("button.send_to_finance")}
    </Button>
  )

  useEffect(() => {
    dispatch(getDetailPlanting(activity_id))
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      }

      if (detail?.is_confirmed_erp === false) {
        subHeader.setButton(btnApprove)
      } else {
        subHeader.setButton(null)
      }

      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: newStatus
        },
        {
          pathname: RoutesConstants.AREA.DETAIL_AREA(status, id),
          title: detail?.area_information?.area_name
        },
        {
          title: "Penanaman"
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === GENERAL.UPLOAD) {
        const data = {
          id: detail?.activity_detail_response?.image_and_id_activity[idDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailPlanting(activity_id))
      } else if (
        General.loading === false &&
        General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP
      ) {
        setShowModalDialog(false)
        swal({
          icon: ILUSTRATION_CONSTANS.SUCCESS_FORM,
          text: t("messages.response.activity_approve_to_erp_success")
        })
        dispatch(getDetailPlanting(activity_id))
      }
    }
  }, [General])

  const titleActivity = (
    <ActivityDetailHeader
      icon={ACTIVITY_ICONS.IC_PENANAMAN}
      title={`AKTIVITAS Penanaman KE-${detail?.activity_detail_response?.seq}`}
      bottomContent={
        <ErpNumber
          noFaktur={detail?.no_faktur}
          noOrderSj={detail?.no_order_sj}
          noOrderSpk={detail?.no_order_spk}
          noOrderErp={detail?.no_order_erp}
          status={detail?.activity_type_enum}
        />
      }
      rightContent={
        detail?.is_confirmed_erp !== null && (
          <Status
            text={detail?.activity_status_string}
            variant={
              detail?.activity_status_enum === ACTIVITY_STATUS_ENUM.APPROVED
                ? STATUS_VARIANT_CONSTANT.COMPLETED
                : STATUS_VARIANT_CONSTANT.WARNING
            }
          />
        )
      }
    />
  )

  const openPopup = (content) => {
    setPopupContent(content)
    setShowPopup(true)
  }

  const bodyPopup = (
    <RowModule customColumnCss={"col-md-12 mb-2"}>
      <InlineText
        title={"ID Alsintan"}
        value={popupContent?.machine_code ?? "-"}
        type={"between"}
      />
      <InlineText title={"Nama Pemilik"} value={popupContent?.owner_name ?? "-"} type={"between"} />
      <InlineText
        title={"Pemilik Alsintan"}
        value={
          popupContent?.supplier_enum === "THIRD_PARTY"
            ? popupContent?.supplier_name + " - " + popupContent?.supplier_third_party_string
            : popupContent?.supplier_name ?? "-"
        }
        type={"between"}
      />
      <InlineText
        title={"Nama Alsintan"}
        value={popupContent?.machine_name ?? "-"}
        type={"between"}
      />
      <InlineText
        title={"Nomor Rangka"}
        value={popupContent?.frame_number ?? "-"}
        type={"between"}
      />
      <InlineText
        title={"Nomor Mesin"}
        value={popupContent?.engine_number ?? "-"}
        type={"between"}
      />
    </RowModule>
  )

  const detailActivitas = (
    <>
      <Card>
        <CardBody>
          <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Manual)"}
                value={
                  detail?.area_information?.area_land_manual
                    ? toMeter(detail?.area_information?.area_land_manual)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                hasIcon={detail?.area_information?.has_kur}
                icon={detail?.area_information?.bank_image}
                label={"Petani"}
                value={
                  detail?.area_information?.farmer_name
                    ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Polygon)"}
                value={
                  detail?.area_information?.area_land_polygon
                    ? toMeter(detail?.area_information?.area_land_polygon)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Mitra"}
                value={
                  detail?.area_information?.mitra_name
                    ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Jenis Tanaman"}
                value={detail?.area_information?.plant_type_name ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Lokasi Lahan"}
                value={detail?.area_information?.full_address ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Varietas Tanaman"}
                value={detail?.area_information?.variety_name ?? "-"}
              />
            </RowModule>
          </RowModule>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
          <div className={"row mb-6"}>
            <div className={"col-md-6"}>
              <RowText
                label={"Submitted by"}
                value={detail?.activity_detail_response?.submitted_by ?? "-"}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={"Tanggal Aktivitas"}
                value={
                  detail?.activity_detail_response?.activity_date
                    ? timeStampToDate(detail?.activity_detail_response?.activity_date, "dd-MM-yyyy")
                    : "-"
                }
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={"Submitted time"}
                value={
                  detail?.activity_detail_response?.submitted_date
                    ? timeStampToDate(
                        detail?.activity_detail_response?.submitted_date,
                        "dd-MM-yyyy HH:mm:ss"
                      )
                    : "-"
                }
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={"HST"}
                value={detail?.activity_detail_response?.hst + " HST" ?? "-"}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={"Penanaman ke"}
                value={detail?.activity_detail_response?.seq ?? "-"}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={"Metode Penanaman"}
                value={detail?.activity_detail_response?.activity_methode_string ?? "-"}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={"Requested by"}
                value={detail?.activity_detail_response?.request_by_string ?? "-"}
              />
            </div>
            <div className={"col-md-6"}>
              <RowText
                label={
                  detail?.activity_detail_response?.activity_methode_enum === "MANUAL"
                    ? "Luas Realisasi Penanaman"
                    : "Luas Lahan Dikerjakan (Alsintan)"
                }
                value={
                  detail?.activity_detail_response?.worked_area
                    ? toMeter(detail?.activity_detail_response?.worked_area)
                    : "-"
                }
              />
            </div>
            {detail?.activity_detail_response?.is_freemarket ? (
              <div className={"col-md-6"}>
                <RowText
                  label={"Jumlah"}
                  value={
                    detail?.activity_detail_response?.seed_qty
                      ? detail?.activity_detail_response?.seed_qty + " Kg"
                      : "-"
                  }
                />
              </div>
            ) : (
              <>
                <div className={"col-md-6"}>
                  <RowText
                    label={"Jumlah Benih Betina"}
                    value={
                      detail?.activity_detail_response?.female_seed_qty
                        ? detail?.activity_detail_response?.female_seed_qty + " Kg"
                        : "-"
                    }
                  />
                </div>
                <div className={"col-md-6"}>
                  <RowText
                    label={"Jumlah Benih Jantan"}
                    value={
                      detail?.activity_detail_response?.male_seed_qty
                        ? detail?.activity_detail_response?.male_seed_qty + " Kg"
                        : "-"
                    }
                  />
                </div>
              </>
            )}
            <div className={"col-md-6"}>
              <RowText
                label={"Asal Bibit"}
                value={detail?.activity_detail_response?.seed_source_string ?? "-"}
              />
            </div>
          </div>

          <div className={"d-flex flex-column"} style={{ gap: 12 }}>
            {detail?.tools?.map((item, index) => {
              return (
                <Card bg={"secondary"} key={index}>
                  <CardBody>
                    <h4 className={"mb-6 text-capitalize"}>Alsintan {index + 1}</h4>
                    <RowModule customColumnCss={"col-md-6"}>
                      <RowModule customColumnCss={"col-md-12 mb-2"}>
                        <RowText
                          label={"Pemilik Alsintan"}
                          value={
                            item?.supplier_enum === "THIRD_PARTY"
                              ? item?.supplier_name + " - " + item?.supplier_third_party_string
                              : item?.supplier_name ?? "-"
                          }
                        />
                      </RowModule>
                      <RowModule customColumnCss={"col-md-12 mb-2"}>
                        <RowText
                          label={"Operator"}
                          value={
                            item?.operators && item.operators.length
                              ? item.operators.map((e) => e.operator_name).join(", ")
                              : "-"
                          }
                        />
                      </RowModule>
                      <RowModule customColumnCss={"col-md-12 mb-2"}>
                        <RowText label={"Nama Pemilik Alsintan"} value={item?.owner_name ?? "-"} />
                      </RowModule>

                      <RowModule customColumnCss={"col-md-12 mb-2"}>
                        {item?.use_implement && (
                          <RowText label={"Implemen"} value={item?.implement_name ?? "-"} />
                        )}
                      </RowModule>

                      <RowModule customColumnCss={"col-md-12 mb-2"}>
                        <RowText
                          label={"Alsintan"}
                          value={`${item?.machine_name ?? "-"}   (${
                            item?.frame_number ? item?.frame_number : "-"
                          })`}
                        />
                      </RowModule>
                      <RowModule></RowModule>
                      <RowModule>
                        <a
                          className="hover"
                          style={{
                            textDecorationLine: "underline",
                            color: "#5EC8F2"
                          }}
                          onClick={() => {
                            openPopup(item)
                          }}>
                          Lihat Detail
                        </a>
                      </RowModule>
                    </RowModule>
                  </CardBody>
                </Card>
              )
            })}
          </div>

          <div className={"my-10"}>
            <h4 className={"mb-6"}>CATATAN</h4>
            <div className={"row mb-4"}>
              {detail?.activity_detail_response?.image_and_id_activity &&
                detail?.activity_detail_response?.image_and_id_activity.map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <DetailText
                        type="image"
                        value={item.url}
                        indexKey={index}
                        menu={AREA.ACTIVITY_PLANTING}
                        setIdDocument={setIdDocument}
                      />
                    </div>
                  )
                })}
            </div>
            <span className="font-weight-bold text-dark-50 font-size-sm">
              {detail?.activity_detail_response?.note ?? "-"}
            </span>
          </div>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      <div className={"d-flex flex-column"} style={{ gap: 12 }}>
        {titleActivity}
        {detailActivitas}
      </div>

      <Popup
        show={showPopup}
        title={`DETAIL ALSINTAN ${
          popupContent?.supplier_name ? popupContent?.supplier_name?.toUpperCase() : ""
        }`}
        bodyCustom={bodyPopup}
        textButton="Oke"
        onClick={() => {
          setShowPopup((Popup) => {
            !Popup
          })
        }}
      />
      <PopupQuestion
        onCancel={() => setShowModalDialogRegion(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_select_regional")}
        onOk={() => {
          setShowModalDialogRegion(false)
          setShowModalDialog(true)
        }}
        disable={!isValid}
        show={showModalDialogRegion}
        bodyCustom={
          <Formik
            enableReinitialize
            initialValues={initialValue}
            innerRef={formRef}
            validationSchema={validationSchema}>
            {() => (
              <Form id={"formRegion"} className={"form"}>
                <div className={"mt-4"}>
                  <RegionSelect setIsValid={setIsValid} setRegionalValue={setRegionalValue} />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_send_to_erp")}
        onOk={() => {
          dispatch(
            activitySendToErp(activity_id, {
              regional_id: regionalValue
            })
          )
          setShowModalDialog(false)
        }}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {t("messages.confirmation.activity_approve_to_erp_confirmation")}
            </p>
          </RowModule>
        }
      />
    </>
  )
}
