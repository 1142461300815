import { Form, Formik, useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { RadioButtonGroup, SelectInput } from "../../../../../component/index.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import {
  assignFo,
  assignFoBulk,
  getListCombobox
} from "../../../../../redux/actions/FieldOfficerAction.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { SelectMitra } from "./component/SelectMitra.jsx"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"
import { FO_MITRA_ASSIGN_MODE_STRING } from "../../../../../constants/StringConstant.js"

export const AssignTab = () => {
  const dispatch = useDispatch()
  const { FieldOfficer, General } = useSelector((state) => state)
  const subHeader = useSubheader()
  const {t} = useTranslation()

  const formAssignRef = useRef()
  const formAssign = useFormik({
    innerRef: formAssignRef,
    enableReinitialize: true,
    initialValues: {
      mode: FO_MITRA_ASSIGN_MODE_STRING.SINGLE,
      fo: null,
      mitra: null,
      mitras: [],
      search: null
    },
    onSubmit: (values) => {
      console.log(values)
    }
  })
  const [isBulk, setIsBulk] = useState(false)
  const [showPopupError, setShowPopupError] = useState(false)
  const [showPopupSuccess, setShowPopupSuccess] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [selectedMitras, setSelectedMitras] = useState([])

  useEffect(() => {
    dispatch(getListCombobox())
    let _title = "Assign mitra"
    subHeader.setTitle(_title)
  }, [])

  useEffect(() => {
    setIsBulk(formAssign.values.mode.toLowerCase() === FO_MITRA_ASSIGN_MODE_STRING.BULK)
  }, [formAssign.values])

  const title = (title) => {
    return <div className="font-weight-bolder mt-10 mb-3">{title}</div>
  }

  const findFo = (foId) => {
    return FieldOfficer.combobox?.find((e) => e?.id === foId)
  }

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      setShowPopupConfirm(false)
      swal({
        text: t("messages.response.assign_fo_mitra_success"),
        icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS)
      })
    }
  }, [General.isFinishUpdateOrDelete])

  const submitAssign = () => {
    if (isBulk) {
      const mitraId = []
      selectedMitras?.map((e, idx) => {
        mitraId[idx] = e?.mitra_id
      })
      dispatch(assignFoBulk(mitraId, formAssign?.values?.fo))
    } else {
      dispatch(assignFo(selectedMitras[0]?.mitra_id, formAssign?.values?.fo))
    }
  }
  return (
    <>
      {/* Popup Error */}
      <Popup
        type="error"
        body={<span>{t("messages.alert.fo_cant_empty")}</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      {/* Popup Success */}
      <Popup
        type="success"
        body={<span>{t("messages.response.fo_assign_success")}</span>}
        centered={true}
        persistent={true}
        show={showPopupSuccess}
        onClick={() => {
          setShowPopupSuccess(false)
        }}
      />
      {/* Popup Confirm */}
      <PopupQuestion
        show={showPopupConfirm}
        onOk={submitAssign}
        textOk={t("button.submit")}
        onCancel={() => {
          setShowPopupConfirm(false)
        }}
        title={t("messages.title.confirmation")}
        bodyCustom={
          <>
            <div>{t("messages.confirmation.check_data_fo_mitra")}</div>
            <div className="mt-3">
              <table>
                <thead>
                  <tr>
                    <th>Field Officer</th>
                    <th className="px-5"> ---&gt; </th>
                    <th>{t("label.mitra_name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMitras.map((e, i) => (
                    <tr key={i}>
                      <td>{i === 0 ? findFo(formAssign.values.fo)?.name : ""}</td>
                      <td></td>
                      <td>{e?.mitra_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        }
      />
      <Card>
        <CardBody>
          <Formik initialValues={formAssign.initialValues} onSubmit={formAssign.handleSubmit}>
            <Form>
              <div>
                {title(`1. ${t("label.assign_method")}`)}
                <RowModule withoutSeparator={true} customColumnCss=" col-lg-6 col-md-6 col-sm-6">
                  <RadioButtonGroup
                    label="Per Mitra"
                    name="mode"
                    value={FO_MITRA_ASSIGN_MODE_STRING.SINGLE}
                    editable={true}
                    withoutFeedback={true}
                    checked={formAssign?.values?.mode === FO_MITRA_ASSIGN_MODE_STRING.SINGLE}
                    chooseAtBack={true}
                    onClick={(v) => {
                      if (v?.target?.checked) {
                        formAssign.setFieldValue("mode", FO_MITRA_ASSIGN_MODE_STRING.SINGLE)
                      }
                    }}
                  />
                  <RadioButtonGroup
                    label="Bulk"
                    name="mode"
                    value={FO_MITRA_ASSIGN_MODE_STRING.BULK}
                    editable={true}
                    withoutFeedback={true}
                    checked={formAssign?.values?.mode === FO_MITRA_ASSIGN_MODE_STRING.BULK}
                    chooseAtBack={true}
                    onClick={(v) => {
                      if (v?.target?.checked) {
                        formAssign.setFieldValue("mode", FO_MITRA_ASSIGN_MODE_STRING.BULK)
                      }
                    }}
                  />
                </RowModule>
              </div>
              <div>
                {title(`2. ${t("label.fo_name")}`)}
                <RowModule withoutSeparator={true} customColumnCss=" col-lg-12 col-md-12 col-sm-12">
                  <SelectInput
                    name="fo"
                    editable={true}
                    label=""
                    placeholder={t("placeholder.choose_one")}
                    value={formAssign.values.fo}
                    options={(FieldOfficer.combobox ?? []).map((e) => ({
                      label: e?.name,
                      value: e?.id
                    }))}
                    withoutFeedback={true}
                    onChange={(v) => {
                      if (!v) {
                        formAssign.setFieldValue("fo", null)
                      } else {
                        formAssign.setFieldValue("fo", v)
                      }
                    }}
                  />
                </RowModule>
              </div>
              {formAssign.values.fo ? (
                <div>
                  {title(`3. ${t("label.mitra_name")}`)}
                  <RowModule withoutSeparator={true}>
                    <SelectMitra
                      key={
                        isBulk
                          ? FO_MITRA_ASSIGN_MODE_STRING.BULK
                          : FO_MITRA_ASSIGN_MODE_STRING.SINGLE
                      }
                      isBulk={isBulk}
                      fo={formAssign?.values?.fo}
                      onSelect={
                        isBulk
                          ? (rows) => {
                              if (formAssign.values.fo && rows.length > 0) {
                                setSelectedMitras(() => rows)
                              }
                            }
                          : null
                      }
                      onClickRow={
                        isBulk
                          ? null
                          : (row) => {
                              if (!formAssign.values.fo) {
                                setShowPopupError(true)
                              } else {
                                const rows = [row]
                                setSelectedMitras(() => rows)
                                setShowPopupConfirm(true)
                              }
                            }
                      }
                    />
                  </RowModule>
                </div>
              ) : null}
              <br />
              {isBulk && formAssign.values.fo ? (
                <Button variant="primary" onClick={() => setShowPopupConfirm(true)}>
                  {t("button.submit")}
                </Button>
              ) : null}
            </Form>
          </Formik>
        </CardBody>
      </Card>
    </>
  )
}