import React, { useMemo } from "react"
import { AsideMenuList } from "./AsideMenuList"
import { useHtmlClassService } from "../../../_core/MetronicLayout"
import { AsideMenuAccount } from "./AsideMenuAccount"
import { AsideLoad } from "./ASideLoad"

export const sideMenuType = {
  defaultSide: "defaultSide",
  menuAccount: "accountSide",
  menuMitra: "MITRA",
  menuFieldOfficer: "FO",
  menuFarmer: "FARMER",
  menuLahan: "AREA",
  menuOrder: "ORDER",
  menuSetting: "SETTING",
  menuMaster: "MASTER",
  menuMac: "MAC",
  menuSubmission: "SUBMISSION",
  menuReport: "REPORT"
}

export function AsideMenu({ isActive, sideMenu, menuTitle, fullTitleMenu = null }) {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    }
  }, [uiService])
  let showAsideMenu = <AsideMenuList layoutProps={layoutProps} />
  if (sideMenu === sideMenuType.menuAccount)
    showAsideMenu = <AsideMenuAccount layoutProps={layoutProps} />
  else if (sideMenu === sideMenuType.menuMitra)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuFarmer)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuFieldOfficer)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuLahan)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuSetting)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuMaster)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuOrder)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuMac)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuSubmission)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  else if (sideMenu === sideMenuType.menuReport)
    showAsideMenu = <AsideLoad titleMenu={menuTitle} parent={sideMenu} layoutProps={layoutProps} fullTitleMenu={fullTitleMenu} />
  return (
    <div className={`tab-pane fade ${isActive && "show active"}`}>
      <div className="aside-menu-wrapper flex-column-fluid px-10 py-35">
        {/* begin::Menu Container */}
        <div
          id="kt_aside_menu"
          data-menu-vertical="1"
          className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}>
          {showAsideMenu}
        </div>
        {/* end::Menu Container */}
      </div>
    </div>
  )
}
