export const EN_CONTENT = {
  button: {
    save: "save",
    add_field_supervisor: "add field supervisor",
    add_field_supervisor_normal: "Add Field Supervisor",
    assign_team: "Assign Team",
    bulk_erp: "Bulk ERP ({{count}} selected)",
    cancel: "Cancel",
    confirmation_planting_season: "Confirmation of Planting Season",
    continue: "Continue",
    download_so_selected: "Download SO ({{selected}}) selected",
    oke: "Oke",
    previous: "previous",
    send_to_finance: "Send to Finance",
    submit: "Submit",
    submit_button: "Submit",
    approve: "Approve",
    reject: "Reject",
    download: "unduh",
    add: "Add",
    next: "Lanjut",
    apply: "Apply",
    add_pest_type: "Add Pest Type",
    delete: "Delete",
    upload_ba: "Upload Berita Acara",
    submission_review: "Submission Review",
    change_filter: "Change Filter",
    select: "Select",
    next_plain: "Next",
    previous_plain: "Previous",
    next_full: "Next",
    setting_target: "Setting Target",
    reset: "Reset"
  },
  content: {
    attachment_maximum_size: "The maximum accumulated file size is 15Mb.",
    attachment_maximum_file: "The maximum number of uploaded files is 10.",
    attachment_format_file: "File formats .pdf, .jpeg, .jpg, .png.",
    attachment_xlsx_file: "The uploaded file format must be .xlsx.",
    attachment_xlsx_size: "Maximum file size is 1Mb."
  },
  description: {},
  menu: {
    add: "Add",
    area: "Area",
    dashboard: "Dashboard",
    farmer: "Farmer",
    fo: "FO",
    mac: "MAC",
    master_data: "Master Data",
    mitra: "Mitra",
    order: "Order",
    setting: "Setting",
    submission: "Submission",
    report: "report",
    ops_team: "Ops Team",
    operational_team: "Operational Team"
  },
  menu_aside: {
    area_location: "Area Location",
    area_terverifikasi: "Verified",
    ba_seeding: "Ba Seeding",
    area_pending: "Pending",
    area_reject: "Reject",
    area_unassigned: "Unassigned",
    area_lead: "Area Lead",
    area_activity_report: "Activity Report",
    area_activity_report_kur: "Activity Report KUR",
    area_sales_order: "Sales Order",
    all_schedule: "All Schedule",
    approved_schedule: "scheduled",
    schedule_pasca_planting: "Pasca Planting",
    schedule_pra_planting: "Pre Planting",
    mac_profile: "Profile",
    mac_user: "User",
    pending: "Pending",
    rescheduled: "Reschedule",
    spraying: "Spraying",
    regional: "Regional",
    pre_planting: "Pre Planting",
    sertification_and_block: "Sertifikasi & Blok",
    ready_planting_season_confirmation: "MT Confirmation Ready",
    ops_team: "Ops Team",
    assign_mitra: "Assign Mitra",
    assign_ops_admin: "Assign Ops Admin",
    operational_team: "Operational Team",
    regional_head: "Regional Head",
    field_manager: "Field Manager",
    field_spv: "Field Supervisor",
    agronomist_drone_spv: "Agronomist Drone Spv",
    harvest_spv: "Harvest Spv",
    field_officer_qa: "Field Officer QA",
    field_officer: "Field Officer",
    assign_ops_team: "Assign Ops Team",
    kpi_goal_setting: "KPI Goals Setting",
    land_block: "Land Block"
  },
  sub_header: {
    assign_ops_admin: "Assign ops admin",
    activity_report: "Activity Report",
    activity_report_kur: "Activity Report Kur",
    title: "Sales Order",
    all_schedule: "All Schedule",
    mac_activity_report: "MAC Activity Report",
    mitra_list_ca: "Daftar Mitra Ca",
    farmer_list_ca: "Daftar Petani KUR",
    spraying_pre_planting: "Spraying Pre Planting",
    pre_planting: "Pre Planting",
    regional_information: "Regional Information",
    mitra_list: "List Mitra",
    seeding_minutes_report: "seeding minutes report",
    registered_area: "Registered Area",
    area_location: "Area Location",
    land_in_waiting: "Land in Waiting",
    mitra_detail: "Mitra Detail",
    add_new_mitra: "Add New Mitra",
    farmer_detail: "Farmer Detail",
    rejected_area: "Rejected Area",
    unassign_area: "Unassigned Area",
    area_lead: "Area Lead",
    area_lead_list: "Area Lead List",
    unassigned_area_list: "Unassigned Area List",
    category_item: "Category Item",
    machine: "Machine",
    pesticide: "Pesticide",
    edit_fo_with_name: "Edit FO With Name",
    add_field_officer: "Add Field Officer",
    fertilizer: "Fertilizer",
    seed: "Seed",
    add_new_farmer_bulk: "Add New Farmer Data (Bulk)",
    add: "Add",
    spraying_package: "Spraying package",
    add_farmer: "Add Farmer",
    edit_farmer_with_name: "Edit Farmer with Name {{ name }}",
    farmer: "Farmer",
    fo_agronomist: "FO Agronomist",
    fo_coordinator: "FO Coordinator",
    fo_harvest: "FO Harvest",
    fo_qa: "Field Officer QA",
    manage_reason: "Manage Reason",
    fo: "Field Officer",
    edit_package: "Edit Package",
    add_package: "Add Package",
    edit_treatment: "Edit Treatment",
    add_treatment: "Add Treatment",
    edit_treatment_on: "Edit Treatment on",
    add_treatment_on: "Add Treatment on",
    user_cms: "User CMS",
    broadcast_message: "Broadcast Message",
    role_list: "Role List",
    edit_video_with_title: "Edit video with title '{{ title }}'",
    setting: "Setting",
    manage_video_tutorial: "Manage video Tutorial",
    video_tutorial: "Video Tutorial",
    admin_setting: "Admin Setting",
    regional_head: "Regional Head",
    field_manager: "Field Manager",
    fo_quality_assurance: "FO Quality Assurance",
    add_ops_team: "Add Operational Team",
    edit_ops_team: "Edit Ops Team With Name",
    field_supervisor: "Field Supervisor",
    edit_operational_team: "Edit Operational Team",
    assign_team: "Assign Team",
    kpi_goal_setting: "KPI Goals Setting",
    edit_target_yearly: "Edit Yearly Target"
  },
  placeholder: {
    select_operational_team: "Select Operational Team",
    select_sub_regional: "please type or select sub regional",
    select_regional_head: "select regional head",
    input_email_address: "input email address",
    input_name: "input name",
    assign_ops_admin_search_placeholder: "Insert FM name",
    select_one: "Select One",
    select_regional: "Select Regional",
    insert_plant_type: "insert plant type",
    insert_mac_username_or_request_id: "Insert user name / request ID",
    input_reason: "Input Reason",
    search_activity_report: "Input user name / submission ID",
    insert_area_name: "Insert area name",
    insert_regional_id_or_name: "insert regional ID / Regional Name",
    select_team_drone: "Select Team Drone",
    insert_province: "Insert Province Name",
    search_mitra_name_or_id: "Cari ID mitra / nama mitra",
    search_farmer_name_or_id: "Cari ID petani / nama petani",
    insert_regional_name: "insert regional name",
    insert_sub_regional_name: "Input sub regional name",
    search_area_block: "Find area name / block",
    search_block_name: "Find Block Name",
    insert_regional_head_name: "Search Regional Head Name",
    select_role: "select role",
    select_field_manager: "Silahkan pilih atau ketik FM",
    select_field_supervisor: "Silahkan pilih atau ketik FS",
    min_value: "Minimal Value",
    max_value: "Maximal Value",
    select_date_range: "Select date range",
    insert_area_name_or_farmer: "Insert area name / farmer",
    choose_province: "Choose Province...",
    choose_city: "Choose City...",
    choose_subdistrict: "Choose Subdistrict...",
    choose_district: "Choose District...",
    change_farmer_status_input: "Input the reason for changing farmer status.",
    change_farmer_mitra_input: "Input the reason for changing Mitra.",
    reject_reason: "Reject Reason",
    change_mitra_status_input: "Input the reason for changing mitra status.",
    input_mitra_name: "Input Mitra Name",
    input_name_as_per_ktp: "Input name according to KTP",
    input_ktp_number: "Input KTP Number",
    input_bank_number: "Input Bank Number",
    input_account_name: "Input Bank Account Owner Name",
    select_bank_name: "Select Bank Name",
    input_organization_name: "Input Organization Name",
    input_telephone_number: "Input Telephone Number",
    input_npwp_number: "Input NPWP number",
    input_bank_name: "Input Bank Name",
    input_company_name: "Input Company Name",
    enter_fo_name: "Search Field Officer Name",
    input_reason_change_fo_status: "Input the reason for changing the FO status.",
    input_description: "Input Description",
    select_branch: "Please select a branch",
    input_nominal: "Add Nominal",
    input_no_hp: "Input phone number",
    input_account_number: "Input Account Number",
    choose_drone_pilot_team: "Choose a Drone Pilot Team",
    input_team_name: "Input team name",
    input_team_member: "Input team member",
    input_variant_name: "Add variant name",
    input_fo_name: "Input Field Officer Name",
    select_plant_type: "Select planting type",
    select_fertilizer_form: "Select fertilizer form",
    insert_variant_name: "Insert variant name",
    select_machine_owner: "Select machine owner",
    select_machine_type: "Select machine type",
    select_pesticide_form: "Select pesticide form",
    select_fo_role: "Select FO Role",
    input_package_name: "Input Package Name",
    input_package_desc: "Input description Paket",
    input_threatment_name: "Input threatment name",
    input_first_hst: "Input First HST",
    input_last_hst: "Input Last HST",
    input_treatment_desc: "Input treatment description",
    input_dose_total: "Input Dose Total",
    input_resaon_change_status_user_cms:
      "Input the reason for changing the status of the CMS User.",
    input_user_full_name: "Input User Name",
    choose_role_user: "Please choose a role user",
    input_your_old_password: "Input your old password",
    input_youtube_video_link: "Please add the video link from YouTube",
    input_youtube_video_title: "Video Title follows from YouTube",
    choose_variety: "Choose Variety",
    select_seed_class: "Select Seed Class",
    select_planting_date: "Select planting date",
    select_seed_source_origin: "Select Seed Source Origin",
    input_count_seed: "Input Count Seed",
    input_lot_number: "Input Lot Number",
    input_label_number: "Input label serial number",
    select_mitra: "Select Mitra",
    select_location: "Select Location",
    select_plant_varieties: "Select Plant Varieties",
    search_area_name: "Search Area Name",
    reason_statement: "Reason Statement",
    select_reporting_region: "Select Reporting Region",
    enter_name: "Enter Name",
    select_spray_date: "Select Spray Date",
    enter_user_name: "Enter User Name",
    input_search: "Input Search",
    input_video_title: "Input Video Title",
    search_id_name_fo: "Search by ID FO / Name FO",
    input_headline: "Input Headline",
    choose_one: "Choose One",
    insert_field_manager_name: "Search Field Manager Name",
    insert_fo_qa_search: "Search Field Officer QA Name",
    insert_fo_agronomist_search: "Search FO Agronomist Name",
    insert_fo_harvest_search: "Search FO Harvest Name",
    insert_target: "Insert target",
    search_sub_regional: "Search Sub Regional",
    insert_field_supervisor_search: "Search Field Supervisor Name",
    input_your_new_password: "Input Your New Password",
    input_confirm_your_new_password: "Confirm Your New Password",
    input_farmer_name: "Input Farmer Name"
  },
  label: {
    history: "history",
    year: "year",
    confirmation: "confirmation",
    field_officer: "Field Officer",
    field_manager: "field manager",
    select_fm: "Pilih FM",
    data_fm: "Data FM",
    input_fs_dan_fo: "Input FS dan FO",
    assign_ops_team: "Assign ops team",
    assign_team: "assign team",
    ops_admin: "ops admin",
    assign_to_field_supervisor: "assign to field supervisor",
    assign_to_field_manager: "assign to field manager",
    field_supervisor: "field supervisor",
    regional_head: "Regional Head",
    profile_picture: "Profile Picture",
    data_operational_team: "data team operational",
    review_data_team_operational: "REVIEW DATA OPERATIONAL TEAM",
    role: "role",
    email: "Email",
    select_maximu_120_day: "Select a maximum date range of 120 days",

    land_clearing_data: "land clearing data",
    certification_number: "certification number",
    land_clearing_reasong: "alasan bongkar",

    land_clearing_activity: "land clearing activity",

    download_history_note_view:
      "The event history will be downloaded in CSV format. History can be downloaded within 120 days from the date it was created.",
    download_history: "Unduh riwayat",
    warning_select_activity:
      "Maximum of 20 selected activities. Narrow down your land choices before continuing.",
    send_to_bulk_finance: "send to bulk finance",
    activity_selected_count: "{{count}} activity selected",
    note_view_activity_report_v2:
      "Activity reports that are billing in nature and must be sent to Finance are only fertilizing and spraying activities.",
    no_bill: "no bill",
    need_ba: "need berita acara",
    sending_finance: "Sending finance",
    ready_send_finance: "Ready Send Finance",
    add_package: "Add Package",
    total_pesticide: "Pesticide Total",
    package_status: "Package Status",
    total_treatment: "Total treatment",
    package_id: "Package ID",
    spraying_package: "Spraying package",
    detail_regional: "List Regional",
    farmer_id: "Farmer ID",
    farmer_list: "List Farmer",
    inactive: "inactive",
    rejection_reason: "rejection reason",
    regional_id: "ID regional",
    province_name: "Province Name",
    rejected_date: "Rejected Date",
    sub_regional: "Sub Regional",
    sub_regional_amount: "Total Sub Regional",
    reject: "rejected",
    active: "Active",
    edit_data_regional: "Edit Data Regional",
    district_or_city: "Kabupaten / kota",
    sub_regional_name: "sub regional name",
    add_sub_regional: "Add sub regional",
    id_sub_regional: "ID Sub Regional",
    address: "Address",
    mitra_id: "Mitra ID",
    detail_sub_regional: "Detail Sub Regional",
    mitra_type: "Mitra Type",
    activity: "Activity",
    activity_detail: "Activity Detail",
    activity_type: "Activity Type",
    activity_working_date: "Activity working date",
    activity_picture: "activity picture",
    area_name: "Area",
    area_information: "Area Information",
    submitted_date: "submitted date",
    area_address: "Area Address",
    activity_date: "Activity Date",
    area_location: "Area Location",
    action: "action",
    bulk_scheduling: "Bulk Approval",
    cabang: "Regional",
    company_name: "Company Name",
    company_phone: "Company Phone",
    company_full_address: "Company Full Address",
    company_email: "Company Email",
    company_document: "Company Document",
    category_item: "Category Item",
    created_date: "Created Date",
    canceled_by: "Canceled By",
    data_activity: "Data Activity",
    data_rescheduled: "Data Rescheduled",
    edit: "Edit",
    farmer: "Farmer",
    farmer_name: "Farmer Name",
    full_address: "Full Address",
    harvest_date: "Harvest Date",
    input_reason: "Input Reason",
    land_area_worked: "Worked Area of Land",
    land_area_total: "Total Land Area",
    land_conditions: "Land Condition",
    mitra: "Mitra",
    mitra_name: "Mitra Name",
    manual_area_size: "Area Size (Manual)",
    mac_profile_information: "Information profile MAC",
    mac_user_information: "Information User MAC",
    mac_data_activity: "Data Activity",
    mac_data_rescheduled: "Data Rescheduled",
    mac_id_user: "ID User MAC",
    mac_user_name: "User Name MAC",
    mac_full_address: "Address User MAC",
    mac_data_scheduled: "Data Scheduled",
    scheduled_data: "Scheduled Data",
    member: "member",
    machine_owner: "Alsintan Owner",
    nominal: "Nominal",
    npwp: "Nomor NPWP",
    nik: "NIK",
    report: "Laporan",
    note: "Note",
    name: "Name",
    cp_name: "Narahubung",
    cp_phone: "Phone",
    order_id: "Order ID",
    overview_activity: "Overview Activity",
    overview_land_data: "Overview Area",
    operator: "Operator",
    others: "others",
    operator_name: "Operator Name",
    plant_data: "Plant Data",
    plant_type: "Plant type",
    plant_age: "Plant Age",
    province: "Province",
    product: "Product",
    planting_date: "Planting Date",
    plant_varieties: "Plant Varieties",
    phone_number: "Phone Number",
    pilot_name: "Pilot Name",
    pasca_planting: "Pasca Planting",
    regional: "regional",
    request_id: "request id",
    request_detail: "request detail",
    upload_so: "Upload SO",
    total_activity: "Total Activity",
    type_schedule: "Type Scheduled",
    warehouse: "Pesticide Warehouse",
    worker_area_size: "Worked area size (Alsintan)",
    pending: "Pending",
    payment_receipt: "payment receipt",
    receipt_number: "recipe number",
    phone: "Telephone Number",
    pic: "PIC",
    first_pic: "PIC First Scheduled",
    submit_spk: "SUBMIT SPK",
    select_date_range: "Select date range",
    status: "Status",
    supporting_photo: "Supporting Photo",
    scheduled: "Scheduled",
    scheduling: "Scheduling",
    scheduled_date: "Scheduled Date",
    scheduled_reason: "Scheduled Reason",
    first_scheduled_date: "First Schedule Date",
    scheduled_by: "Scheduled By",
    schedule_date: "Schedule Date activity",
    submitted_by: "Submitted By",
    submitted_time: "Submitted time",
    user_name: "User Name",
    spraying_pasca_planting: "Penyemprotan Pasca Tanam",
    spraying_activity: "Spraying Activity",
    spraying_activity_mac: "Spraying Activity MAC",
    spraying: "Spraying",
    team_drone: "Team Drone",
    team_name: "team name",
    reschedule: "Reschedule",
    rescheduled_date: "Tanggal Pengganti",
    rescheduled_reason: "Rescheduled Reason",
    reason_reject: "Reject Reason",
    reason_cancelation: "Feason for Cancellation",
    reject_by: "Reject By",
    overdue: "overdue",
    done: "done",
    canceled: "canceled",
    rejected: "rejected",
    rejected_by: "Rejected By",
    reset_date: "Reset date",
    apply: "Apply",
    mac_user_merchant_name: "MAC User Merchant name",
    add_plant_type: "Add Plant Type",
    edit_plant_type: "EDIT PLANT TYPE",
    insert_plant_type_name: "Insert plant type name",
    input_plant_name: "Insert plant name",
    add_regional: "Add Regional",
    edit_regional: "Edit Regional",
    varietas: "Varietas",
    regional_name: "Regional Name",
    list_province: "List Provinsi",
    insert_regional_name: "Insert Regional Name",
    spraying_schedule: "Spraying Schedule",
    preview: "Preview",
    spraying_activity_scheduling: "Penjadwalan Aktivitas Penyemprotan",
    review_and_submit: "Review & Submit",
    bulk_approval: "Bulk Approval",
    land_area: "Land Area",
    delete_sub_regional: "Delete Sub Regional",
    add_treatment: "Add Treatment",
    edit_treatment: "Edit Treatment",
    id_treatment: "ID Treatment",
    hst: "HST",
    submitted: "Submitted",
    received: "Received",
    submission_certification_block: "Submission Certification & Block",
    description: "Description",
    canot_more_than: "Total land must not exceed 100,000 m² (10 Ha)",
    total_area: "Total Area",
    female_planting_date: "Female Planting Date",
    see_map: "See Map",
    data_previous_plant: "Previous Crop Data",
    data_from_area_bigger:
      "Information is taken from Seeding Activation data from the largest land size.",
    land_history: "Land History",
    former_bero: "Former Bero",
    disertifikasi: "Disertifikasi",
    seed_class: "Seed Class",
    field_inspection: "Field Inspection",
    cancel_submission: "Cancel Submission",
    cancel_submission_certification: "CANCEL SUBMISSION CERTIFICATION",
    submission_download: "Download Submission",
    create_ba_land_clearing: "Create Report (Land Clearing)",
    last_inspection: "Last Inspection",
    inspection_status: "Inspection Status",
    harvest_date_prediction: "Harvest Date Prediction",
    doc_number: "Document Number",
    file_number: "File Number",
    block_information: "Block Information",
    planting_season_information: "Planting Season Information",
    qa_last_inspection: "QA Last Inspection",
    status_user: "Status User",
    ownership: "Ownership",
    planting_season_completed: "Planting Season Completed",
    failed_harvest: "Gagal Panen",
    min: "Minimal",
    max: "Maximal",
    location: "Location",
    land_area_m2: "Land Area (m2)",
    plant: "Plant",
    planting_status: "Planting Status",
    area_detail: "Area Detail",
    activity_history: "Activity History",
    log: "Log",
    land_ownership_proof: "Land Ownership Proof",
    new_land_registration: "New land registration",
    approval_to_remove_farmers_from_the_land: "Approval to remove farmers from the land.",
    ready_planting_season_confirmation: "Ready to Confirm Planting Season",
    confirmation_planting_season_bulk: "Confirmation Plating Season Bulk",
    existing_fields_have: "Existing fields have",
    harvest_reports_and_have_been_fully_harvested: "harvest reports and have been fully harvested.",
    make_sure_the_land_to_be_confirmed_has_been_paid_in_full:
      "Make sure the land to be confirmed has been paid in full.",
    choose_one: "Choose One",
    minimal_value: "Minimal Value",
    maximal_value: "Maximal Value",
    city: "City",
    district: "District",
    subdistrict: "Subdistrict",
    men: "Men",
    woman: "Woman",
    farmer_detail: "Farmer Detail",
    edit_farmer: "Edit Farmer",
    edit_mitra: "Edit Mitra",
    every_action_will_be_recorded_in_the_log_history:
      "Every action will be recorded in the log history.",
    change_farmer_mitra: "Please add a reason for changing Mitra.",
    select_farmer_mitra_assignment: "Please select the Mitra who will be assigned to the Farmer.",
    account_name: "Account Name",
    bank_name: "Bank Name",
    account_number: "Account Number",
    pending_activity: "Pending Activity",
    finish_activity: "Finish Activity",
    next_activity: "Next Activity",
    list_mitra_ca: "Mitra CA List",
    number_of_farmers: "Number of Farmers",
    detail_mitra: "Mitra Detail",
    mitra_businnes: "Mitra Business",
    document: "Document",
    mitra_reject_reason: "Please add reasons for rejecting mitra",
    name_as_per_id: "Name as per KTP",
    ktp_number: "KTP number",
    npwp_number: "NPWP number",
    ktp: "KTP",
    swafoto: "SWAFOTO",
    work_assignment_letter: "WORK ASSIGNMENT LETTER",
    organizational_structure: "ORGANIZATIONAL STRUCTURE",
    ktp_of_the_person_in_charge: "KTP OF THE PERSON IN CHARGE",
    deed_of_company_establishment: "DEED OF COMPANY ESTABLISHMENT",
    select_mitra_type: "Select Mitra Type",
    date_of_birth: "Date of Birth",
    mitra_domicile_address: "Mitra Domicile Address",
    postal_code: "Kode Pos",
    ktp_picture: "KTP Picture",
    mitra_data: "Mitra Data",
    telp_number: "Telephone Number",
    gender: "Gender",
    mitra_document: "Mitra Document",
    selfie_with_ktp: "Selfie with KTP",
    kk_picture: "KK Picture",
    npwp_picture: "NPWP Picture",
    bank_account: "Bank Account",
    organization_name: "Organization Name",
    person_in_charge: "Person in Charge",
    organization_address: "Organization Address",
    organization_document: "Organization Document",
    photo_of_work_assignment_letter: "Photo of work assignment letter",
    organization_structure_photo: "Organization Structure Photo",
    organization_bank_account: "Organization Bank Account",
    npwp_organization_number: "NPWP Organization Number",
    npwp_organziation_photo: "NPWP Organization Photo",
    company_address: "Company Address",
    company_bank_account: "Company Bank Account",
    review_mitra_data: "Review Mitra Data",
    company_npwp_number: "Company NPWP number",
    company_npwp_photo: "Company NPWP photo",
    waiting: "Waiting",
    registered: "Registered",
    area_lead: "Area Lead",
    empty_data: "Empty Data",
    scheduled_working_date: "Scheduled Working Date",
    completed_working_date: "Completed Working Date",
    spraying_method: "Spraying Method",
    liquid_use: "Liquid Use",
    pest_type: "Pest Type",
    reason: "Reason",
    pesticide_name: "Pesticide Name",
    pesticide_ownership: "Pesticide Ownership",
    dose: "Dose",
    quantity: "Quantity",
    pesticide_detail: "Pesticide Detail",
    team_member: "Team Member",
    total_activities: "Total Activities",
    total_revenue: "Total Revenue",
    confirmation_planting_season: "Confirmation of Planting Season",
    reasons_for_harvest_failure: "Reasons for Harvest Failure",
    pest: "Pest",
    choose_pest_type: "Choose Pest Type",
    event_news_document: "Event News Document",
    photo_evidence: "Photo Evidence",
    max_8_photos_sub_label: "max. 8 photos",
    note_description: "Note",
    district_code: "District Kelurahan",
    farmer_data_type: "Farmer Data Type",
    maxxi_tani_farmer: "Maxxi Tani Farmer",
    lead_farmer: "Lead Farmer",
    farmer_location: "Farmer Location",
    lead_farmer_name: "Lead Farmer Name",
    assign_method: "Assign Method",
    fo_name: "FO Name",
    variant_name: "Variant Name",
    plant_form: "Plant Form",
    mesin_owner: "Machine Owner",
    machine_type: "Machine Type",
    machine_name: "Machine Name",
    engine_number: "Engine Number",
    frame_number: "Frame Number",
    pesticide_form: "Pesticide Form",
    fo_address: "Field Officer Address",
    please_add_reason: "Please Add Reason",
    please_edit_reason: "Please edit reason.",
    package_name: "Package Name",
    treatment_name: "Threatment Name",
    from: "From",
    to: "To",
    dose_total: "Dose Total",
    edit_activity: "Edit Activity",
    cancel_activity: "Cancel Activity",
    add_drone_team: "Add Drone Team",
    user_information: "User Information",
    user_full_name: "User Name",
    old_password: "Old Password",
    new_password: "New Password",
    confirm_new_password: "Confirm New Password",
    video_title: "Video Title",
    video_category: "Video Category",
    detail_seed: "Seed Detail",
    seed_source: "Seed Source",
    seed_information: "Seed Information",
    seed_source_origin: "Seed Source Origin",
    lot_number: "Lot Number",
    total_count_seed: "Total Count Seed",
    certification_form: "Certification Form",
    search_result: "Search Result",
    select_all_area: "Select All Area",
    fill_out_form: "Fill out the Form",
    select_reporting_region_desc: "Regional financial areas for activity reporting",
    unassign_reason: "Unassign Reason",
    selfie: "Selfie",
    sub_district: "Subdistrict",
    photo_profile: "Photo Profile",
    approved: "Approved",
    spary_date: "Spray Date",
    fo_inactive: "FO inactive",
    fo_active: "FO active",
    harvest_realization: "Harvest Realization",
    harvest_age: "Harvest Age",
    number_of_sacks: "Total Sacks",
    moisture_content: "Water Content",
    waste: "Waste",
    weight: "Weight",
    weight_slip: "Weight Slip",
    sack: "Sack",
    harvest_data: "Harvest Data",
    type: "Type",
    price: "Price",
    buyer_data: "Buyer Data",
    sold_harvest_data: "Data Hasil Panen Terjual",
    weight_slip_data: "Weight Slip Data",
    picture_of_weight_slip: "Picture of Weight Slip",
    estimated_land_area_worked: " Land Area Worked",
    requested_by: "Requested By",
    harvest_estimation: "Harvest Estimation",
    id_user: "Id User",
    unassignment_reason: "Unassignment Reason",
    no_order: "Order Number",
    no_spk: "SPK Number",
    no_sj: "SJ Number",
    no_faktur: "Facture Number",
    last_30_days: "Last 30 Days",
    last_90_days: "Last 90 Days",
    total: "Total",
    sequence: "Sequence",
    treatment_total: "Total Treatment",
    add_video: "Add Video",
    ktp_selfie: "KTP & Selfie",
    ktp_selfie_photo: "KTP & Selfie Photo",
    bank_information: "Bank Information",
    review_individual_submit: "Review Individual & Submit",
    fo_list: "FO List",
    drone_team: "Drone Team",
    team_schedule: "Team Schedule",
    all: "All",
    pass: "Pass",
    not_pass: "Not Pass",
    price_per_kg: "Price per Kg",
    spray_date: "Spray Date",
    this_year_target: "This Year Target",
    plant_target_year: "Plant Target {{year}}",
    regional_target: "Target Regional",
    total_sub_regional_target: "Total Target Sub Regional",
    total_sub_regional_target_warning: "Subregional targets must not exceed regional targets",
    upload_letter_of_change_submission: "Upload Letter of Change Submission",
    regional_change_reason_letter: "Regional Change Reason Letter",
    input_target: "Input Target",
    edit_target: "Edit Target",
    input_must_not_more_than_less_than:
      "{{input}} must not more than {{more}} and not less than {{less}}",
    input_must_number: "{{input}} must be number",
    input_must_positive_number: "{{input}} must positive number",
    input_must_inputted: "{{input}} must inputted",
    input_must_custom: "{{input}} must {{custom}}",
    target_sub_regional: "Sub Regional Target",
    target_regional: "Regional Target",
    setting_target_note_view_create_edit:
      "Make sure the target inputted is correct. Because if there is a change, you must use a submission letter",
    download_example_document_for_edit_target_title:
      "To edit annual targets, please include a change submission letter.",
    document_example: "Document Example",
    note_view_setting_target_sub_regional_description:
      "Sub-Regional targets must not exceed Regional targets",
    input_must_have_one_point: "{{input}} must have 1 point decimal"
  },
  messages: {
    alert: {
      invalid_file_type: "Invalid File Type",
      max_mb: "max {{size}} mb",
      failed_remove_assign_farmer_planting_activity:
        "Farmers cannot be deleted because there is still planting activity.",
      fo_cant_empty: "FO must not be empty"
    },
    title: {
      change_status_package: "UBAH STATUS PAKET",
      add_regional_head_successfully: "Regional Head successfully added",
      add_field_manager_successfully: "Field manager successfully added",
      activity_send_to_erp: "SEND ACTIVITIES TO ERP",
      activity_select_regional: "REGIONAL REPORTING ACTIVITIES",
      approve_schedule: "APPROVE SCHEDULE",
      cancel_schedule: "CANCEL SCHEDULE",
      cancel_reschedule: "CANCEL EDIT SCHEDULE",
      edit_order: "EDIT ORDER",
      reschedule_order: "RESCHEDULE ORDER",
      planting_season_confirmation: "PLANTING SEASON CONFIRMATION",
      reject_order: "REJECT ORDER",
      cancel_order: "ORDER CANCELATION",
      preview_information_regional: "Preview Information Regional",
      detail_ops_team: "Detail Operational Team",
      planting_season_information: "Planting Season Information",
      remove_farmer_from_area: "REMOVE FARMER FROM AREA",
      land_does_not_meet_the_criteria: "LAND DOES NOT MEET THE CRITERIA",
      land_does_meet_the_criteria: "LAND DOES MEET THE CRITERIA",
      land_hazard_potential: "Potential Land Danger",
      farmer_reject_reason: "FARMERS DO NOT MATCH THE CRITERIA",
      farmer_approve: "FARMERS AS PER THE CRITERIA",
      erp_synchronization: "ERP SYNCHRONIZATION",
      change_farmer_status: "CHANGE FARMER STATUS",
      change_farmer_mitra: "CHANGE FARMER MITRA",
      select_mitra_replacement: "SELECT MITRA REPLACEMENT",
      mitra_reject_reason: "MITRA DO NOT MATCH THE CRITERIA",
      mitra_approve: "MITRA AS PER THE CRITERIA",
      change_mitra_status: "CHANGE MITRA STATUS",
      reject_farmer_removal_request: "REJECT FARMER REMOVAL REQUEST",
      change_status_fo: "CHANGE FO STATUS",
      farmer_domicile_address: "Farmer Domicile Address",
      add_reason: "ADD REASON",
      remove_reason: "REMOVE REASON",
      confirmation: "CONFIRMATION",
      delete_video: "DELETE VIDEO",
      new_clearing_report_ba: "NEW CLEARING REPORT EVENT HISTORY",
      update_farmer_data: "UPDATE FARMER DATA",
      reset_password: "Reset Password",
      success: "Success",
      remove_broadcast_message: "DELETE BROADCAST MESSAGE"
    },
    confirmation: {
      edit_package:
        "Apakah anda yakin ingin memperbarui paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      change_status_package:
        "Apakah anda yakin ingin menonaktifkan paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      edit_regional: "EDIT REGIONAL",
      create_regional:
        "The regional data that has been filled in will be entered into the regional list. Make sure the data entered is correct before continuing",
      submit_add_regional: "Make sure the regional data entered is correct before continuing.",
      activity_approve_to_erp_confirmation:
        "By agreeing to this activity, the available data will be sent to the ERP. Make sure all data is correct.",
      activity_approve_to_erp_confirmation_bulk:
        "The selected activity will be sent to the ERP system ({{count}} Activities). Make sure all the data selected is correct.",
      create_so:
        "The filled data will be sent to become SPK in the ERP. Make sure all the data entered is correct.",
      create_so_success: "SO success created.",
      order_spraying_cancel:
        "By canceling the form filling, the order will not be scheduled and all data that has been filled in will be lost.",
      order_edit_spraying_cancel:
        "By canceling the edit form, all data that has been filled in will be lost.",
      order_spraying_approve:
        "Approved orders will be included in the scheduling date. Make sure the data entered is correct.",
      order_edit: "After agreeing, the order data will change. Changed data:",
      planting_season_confirmation_need_ba:
        "Confirming the growing season, this growing season's activity will be moved to the Activity History tab. " +
        "Make sure the minutes have been made.",
      planting_season_confirmation:
        "Confirming the growing season, this growing season's activity will be moved to the Activity History tab.",
      team_edit_cancel:
        "By canceling the edit form, all data that has been filled in will be lost.",
      team_create_cancel:
        "By canceling the form filling, a new team will not be created and all data that has been filled in will be lost.",
      team_edit:
        "After agreeing, the drone team data will change. Make sure all data has been filled in correctly.",
      team_create:
        "By adding a drone team, the team can be scheduled for spraying activities. Make sure the data entered is correct.",
      team_create_with_schedule:
        "The edited team still has the spraying schedule and the new Team members will follow all existing schedules. Are you sure you want to change team members?",
      add_treatment:
        "The treatment data that has been filled in will be included in the related treatment list. Make sure the data entered is correct before continuing",
      edit_treatment:
        "Are you sure you want to renew this treatment? This action will affect all data related to this treatment.",
      cancel_submission:
        "Are you sure you want to cancel the selected land application? Canceled land will be returned to the Field Officer.",
      cant_confirm_planting_season_plant_activity:
        "The planting season cannot be confirmed because harvesting activities have not yet taken place.",
      planting_season_confirmation_question: "Are you sure you can confirm the planting season?",
      planting_season_confirmation_question_info:
        "The activity information will be moved to the <1>Activity History</1> tab",
      planting_season_cancel_question:
        "By canceling the planting season, the planting status of the registered land will be <1>Inactive.</1>",
      planting_season_cancel_question_info: "Ensure that the land is selected correctly.",
      erp_synchronization_confirmation: "Are you sure you want to synchronize these farmers?",
      change_farmer_status_add_reason: "Silahkan tambah alasan ubah status Petani.",
      change_mitra_status_add_reason: "Silahkan tambah alasan ubah status Mitra.",
      log_will_be_recorded: "Setiap action akan terekam dalam log history.",
      add_reason_change_fo_status:
        "Silahkan tambah alasan ubah status FO, semua Mitra akan diunassign.",
      farmer_bank_account_warning:
        "If the bank has been selected then the account number & account owner's name must be filled in.",
      check_data_fo_mitra: "Ensure field officer and mitra data is correct.",
      new_password_from_user: "New Password From User",
      add_reason_deactivate_user: "Please add the reason for deactivating the CMS User.",
      delete_data_confirmation: "Are you sure you want to delete this data?",
      delete_tutorial_video_confirmation:
        "Are you sure you want to delete Video Tutorial? Deleted data will be lost.",
      common_data_correct_confirmation: "Are you sure the data is correct?",
      update_farmer_data_confirmation:
        "Are you sure you are editing the Farmer data? Make sure all data is complete and correct.",
      reset_password_confirmation: "Are you sure you want to Reset Password from User "
    },
    response: {
      area_unassigned_approve:
        "The unassign request is approved and the land status changes to unassigned.",
      area_unassigned_reject:
        "The unassign request is rejected and the land status remains registered.",
      activity_approve_to_erp_success: "Activity successfully sent to ERP.",
      broadcast_success_delete: "The broadcast message has been deleted successfully.",
      broadcast_create_success: "The broadcast message was sent successfully.",
      data_not_found: "Data not found",
      empty_state: "{{name}} data is empty",
      empty_target_state: "{{year}} data is empty",
      searching_not_found: "Your search was not found",

      erp_sync: "Farmer data synchronization to ERP successful.",
      farmer_rejected: "Farmers successfully rejected",
      farmer_approved: "Farmer successfully verified",
      farmer_edit_success: "Farmer data updated successfully",
      farmer_create_success: "Farmers successfully registered.",
      order_rejected: "Activity was successfully rejected.",
      order_cancel: "Activity canceled successfully.",
      order_success: "The activity was successfully scheduled.",
      success_approve_area: "Area verficication successfully",
      team_drone_change_success: "The drone team has been updated successfully.",
      team_drone_add_success: "The drone team has been successfully added.",
      success_create_plant_type: "Plant successfully added",
      success_create_regional: "Data regional created successfully",
      success_edit_regional: "Data regional updated successfully",
      success_edit_sub_regional: "Edit sub regional success.",
      response_not_found_data: "Your search was not found",
      sub_response_not_found_data: "Please check the filters or keywords and try again.",
      success_download_submission: "Download of certification application was successful.",
      success_download_history_clearing_ba:
        "The unloading event history has been successfully downloaded",
      success_create_clearing_report: "The clearing report has been successfully created.",
      edit_ops_team_success: "Operational Team data updated successfully",
      add_ops_team_success: "Operational Team data successfully created",
      no_activity_history_yet: "No activity history yet.",
      no_land_activities_yet: "No land activities yet.",
      there_have_been_no_recent_logs_in_3_months: "There have been no recent logs in 3 months.",
      there_have_been_no_recent_logs: "There have been no recent logs",
      log_search_not_found: "Log search not found",
      please_check_the_filter_and_try_again: "Please check the filter and try again.",
      reason_not_found: "Reason Not Found.",
      success_remove_assign_farmer:
        "Farmer is removed from the field. Land data updated successfully",
      success_reject_area: "Area successfully rejected",
      success_verify_area: "Area successfully verified",
      empty_area_farmer: "Farmers Don't Have Area Data",
      lead_farmer_data_added_successfully: "Lead farmer data added successfully",
      field_officer_successfully_deactivated: "Field Officer successfully deactivated.",
      assign_fo_mitra_success: "FO successfully assigned to mitra",
      fo_assign_success: "Assign Mitra successfully.",
      sync_pesticide_successfully: "Sync Pesticide Successfully",
      successfully_added_reason: "Successfully Added Reason",
      no_item_yet: "No Item Yet.",
      successfully_removed_reason: "Successfully Removed Reason",
      successfully_updated_reason: "Successfully Updated Reason",
      cms_user_successfully_deactivate: "CMS User successfully deactivate",
      fo_data_updated_successfully: "FO data updated successfully",
      fo_data_successfully_created: "Data FO berhasil dibuat",
      successfully_declared_a_crop_failure: "The area was successfully declared a crop failure.",
      tutorial_video_deleted: "Tutorial video successfully deleted",
      inactive_package_success: "Package successfully deactivated",
      active_package_success: "Package successfully activated",
      edit_cms_role_success: "CMS user data updated successfully",
      add_seed_success: "Seed added successfully.",
      treatment_empty: "Treatment Empty",
      update_package_data_success: "Package data update successful",
      add_package_data_success: "Package data added successful",
      new_password_success: "New Password from User",
      update_access_right_success: "Access rights edited successfully.",
      edit_video_tutorial_success: "video Tutorial successfully edited",
      add_video_tutorial_success: "video Tutorial successfully added",
      edit_field_manager_success: "Field Manager data updated successfully",
      search_not_found_subtitle: "Please check the keywords and try again.",
      edit_regional_head_success: "Regional Head data updated successfully",
      your_search_was_not_found: "Pencarian anda tidak ditemukan",
      edit_field_supervisor_success: "Field Supervisor data updated successfully",
      edit_fo_qa_success: "Field Officer QA data updated successfully",
      edit_fo_agronomist_success: "Field Officer Agronomist data updated successfully",
      edit_fo_success: "Field officer data successfully updated",
      success_setting_target_sub_regional: "Sub-regional target saved successfully."
    }
  },
  profile: {
    label_profile: "User Profile",
    label_language: "Select Language"
  },
  sales_order: {
    title: "Sales Order",
    table_label_order_id: "Order ID",
    table_label_area_name: "Area Name",
    table_label_farmer: "Farmer",
    table_label_activity_type: "Activity Type",
    table_label_created_date: "Created Date",
    table_label_no_spk: "No SPK",
    order_information: "ORDER INFORMATION",
    title_detail_spk: "DETAIL SURAT PERINTAH KERJA"
  },
  table_header: {
    field_manager: "field manager",
    field_supervisor: "field supervisor",
    area_name: "Area Name",
    block_name: "Block Name",
    activity_type: "Activity Type",
    created_date: "Created Date",
    farmer: "Farmer",
    mitra: "Mitra",
    planting_type: "Planting Type",
    status: "Status",
    request_id: "Reuest Id",
    user_mac: "User MAC",
    land_area: "Land Area",
    full_address_mac: "MAC FULL ADDRESS",
    plant_type: "plant type",
    plant_age: "Umur Tanaman",
    request_date: "Request Date",
    activity_date: "Activity Date",
    sub_regional_amount: "sub regional amount",
    overdue_date: "overdue date",
    plant_name: "plant name",
    user: "user",
    canceled_date: "cancel date",
    profile_mac: "MAC PROFILE",
    scheduled_by: "Scheduled by",
    scheduled_date: "Scheduled date",
    submit_by: "Diajukan Oleh",
    canceled_by: "canceled by",
    reject_by: "reject by",
    regional_id: "Regional ID",
    regional_name: "Regional Name",
    province: "Province",
    land_area_only: "Land Area",
    varietas: "Variety",
    female_date: "Female Planting Date",
    submission_date: "Submitted Date",
    submitted_by: "Submitted By",
    planting_date: "Planting Date",
    id_regional_head: "ID RH",
    id_field_manager: "ID FM",
    sub_regional: "Sub Regional",
    all: "All",
    area_lahan: "Area",
    kur_area: "KUR Area",
    status_area: "Area Status",
    planting_status: "Planting Status",
    submitted_date: "Submitted Date",
    reject_reason: "Reject Reason",
    reject_date: "Reject Date",
    area_address: "Area Address",
    harvest_date: "Harvest Date",
    action: "Action",
    name: "Name",
    address: "Address",
    farmer_id: "Id Petani",
    machine_name: "Machine Name",
    machine_owner: "Machine Owner",
    machine_type: "Machine Type",
    engine_number: "Engine Number",
    frame_number: "Frame Number",
    variant_name: "Variant Name",
    pesticide_form: "Pesticide Form",
    submitted_by_area: "Submitted By",
    mitra_total: "Mitra Total",
    category_name: "Category Name",
    reason_total: "Reason Total",
    role_name: "Role Name",
    email: "Email",
    regional: "Regional",
    role: "Role",
    headline: "Headline",
    receiver: "Receiver",
    submitted_time: "Submitted time",
    package_id: "Package ID",
    package_name: "Package Name",
    total_treatment: "Total Treatment",
    id_fs: "ID FS",
    paddy: "Paddy",
    corn: "Corn",
    corn_production: "Corn Production"
  },
  card_title: {
    planting_season_information: "Planting Season Information",
    area_location: "Area Location",
    reason_of_rejection: "Reason for Rejection",
    account_information: "Account Information",
    person_in_charge: "Person In Charge",
    status_change_reason: "Status Change Reason",
    farmer_data: "Farmer Data",
    farmer_bank_account: "Farmer Bank Account",
    ktp_selfie_farmer: "FARMER'S KTP & SELFIE WITH KTP",
    ktp_selfie: "KTP & SELFIE WITH KTP",
    ktp_selfie_mitra: "MITRA'S KTP & SELFIE WITH KTP",
    pesticide_information: "Pesticide Information",
    seed_information: "Seed Information",
    review_farmer_data: "Review Farmer Data",
    sub_district: "Subdistrict",
    farmer: "Farmer",
    photo_profile: "Photo Profile",
    mitra_list: "Mitra List",
    harvest_estimation_activity: "Harvest Estimation Activity",
    edit_user_cms: "Edit User CMS",
    add_user_cms: "Add User CMS"
  },
  tab_label: {
    area_detail: "Area Detail",
    activity: "activity",
    activity_history: "Activity History",
    area_location: "Area Location",
    log: "Log"
  },
  area: {
    registered_area: "Registered Area",
    mitra_name: "Mitra Name"
  }
}