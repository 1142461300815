import { Skeleton } from "@material-ui/lab"
import clsx from "clsx"
import { t } from "i18next"
import React from "react"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { sortCaret } from "../../../../../_metronic/_helpers/TableSortingHelpers.js"
import { CardBody } from "../../../../../_metronic/_partials/controls/Card.js"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG/detail.jsx"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component/index.jsx"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { useDetailKpiGoalSettingPage } from "./useDetailKpiGoalSettingPage.js"
export function DetailKpiGoalSettingPage() {
  const page = useDetailKpiGoalSettingPage()
  const columnProperties = [
    {
      text: t("table_header.name"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>
              {checkValue(e.sub_regional_name)}
            </div>
          </>
        )
      }
    },
    {
      text: t("table_header.paddy"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>{checkValue(e.paddy, "HA")}</div>
          </>
        )
      }
    },
    {
      text: t("table_header.corn"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>{checkValue(e.corn, "HA")}</div>
          </>
        )
      }
    },
    {
      text: t("table_header.corn_production"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-bolder font-size-lg"}>
              {checkValue(e.corn_production, "HA")}
            </div>
          </>
        )
      }
    },
    {
      text: "Forecast",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div>
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Detail {e.regional_name}</Tooltip>}>
              <div className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
                <DetailSvgIcon />
              </div>
            </OverlayTrigger>
          </div>
        )
      }
    }
  ]
  return (
    <>
      <Card>
        <CardBody>
          <div >
            <div className=" justify-content-between align-items-center">
              <h1>{t("label.target_regional").toUpperCase() + ` ${page.year}`}</h1>
              {page?.FieldOfficer.kpiGoalThisYearTargetRegional !== undefined ? (
                <Card className="rounded mt-4">
                  <CardBody
                    fit
                    className="rounded d-flex justify-content-between align-items-center bg-gray-card border-gray">
                    <div>
                      <h4>{checkValue(page?.FieldOfficer?.kpiGoalThisYearTargetRegional?.regional_name)}</h4>
                      <span>
                        {checkValue(page?.FieldOfficer?.kpiGoalThisYearTargetRegional?.regional_head_name)}
                      </span>
                    </div>
                    <div className="d-flex">
                      {page?.FieldOfficer?.kpiGoalThisYearTargetRegional?.target.map((targetItem) => (
                        <div
                          key={targetItem.plant_type_id}
                          className={clsx(
                            "separator-left-solid",
                            "separator-border-2",
                            "mr-4",
                            "pl-4",
                            "pr-4"
                          )}>
                          <span className="text-dark-50">{checkValue(targetItem.plant_type_name)}</span>
                          <h4>{checkValue(targetItem.value, "ha")}</h4>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <Skeleton animation="wave" className="rounded" variant={"rect"} height={100} />
              )}
            </div>
          </div>
          <div className="my-5">
            <h1>{t("label.target_sub_regional").toUpperCase() + ` ${page.year}`}</h1>
          </div>
          <Card className="mt-4">
            <CardBody>
              {page?.General?.loading ? (
                <TableSkeleton column={5} row={10} />
              ) : page?.FieldOfficer?.pageData && page?.FieldOfficer?.pageData.length === 0 ? (
                <EmptyCard
                  subtitle={t("messages.response.empty_search_subtitle")}
                  image={

                    ILLUSTRATION_ASSETS_V2.mitra.IL_EMPTY_STATE
                  }
                  body={
                    t("messages.response.empty_state", { name: "Target sub regional" })
                  }
                />
              ) : (
                <TableView
                  dataTable={page?.FieldOfficer?.pageData || []}
                  currentPage={page?.FieldOfficer?.page?.currentPage ?? 1}
                  currentElement={page?.FieldOfficer?.page?.currentElement ?? 10}
                  totalCount={page?.FieldOfficer?.page?.totalCount ?? 0}
                  columnProperties={columnProperties}
                  callbackAfterPageChange={page?.changePage}
                  loadingGetData={page?.General?.loading}
                  initialFilterTable={page?.currentParam}
                  ignoreSinglePage
                  rowClasses={() => {
                    return "cursor-pointer"
                  }}
                />
              )}
            </CardBody>
          </Card>
        </CardBody>
      </Card>


    </ >
  )
}
