import React from "react"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { DetailText } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { useTranslation } from "react-i18next"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../../../constants/StringConstant.js"
import { checkValue } from "../../../../helpers/TextHelper.js"

export const PreviewFieldOfficer = ({ formData }) => {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader title={t("label.review_data_team_operational")} />
      <CardBody>
        <RowModule>
          <Card>
            <CardHeader title={t("label.profile_picture").toUpperCase()} />
            <CardBody>
              <DetailText type="image" value={formData?.avatar} />
            </CardBody>
          </Card>
        </RowModule>
        <RowModule>
          <Card>
            <CardHeader title={t("label.data_operational_team").toUpperCase()} />
            <CardBody>
              <DetailText title={t("label.role")} value={checkValue(formData?.roleFO?.display_name)} />
              {formData?.roleFO?.name === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER && (
                <DetailText
                  title={t("label.assign_regional_head")}
                  value={formData?.regionalHead?.name || "-"}
                />
              )}
              {formData?.roleFO?.name === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER && (
                <DetailText
                  title={t("label.assign_to_field_supervisor")}
                  value={formData?.fieldSupervisor?.name || "-"}
                />
              )}

              {formData?.roleFO?.name === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR && (
                <DetailText
                  title={t("label.assign_to_field_manager")}
                  value={formData?.fieldManager?.name || "-"}
                />
              )}

              {(formData?.roleFO?.name === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER ||
                formData?.roleFO?.name === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR) && (
                <DetailText
                  title={t("label.sub_regional")}
                  value={
                    formData?.subRegional
                      ? [...formData.subRegional.map((item) => item.name)].join(", ")
                      : "-"
                  }
                />
              )}

              <DetailText title={t("label.name")} value={checkValue(formData?.name)} />
              {formData?.roleFO?.name === FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD && (
                <DetailText title={t("label.regional")} value={checkValue(formData?.regional?.name)} />
              )}

              <DetailText title={t("label.phone_number")} value={checkValue(formData?.phone_number)} />
              <DetailText title={t("label.email")} value={checkValue(formData?.email)} />
              <DetailText
                title={t("label.address").toUpperCase()}
                type="address"
                value={
                  formData?.address +
                  ", " +
                  formData?.postal_code +
                  ", " +
                  formData?.sub_district_name +
                  ", " +
                  formData?.district_name +
                  ", " +
                  formData?.regency_name +
                  ", " +
                  formData?.province_name
                }
              />
            </CardBody>
          </Card>
        </RowModule>
      </CardBody>
    </Card>
  )
}