const parseParam = (params, key) => {
  const urlSearchParams = new URLSearchParams(params)
  return urlSearchParams.get(key)
}

const dataArray = Array.from({ length: 30 }).map((_, i) => {
  return {
    id: "asd6as66d6sd6a6sd6asda6a" + i.toString().padStart(3, "0") + "-mock-fm",
    code: "RH-" + i.toString().padStart(3, "0"), // SORT
    name: "Freya Jayawardhana", // SORT
    phone_number: "666666666666666",
    sub_regional_list: ["Jatim 1", "Jatim 2", "Jatim 3"],
    status_enum: i % 2 ? "ACTIVE" : "INACTIVE",
    status_string: i % 2 ? "Active" : "Inactive",
    created_date: new Date() // SORT
  }
})

export const mockFieldManagerList = (param) => {
  const sizeParam = parseParam(param, "size")
  const pageParam = parseParam(param, "page")
  const size = sizeParam == null ? 10 : Number(sizeParam)
  const page = pageParam == null ? 0 : Number(pageParam)
  return new Promise((resolve) => {
    setTimeout(() => {
      const startIndex = page * size
      const endIndex = Number(startIndex) + Number(size)
      const paginatedData = dataArray.slice(startIndex, endIndex)
      resolve({
        data: paginatedData,
        all_element: dataArray.length,
        current_element: paginatedData.length,
        page
      })
    }, 3000)
  })
}

const dataDetail = (
  id,
  regionalHead = {
    id: "870d7cc5-f837-4a8f-a173-ae8f9d31d12d",
    name: "Rivo Rh 2"
  },
  subRegional = [
    {
      id: "d82ed39c-1570-4f6e-80f3-1549e993dfde",
      name: "Makassar - MKS"
    }
    ]
) => {
  return {
    success: true,
    data: {
      field_officer_id: "23d7f608-4a67-4070-9ab5-68bd9de2" + id,
      name: "danika " + id,
      phone_number: "879878930293021",
      email: "danika.clara@gmail.com",
      province_id: "112",
      regency_id: "162",
      postal_code: "46365",
      district_id: "2015",
      sub_district_id: "54266",
      full_address: "dwfwefewce, Cileungsing, Cikakak, Kabupaten Sukabumi, Jawa Barat",
      address: "dwfwefewce",
      url_profile:
        "https://s3-ap-southeast-1.amazonaws.com/maxxi-staging/image/user/AVATAR_48a806be-47f7-481a-9a9e-8fd9e47ca4a0",
      account_id: "045415ef-872b-430e-9c92-f9f0cedb86e3",
      role: "FIELD_MANAGER",
      field_officer_activation: "ACTIVE",
      field_officer_deactivation_reason: null,
      mitra_child_list: [],
      field_officer_child_list: [],
      // NEW RESPONSE
      regional_id: "34353d43-a8c9-4563-b3dc-0bec141e356d",
      regional_name: "Jatim 1",
      role_string: "Field Manager",
      field_manager_name: "",
      field_manager_id: "",
      sub_regional_list: subRegional,
      regional_head_id: regionalHead.id,
      regional_head_name: regionalHead.name,
      field_supervisor_name: "",
      field_supervisor_id: ""
    }
  }
}

export const mockFieldManagerDetail = (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dataDetail(id))
    }, 3000)
  })
}
