import { useFormik } from "formik"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers.js"
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout/index.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { FIELD_OFFICER, UPLOAD } from "../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { getDetailTargetByYearTargetRegional, submitEditTargetRegional } from "../../../../redux/actions/FieldOfficerAction.jsx"
import { resetUrlResponse, uploadAction } from "../../../../redux/actions/MasterDataAction.jsx"
import { getMinimumErrorNumber } from "../../../helpers/StringCollection.js"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { KPI_GOAL_SETTING_TAB } from "../../../../constants/StringConstant.js"
export function useEditTargetRegionalPage() {
  const dispatch = useDispatch()
  const user = loadUserLogin()
  const history = useHistory()
  const subHeader = useSubheader()
  const uiService = useHtmlClassService()

  const regionalId = user?.regional_id
  const { year } = useParams()
  const { MasterData, General, FieldOfficer } = useSelector((state) => state)
  const [urlDocument, setUrlDocument] = useState(null)
  const [fileAttachments, setFileAttachments] = useState([])
  const [documentUrl, setDocumentUrl] = useState()
  const [targetId, setTargetId] = useState();
  const [activeStep, setActiveStep] = useState(0)
  const labelStep = [t("label.edit_target"), t("label.preview")]

  const validationSchema = Yup.object().shape({
    target: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .test("is-not-zero", getMinimumErrorNumber(0), (val, ctx) => {
            if (ctx?.options?.index === 2) return true
            return val !== 0
          })
          .required("Value harus diisi")
      })
    )
  })

  useEffect(() => {
    uiService.setFullPage()
    return () => {
      uiService.setExitFullPage()
    }
  }, [])

  useEffect(() => {
    subHeader.setButton()
  }, [])

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      year: year,
      target: [],
      document_url: ""
    },
    onSubmit: () => {
      if (targetId) {
        setActiveStep(1)
      }
    }
  })

  function onSubmit() {
    const e = formik.values
    if (e) {
      const data = {
        year: parseInt(e.year),
        target: formik.values.target.map(v => {
          return {
            ...v,
            value: parseInt(v.value)
          }
        })
      }
      dispatch(submitEditTargetRegional(targetId, data))
    }

  }

  function onRemoveFile() {
    setFileAttachments([])
    formik.setFieldValue("document_url", undefined)
  }

  useEffect(() => {
    if (General?.isFinishUpdateOrDelete && General?.actionInitType === FIELD_OFFICER.SUBMIT_TARGET)
      swal({
        text: t("messages.response.success_setting_target_regional_and_sub_regional"),
        icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.IL_SUCCESS_SETTING_TARGET)
      }).then(() => {
        history.push(RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.THIS_YEAR))
      })
  }, [General?.isFinishUpdateOrDelete])


  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      formik.setFieldValue("document_url", MasterData?.urlResponseUpload)
      return () => {
        dispatch(resetUrlResponse())
      }
    }

  }, [MasterData.urlResponseUpload])


  useEffect(() => {
    if (!FieldOfficer?.page?.target) return
    formik.setFieldValue("target", FieldOfficer?.page?.target)
    setTargetId(FieldOfficer?.page?.id)
    setDocumentUrl(FieldOfficer?.page?.document_url)
  }, [FieldOfficer?.page?.target])

  function fetchData() {
    if (year && regionalId) {
      dispatch(getDetailTargetByYearTargetRegional(year, regionalId))
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  function onChangeUpload(file) {
    dispatch(uploadAction({ data: file, type: UPLOAD.AVATAR }))
    setFileAttachments([file])
  }

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      setUrlDocument(MasterData.urlResponseUpload)
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])



  function checkDisableButtonRegional() {
    const data = formik.values.target
    if (formik?.isValid) {
      if (!General?.loading) {
        if (urlDocument) {
          if (formik.values.document_url) {
            return data.some((item) => isNaN(item.value) || parseInt(item.value) < 1)
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      return true
    }

  }

  async function onClickDownloadDocument() {
    if (documentUrl) {
      try {
        const response = await fetch(documentUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'surat_referensi_perubahan_data_target_tanam';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the image:', error);
      }
    }

  }

  function onClickReset() {
    if (!FieldOfficer?.page?.target) return
    formik.setFieldValue("target", FieldOfficer?.page?.target)
    setTargetId(FieldOfficer?.page?.id)
    setDocumentUrl(FieldOfficer?.page?.document_url)
  }

  return {
    onClickReset,
    formik,
    onChangeUpload,
    urlDocument,
    fileAttachments,
    setFileAttachments,
    General,
    checkDisableButtonRegional,
    onClickDownloadDocument,
    setActiveStep,
    activeStep,
    labelStep,
    uiService,
    onSubmit,
    onRemoveFile
  }
}
