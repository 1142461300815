import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { useParams } from "react-router"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { Card, Form } from "react-bootstrap"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getDetailRegional,
  getDetailSubRegional,
  getListAvailableRegencyRegional,
  getListAvailableRegencyRegionalEdit,
  submitCreateSubRegional,
  submitEditSubRegional
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import { ChipsMultipleInput } from "../../../../../../component/atoms/ChipsMultipleInput.jsx"
import { PopupQuestion } from "../../../../../../component/atoms/Popup/index.jsx"
import { MASTER } from "../../../../../../constants/InitTypeConstants.js"
import swal from "sweetalert"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../../constants/ImageConstants.js"
import { useHistory } from "react-router-dom"

export function CreateSubRegionalPage() {
  const { t } = useTranslation()
  const { regionalId, subRegionalId } = useParams()
  const { MasterData, General } = useSelector((state) => state)
  const subheader = useSubheader()
  const dispatch = useDispatch()
  const history = useHistory()

  const [dataDetail, setDataDetail] = useState()
  const [listRegency, setListRegency] = useState([])
  const [selectedRegency, setSelectedRegency] = useState([])
  const [dataForm, setDataForm] = useState()
  const [disableBtn, setDisableBtn] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [detailSubRegional, setDetailSubRegional] = useState()
  const formik = useFormik({
    initialValues: {
      regional_name: "",
      sub_regional_name: ""
    },
    onSubmit: (values) => {
      if (regionalId) {
        setShowPopup(true)
        setDataForm({
          sub_regional_name: values.sub_regional_name,
          regional_id: regionalId,
          regency_id: selectedRegency.map((item) => item.value)
        })
      }
    }
  })

  useEffect(() => {
    if (MasterData?.detailRegional) {
      setDataDetail(MasterData?.detailRegional)
    }
    if (MasterData?.detailSubRegional) {
      setDetailSubRegional(MasterData?.detailSubRegional)
    }
  }, [MasterData])

  useEffect(() => {
    if (subRegionalId) {
      if (detailSubRegional?.regency_list) {
        const data = detailSubRegional.regency_list.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
        setSelectedRegency(data)
      }
      if (detailSubRegional?.name) {
        formik.setFieldValue("sub_regional_name", detailSubRegional?.name)
      }
    }
  }, [detailSubRegional, subRegionalId])
  useEffect(() => {
    formik.setFieldValue("sub_regional_name", "")
    setSelectedRegency([])
  }, [])


  useEffect(() => {
    if (
      General?.isFinishUpdateOrDelete &&
      (General?.actionInitType === MASTER.CREATE_SUB_REGIONAL ||
        General?.actionInitType === MASTER.EDIT_REGIONAL_MASTER_DATA)
    ) {
      swal({
        icon: subRegionalId
          ? ILLUSTRATION_ASSETS_V2.IL_POSITIVE_DIPERBARUI
          : ILLUSTRATION_ASSETS_V2.IL_SUCCESS_ADD,
        text: t(
          subRegionalId
            ? "messages.response.success_edit_sub_regional"
            : "messages.response.success_create_regional"
        )
      })
      setShowPopup(false)
      if (regionalId) {
        history.push(RoutesConstants.MASTER_DATA.REGIONAL.DETAIL_REGIONAL(regionalId))
      }
    }
  }, [General])

  useEffect(() => {
    subheader.setTitle(t(subRegionalId ? "label.edit_sub_regional" : "label.add_sub_regional"))

    fetchData()
  }, [regionalId])

  useEffect(() => {
    if (subRegionalId) {
      subheader.setBreadcrumbs([
        {
          pathname: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
          title: t("label.regional")
        },
        {
          pathname: RoutesConstants.MASTER_DATA.REGIONAL.DETAIL_REGIONAL(regionalId),
          title: dataDetail?.regional_name
        },
        {
          title: t("label.edit_sub_regional")
        }
      ])
    } else {
      subheader.setBreadcrumbs([
        {
          pathname: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
          title: t("label.regional")
        },

        {
          title: t("label.add_sub_regional")
        }
      ])
    }
  }, [dataDetail])

  useEffect(() => {
    if (
      formik.values.regional_name &&
      formik.values.sub_regional_name &&
      selectedRegency.length > 0
    ) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }, [formik.values, selectedRegency])

  useEffect(() => {
    const data = MasterData?.listAvailableRegencyRegional
    if (data) {
      const list = data.map((item) => {
        return {
          label: item.regency_name,
          value: item.regency_id
        }
      })
      setListRegency(list)
    }
  }, [MasterData])

  function onSubmit() {
    if (dataForm) {
      setShowPopup(false)
      if (subRegionalId) {
        dispatch(submitEditSubRegional(dataForm, subRegionalId))
      } else {
        dispatch(submitCreateSubRegional(dataForm))
      }
    }
  }
  function fetchData() {
    if (regionalId) {
      if (subRegionalId) {
        dispatch(getListAvailableRegencyRegionalEdit(subRegionalId))
        dispatch(getDetailSubRegional(subRegionalId))
      } else {
        dispatch(getListAvailableRegencyRegional(regionalId))
      }
      dispatch(getDetailRegional(regionalId))
    }
  }
  useEffect(() => {
    subheader.setButton(btnSubmit())
  }, [disableBtn])

  useEffect(() => {
    if (dataDetail) {
      formik.setFieldValue(
        "regional_name",
        dataDetail?.regional_name + ` (${dataDetail?.regional_code})`
      )
    }
  }, [dataDetail])

  function onSubmitCreateSubRegional() {
    onSubmit()
  }

  function onCancelPopup() {
    setShowPopup(false)
  }
  function btnSubmit() {
    return (
      <button
        disabled={disableBtn}
        type="button"
        className="btn btn-primary"
        onClick={() => formik.handleSubmit()}>
        {t("button.submit")}
      </button>
    )
  }

  function componentPreview() {
    return (
      <div>
        <div className={"text-center"}>
          {t(
            subRegionalId
              ? "messages.confirmation.edit_sub_regional"
              : "messages.confirmation.create_sub_regional"
          )}
        </div>
      </div>
    )
  }

  return (
    <Card>
      <PopupQuestion
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        onOk={onSubmitCreateSubRegional}
        onCancel={onCancelPopup}
        title={subRegionalId ? "PERBARUI SUB REGIONAL" : t("label.add_sub_regional").toUpperCase()}
        show={showPopup}
        bodyCustom={componentPreview()}
      />
      <CardBody>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            paddingTop: 32,
            paddingBottom: 32
          }}>
          <div style={{ width: 720, display: "flex", flexDirection: "column", gap: 12 }}>
            <h3>{t("label.detail_sub_regional").toUpperCase()}</h3>
            <div>
              <div style={{ paddingBottom: 2 }}>
                {t("label.regional")} <span className={"text-danger"}> *</span>
              </div>
              <Form.Control
                disabled
                name="regional_name"
                className={formik.errors.regional_name && "is-invalid"}
                value={formik.values.regional_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={t("label.insert_regional_name")}
              />
            </div>
            <div>
              <div style={{ paddingBottom: 2 }}>
                {t("label.sub_regional_name")} <span className={"text-danger"}> *</span>
              </div>
              <Form.Control
                name="sub_regional_name"
                className={formik.errors.regional_name && "is-invalid"}
                value={formik.values.sub_regional_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={t("placeholder.insert_sub_regional_name")}
              />
            </div>
            <ChipsMultipleInput
              required={true}
              placeholder={"Silahkan pilih atau ketik kabupaten/kota"}
              label={"Kabupaten/Kota"}
              value={selectedRegency}
              onChange={(e) => setSelectedRegency(e)}
              options={listRegency}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}