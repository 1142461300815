import React, { useCallback, useEffect, useState } from "react"
import { isEqual, isFunction } from "lodash"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import { Pagination } from "../../../_metronic/_partials/controls"
import BootstrapTable from "react-bootstrap-table-next"
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage
} from "../../../_metronic/_helpers"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

export const initialFilterTable = {
  sortOrder: null, // asc||desc
  sortField: null,
  pageNumber: 1,
  pageSize: 20
}

export const initialFilterTableNoSize = {
  sortOrder: null, // asc||desc
  sortField: null,
  pageNumber: 1
}

export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
]

export function formatterRowComponent(cellContent, row, rowIndex, formatExtraData) {
  if (formatExtraData) {
    const { onClickEvent, externalStyle } = formatExtraData
    let show = cellContent
    if (externalStyle) {
      show = externalStyle(row)
    }
    const onClick = () => {
      if (onClickEvent) onClickEvent(row)
    }
    if (onClickEvent) {
      return (
        <a className="text-dark text-hover-primary mb-1 font-size-lg" onClick={onClick}>
          {show}
        </a>
      )
    } else {
      return <> {show}</>
    }
  } else {
    return <>{cellContent}</>
  }
}

const TableView = ({
  dataTable, //data From api
  columnProperties, // settingan layaut component
  totalCount, //total count please use in api standard
  currentElement, // current Element  please use in api standard
  currentPage, // current page without plus 1  please use in api standard
  loadingGetData,
  callbackAfterPageChange,
  keyField = "id",
  selectable = false,
  onSelect,
  onSelectAll,
  showingTotalPage,
  disablePagination,
  selected,
  selectedParam,
  disabledCentered,
  disabledPadding,
  ...rest
}) => {
  const { General } = useSelector((state) => state)
  const [queryParams, setQueryParamsBase] = useState(initialFilterTable)
  const setQueryParams = useCallback(
    (nextQueryParams) => {
      setQueryParamsBase((prevQueryParams) => {
        if (isFunction(nextQueryParams)) {
          nextQueryParams = nextQueryParams(prevQueryParams)
        }
        if (isEqual(prevQueryParams, nextQueryParams)) {
          return prevQueryParams
        }
        callbackAfterPageChange(nextQueryParams)
        return nextQueryParams
      })
    },
    [callbackAfterPageChange]
  )

  useEffect(() => {
    setQueryParams(initialFilterTable)
  }, [selectedParam])

  useEffect(() => {
    return queryParams
  }, [queryParams])
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: currentElement,
    page: currentPage + 1
  }

  return (
    <div className={General.loading || dataTable.length === 0 ? "d-none" : ""}>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              disablePagination={disablePagination}
              isLoading={loadingGetData}
              paginationProps={paginationProps}
              showingTotalPage={showingTotalPage}>
              {selectable ? (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom bordered  table-vertical-center overflow-hidden"
                  bootstrap4
                  bordered={false}
                  remote
                  keyField={keyField}
                  data={dataTable === null ? [] : dataTable}
                  columns={columnProperties}
                  selectRow={{
                    mode: "checkbox",
                    clickToSelect: true,
                    onSelect,
                    onSelectAll,
                    selected
                  }}
                  {...rest}
                  onTableChange={getHandlerTableChange(setQueryParams)}
                  {...paginationTableProps}>
                  <PleaseWaitMessage entities={dataTable} />
                  <NoRecordsFoundMessage entities={dataTable} />
                </BootstrapTable>
              ) : (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes={`table table-head-custom  overflow-hidden ${
                    disabledCentered ? " " : "table-vertical-center"
                  } ${disabledPadding ? " disable-padding-table" : " "}`}
                  bootstrap4
                  bordered={false}
                  remote
                  keyField={keyField}
                  data={dataTable === null ? [] : dataTable}
                  columns={columnProperties}
                  {...rest}
                  onTableChange={getHandlerTableChange(setQueryParams)}
                  {...paginationTableProps}>
                  <PleaseWaitMessage entities={dataTable} />
                  <NoRecordsFoundMessage entities={dataTable} />
                </BootstrapTable>
              )}
            </Pagination>
          )
        }}
      </PaginationProvider>
    </div>
  )
}

export default TableView

TableView.propTypes = {
  dataTable: PropTypes.array.isRequired,
  columnProperties: PropTypes.array.isRequired,
  // totalCount: PropTypes.number.isRequired,
  // currentPage: PropTypes.number.isRequired,
  // currentElement: PropTypes.number.isRequired,
  loadingGetData: PropTypes.bool.isRequired,
  callbackAfterPageChange: PropTypes.func.isRequired
}
