import React, { useEffect, useState } from "react"
import { FieldFeedbackLabel } from "./FieldFeedbackLabel"
import { useSelector } from "react-redux"
import { thousandSeparator } from "../../../../app/helpers/TextHelper"

const getFieldCSSClasses = (touched, errors, editable, withTogglePass, errorMessage) => {
  const classes = ["form-control"]
  if (editable) {
    if (touched && errors && !withTogglePass) {
      classes.push("is-invalid")
    }

    if (errorMessage) {
      classes.push("is-invalid-toggle")
    }
    if (touched && errors && withTogglePass && errorMessage) {
      classes.push("is-invalid-toggle")
    }

    if (touched && !errors && !withTogglePass) {
      classes.push("is-valid")
    }

    if (touched && !errors && withTogglePass) {
      classes.push("is-valid-toggle")
    }
  }
  const value = classes.join(" ")

  return value
}

export const Input = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  value,
  editable,
  errorMessage,
  onChange,
  customFeedbackLabel,
  type = "text",
  allowEmpty = false,
  withTogglePass = false,
  onChangeValue,
  counter,
  required,
  placeholder,
  className,
  onKeyUp,
  endIcon,
  unit,
  onBlur
}) => {
  const { General } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const [openPass, setOpenPass] = useState(false)
  const [counterValue] = useState(0)
  useEffect(() => {
    onChangeValue(field.value)
  }, [field.value])

  useEffect(() => {
    setLoading(General.loading)
  }, [General])
  useEffect(() => {
    const changeValue = () => {
      if (field.value !== value) {
        form.setFieldValue(field.name, value)
      }
    }
    if (allowEmpty) {
      changeValue()
    } else {
      if (value) {
        changeValue()
      }
    }
  }, [value])

  useEffect(() => {
    if (type === "rupiah") {
      const parsedInteger = parseInt(field.value.replace(/\./g, ""))

      form.setFieldValue(field.name, thousandSeparator(parsedInteger ?? 0))
    }
  }, [field.value])

  useEffect(() => {
    if (value === "") {
      form.setFieldValue(field.name, value)
    }
  }, [value])

  const disabledInput = () => {
    if (editable) {
      return !loading
    }
    return editable
  }

  return (
    <>
      {label && (
        <label>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      {type === "number" && (
        <div className="input-group ">
          {endIcon && (
            <div
              style={{
                position: "absolute",
                top: 8,
                right: 10,
                zIndex: 4,
                height: "fit-content",
                width: "fit-content"
              }}>
              {endIcon}
            </div>
          )}
          <input
            style={{
              borderRadius: 4
            }}
            type="number"
            onBlur={onBlur}
            pattern="[0-9]*"
            className={getFieldCSSClasses(
              form.touched[field.name],
              form.errors[field.name],
              editable,
              withTogglePass,
              errorMessage
            )}
            readOnly={!disabledInput()}
            {...field}
            placeholder={placeholder}
            name={field.name}
            value={field.value}
          />
        </div>
      )}
      {type !== "number" && (
        <>
          {type === "rupiah" ? (
            <div className="input-group ">
              <div
                style={{
                  position: "absolute",
                  top: 8,
                  left: 10,
                  zIndex: 4,
                  height: "fit-content",
                  width: "fit-content"
                }}>
                Rp.
              </div>
              <input
                style={{
                  paddingLeft: 32,
                  borderRadius: 4
                }}
                type="text"
                pattern="[0-9]*"
                className={getFieldCSSClasses(
                  form.touched[field.name],
                  form.errors[field.name],
                  editable,
                  withTogglePass
                )}
                readOnly={!disabledInput()}
                {...field}
                placeholder={placeholder}
                name={field.name}
                value={field.value}
              />
              {withTogglePass && (
                <div
                  className="input-group-addon input-eye-container"
                  onClick={() => setOpenPass(!openPass)}>
                  <i className={openPass ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i>
                </div>
              )}
            </div>
          ) : (
            <div className="input-group">
              <input
                type={withTogglePass && openPass ? "text" : type}
                className={`
                  ${
                    className
                      ? className
                      : getFieldCSSClasses(
                          form.touched[field.name],
                          form.errors[field.name],
                          editable,
                          withTogglePass
                        )
                  }
                  ${unit ? "pr-20" : ""}
                `}
                readOnly={!disabledInput()}
                {...field}
                placeholder={placeholder}
                name={field.name}
                value={field.value}
                onKeyUp={onKeyUp}
              />
              {unit && (
                <div
                  className={"unit"}
                  style={{
                    position: "absolute",
                    right: "24px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: "9"
                  }}>
                  {unit}
                </div>
              )}
              {withTogglePass && (
                <div
                  className="input-group-addon input-eye-container"
                  onClick={() => setOpenPass(!openPass)}>
                  <i className={openPass ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {/* {type !== 'number' && <input
                type={type}
                className={getFieldCSSClasses(spraying-approval.touched[field.name], spraying-approval.errors[field.name], editable)}
                readOnly={!disabledInput()}
                {...field}
                {...props}
            />} */}
      {editable && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
          onChange={onChange}
          value={value}
        />
      )}
      {errorMessage && <div className={"text-danger"}>{errorMessage}</div>}
      {counter && (
        <div
          className={" text-black-50 pt-1 "}
          style={{
            fontSize: 10,
            zIndex: 10000,
            bottom: 3,
            right: 3,
            textAlign: "end"
          }}>
          {counterValue}/{counter}
        </div>
      )}
    </>
  )
}

export function InputRadio({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  value,
  editable,
  customFeedbackLabel,
  type = "text",
  withoutFeedback,
  chooseAtBack = false,
  checked = false,
  placeholder,
  onClick
}) {
  const { General } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(General.loading)
  }, [General])
  useEffect(() => {
    if (value) {
      if (field.value !== value) {
        form.setFieldValue(field.name, value)
      }
    }
  }, [value])

  const disabledInput = () => {
    if (editable) {
      return !loading
    }
    return editable
  }

  const styleRadio = {
    width: "1rem",
    marginRight: "1rem"
  }

  const styleRadioBack = {
    width: "1rem",
    cursor: "pointer"
  }

  const styleChoose = {
    fontWeight: "bold",
    color: "#6CC049"
  }

  return (
    <>
      <div className="d-flex align-items-center">
        {!chooseAtBack && type !== "number" && (
          <input
            type="radio"
            id={field.value}
            style={styleRadio}
            className={getFieldCSSClasses(
              form.touched[field.name],
              form.errors[field.name],
              editable
            )}
            readOnly={!disabledInput()}
            checked={true}
            {...field}
            placeholder={placeholder}
            name={field.name}
            value={field.value}
            onClick={onClick}
          />
        )}
        {label && (
          <label
            htmlFor={field.value}
            className="mb-0 flex-fill cursor-pointer"
            style={checked ? styleChoose : null}>
            {label}
          </label>
        )}
        {chooseAtBack && type !== "number" && (
          <input
            type="radio"
            id={field.value}
            style={styleRadioBack}
            className={getFieldCSSClasses(
              form.touched[field.name],
              form.errors[field.name],
              editable
            )}
            checked={checked}
            readOnly={!disabledInput()}
            {...field}
            placeholder={placeholder}
            name={field.name}
            value={field.value}
            onClick={onClick}
          />
        )}
      </div>
      {editable && !withoutFeedback && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          touched={form.touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  )
}
