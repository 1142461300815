import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const DetailSvgIcon = ({ size = "md" }) => {
  return (
    <span className={`svg-icon svg-icon-${size} svg-icon-primary`}>
      <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_DETAIL)} />
    </span>
  )
}
