import { mockFieldManagerDetail } from "../../app/mocks/MockFieldManager.js"
import { requestDelete, requestGet, requestPost, requestPut } from "../../config"
import { getUrlParsingPage, getUrlParsingPageFilter } from "../../config/EndpointCollection.js"
import { FIELD_OFFICER } from "../../constants/InitTypeConstants"
import { FIELD_OFFICER_URL } from "../../constants/UrlConstants"
import { FieldOfficerSlice } from "../reducers/FieldOfficer"
import { GeneralSlice } from "../reducers/General"

const { actions: general } = GeneralSlice
const { actions: fieldOfficer } = FieldOfficerSlice

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    fieldOfficer.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export const getDetailTargetByYearTargetRegional = (year, regionalId) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.DETAIL_TARGET_REGIONAL_BY_YEAR
      })
    )
    await requestGet(FIELD_OFFICER_URL.GET_TARGET_REGIONAL_YEAR(year, regionalId))
      .then((res) => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.DETAIL_TARGET_REGIONAL_BY_YEAR
          })
        )
        dispatch(
          fieldOfficer.setPageData({
            page: res.data
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        // dispatch(
        //   fieldOfficer.setPageData({
        //     page: mockDataTargetRegionalByYear
        //   })
        // )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.DETAIL_TARGET_REGIONAL_BY_YEAR
          })
        )
      })
  }
}

export const getDetailTargetByYearTargetSubRegional = (year, regionalId) => {
  return async (dispatch) => {
    dispatch(fieldOfficer.setkpiGoalInitDataEditSubRegionalTarget([]))
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.LIST_TARGET_SUBREGIONAL_BY_YEAR
      })
    )
    try {
      let res = await requestGet(
        FIELD_OFFICER_URL.GET_TARGET_SUBREGIONAL_YEAR_DETAIL(year, regionalId)
      )
      dispatch(fieldOfficer.setkpiGoalInitDataEditSubRegionalTarget(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.LIST_TARGET_SUBREGIONAL_BY_YEAR
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListCombobox = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_ALL_FOR_COMBOBOX
      })
    )
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_FOR_COMBOBOX)
      dispatch(fieldOfficer.setCombobox(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_ALL_FOR_COMBOBOX
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createFo = (dataFieldOfficer) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FIELD_OFFICER.UPDATE
        })
      )
      await requestPost(FIELD_OFFICER_URL.CREATE_FO, dataFieldOfficer)
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: FIELD_OFFICER.UPDATE
        })
      )
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: FIELD_OFFICER.UPDATE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: FIELD_OFFICER.UPDATE, e }))
    }
  }
}

export const submitEditTargetRegional = (regionalId, data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: FIELD_OFFICER.SUBMIT_TARGET
      })
    )
    await requestPut(FIELD_OFFICER_URL.EDIT_TARGET_REGIONAL(regionalId), undefined, data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.SUBMIT_TARGET
          })
        )
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.SUBMIT_TARGET
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError({ actionInitType: FIELD_OFFICER.SUBMIT_TARGET, e }))
      })
  }
}

export const submitSettingTargetRegional = (regionalId, data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: FIELD_OFFICER.SUBMIT_TARGET
      })
    )
    await requestPost(FIELD_OFFICER_URL.SUBMIT_TARGET_REGIONAL(regionalId), data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.SUBMIT_TARGET
          })
        )
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.SUBMIT_TARGET
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError({ actionInitType: FIELD_OFFICER.SUBMIT_TARGET, e }))
      })
  }
}

export const submitEditSettingTargetSubRegional = (regionalId, data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: FIELD_OFFICER.SUBMIT_EDIT_TARGET
      })
    )
    await requestPut(FIELD_OFFICER_URL.EDIT_TARGET_SUBREGIONAL(regionalId), "", data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.SUBMIT_EDIT_TARGET
          })
        )
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.SUBMIT_EDIT_TARGET
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError({ actionInitType: FIELD_OFFICER.SUBMIT_EDIT_TARGET, e }))
      })
  }
}

export const updateFo = (dataFieldOfficer) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FIELD_OFFICER.UPDATE
        })
      )
      await requestPut(FIELD_OFFICER_URL.CREATE_FO, null, dataFieldOfficer)
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: FIELD_OFFICER.UPDATE
        })
      )
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: FIELD_OFFICER.UPDATE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: FIELD_OFFICER.UPDATE, e }))
    }
  }
}

export const assignFo = (childId, parentId, isFo) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FIELD_OFFICER.ASSIGN_FO
        })
      )
      if (isFo) {
        await requestPost(FIELD_OFFICER_URL.ASSIGN_FO_TO_FO, {
          account_id: childId,
          field_officer_account_id: parentId
        })
      } else {
        await requestPost(FIELD_OFFICER_URL.ASSIGN_MITRA_TO_FO, {
          mitra_id: childId,
          field_officer_account_id: parentId
        })
      }
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: FIELD_OFFICER.ASSIGN_FO
        })
      )
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: FIELD_OFFICER.ASSIGN_FO
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: FIELD_OFFICER.ASSIGN_FO, e }))
    }
  }
}

export const assignFoBulk = (childId, parentId) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FIELD_OFFICER.ASSIGN_FO_BULK
        })
      )
      await requestPost(FIELD_OFFICER_URL.ASSIGN_MITRA_TO_FO_BULK, {
        mitra_id: childId,
        field_officer_account_id: parentId
      })
      dispatch(
        general.finishUpdateApi({
          actionInitType: FIELD_OFFICER.ASSIGN_FO_BULK,
          isFinishUpdateOrDelete: true
        })
      )
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: FIELD_OFFICER.ASSIGN_FO_BULK
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: FIELD_OFFICER.ASSIGN_FO, e }))
    }
  }
}

export const getPageData = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST + param)
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListAssignOpsAdmin = (param, rhId) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_ASSIGN_OPS_ADMIN(rhId) + param)
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetail = (id) => {
  return async (dispatch) => {
    if (id) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: FIELD_OFFICER.GET_DETAIL
        })
      )
      dispatch(fieldOfficer.getDetail({}))
      try {
        let res = await requestGet(FIELD_OFFICER_URL.GET_DETAIL(id))
        dispatch(fieldOfficer.getDetail(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getListByMitra = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPage(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_BY_MITRA
      })
    )
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_BY_MITRA + param.id + urlData)
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST_BY_MITRA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const setAssignTab = (value) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_ASSIGN_TAB
      })
    )
    dispatch(fieldOfficer.setAssignTab({ tab: value }))
    dispatch(
      general.setLoading({
        loading: false,
        actionInitType: FIELD_OFFICER.GET_ASSIGN_TAB
      })
    )
  }
}

export const clearDataFO = () => {
  return async (dispatch) => {
    dispatch(fieldOfficer.setPageData(false))
  }
}

export const getListAssignMitra = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_ASSIGN_MITRA
      })
    )
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_ASSIGN_MITRA + urlData)
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST_ASSIGN_MITRA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const deleteListAssignMitra = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.DELETE_LIST_ASSIGN_MITRA
      })
    )
    try {
      await requestDelete(FIELD_OFFICER_URL.DELETE_LIST_ASSIGN_MITRA + param)
      dispatch(
        general.finishUpdateApi({
          actionInitType: FIELD_OFFICER.DELETE_LIST_ASSIGN_MITRA,
          isFinishUpdateOrDelete: true
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.DELETE_LIST_ASSIGN_MITRA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const deactivateStatusUserFO = (param, payload) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FIELD_OFFICER.DEACTIVATE_FIELD_OFFICER
        })
      )
      try {
        await requestDelete(FIELD_OFFICER_URL.DEACTIVATE_FIELD_OFFICER + param, payload)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.DEACTIVATE_FIELD_OFFICER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.DEACTIVATE_FIELD_OFFICER
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getListRoleFO = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_ALL_ROLE_FO
      })
    )
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_ROLE_FO)
      dispatch(fieldOfficer.getRole([...res.data]))
      dispatch(fieldOfficer.getRole([...res.data]))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_ALL_ROLE_FO
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const updateImage = ({ id, data }) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: FIELD_OFFICER.UPDATE_IMAGE
        })
      )
      await requestPut(FIELD_OFFICER_URL.UPDATE_IMAGE + id, null, data)
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.UPDATE_IMAGE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: FIELD_OFFICER.UPDATE_IMAGE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: FIELD_OFFICER.UPDATE_IMAGE, e }))
    }
  }
}

export const getAvailableMember = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_AVAILABLE_MEMBER
      })
    )
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_AVAILABLE_MEMBER)
      dispatch(fieldOfficer.getAvailableMember(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST_AVAILABLE_MEMBER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const getAvailableMemberEdit = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_AVAILABLE_MEMBER
      })
    )
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_AVAILABLE_MEMBER_EDIT + id)
      dispatch(fieldOfficer.getAvailableMember(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST_AVAILABLE_MEMBER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export function createTeamAssign(data) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: FIELD_OFFICER.CREATE_ASSIGN_TEAM
      })
    )
    requestPost(FIELD_OFFICER_URL.CREATE_ASSIGN_TEAM(), data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.CREATE_ASSIGN_TEAM
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.CREATE_ASSIGN_TEAM
          })
        )
      })
      .catch((e) => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.CREATE_ASSIGN_TEAM
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.CREATE_ASSIGN_TEAM
          })
        )
        dispatch(general.setError(e))
      })
  }
}

export const createTeamDrone = (data, id) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: FIELD_OFFICER.CREATE_UPDATE_TEAM
        })
      )
      try {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: FIELD_OFFICER.CREATE_UPDATE_TEAM
          })
        )
        if (id) {
          await requestPut(FIELD_OFFICER_URL.CREATE_UPDATE_TEAM + id, null, data)
        } else {
          await requestPost(FIELD_OFFICER_URL.CREATE_UPDATE_TEAM, data)
        }
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: FIELD_OFFICER.CREATE_UPDATE_TEAM
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getListFOAgro = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_AGRO + param)
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          },
          param: "FO_AGRO"
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListFOTeam = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(FIELD_OFFICER_URL.GET_LIST_DRONE_TEAM + param)
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          },
          param: "TEAM_DRONE"
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const GetDetailHeaderTeamDrone = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actions: FIELD_OFFICER.GET_DETAIL_HEADER_TEAM_DRONE
      })
    )
    dispatch(fieldOfficer.getDetail({}))
    await requestGet(FIELD_OFFICER_URL.GET_HEADER_DETAIL_TEAM_DRONE + id)
      .then((res) => {
        if (res) {
          dispatch(fieldOfficer.getDetail(res.data))
          dispatch(
            general.setLoading({
              loading: false,
              actions: FIELD_OFFICER.GET_DETAIL_HEADER_TEAM_DRONE
            })
          )
        }
      })
      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actions: FIELD_OFFICER.GET_DETAIL_HEADER_TEAM_DRONE
          })
        )
      })
  }
}

export const clearDetailFo = () => {
  return async (dispatch) => {
    dispatch(fieldOfficer.getDetail({}))
  }
}

export const clearCalendarActions = () => {
  return async (dispatch) => {
    dispatch(fieldOfficer.getCalendar(undefined))
  }
}
export const GetTeamScheduleCalendar = (param = "") => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_TEAM_SCHEDULE
      })
    )
    dispatch(fieldOfficer.getCalendar([]))

    await requestGet(FIELD_OFFICER_URL.GET_TEAM_SCHEDULE + param)
      .then((res) => {
        dispatch(fieldOfficer.getCalendar(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_TEAM_SCHEDULE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(
          general.setLoading({
            loading: false,
            actions: FIELD_OFFICER.GET_DETAIL_HEADER_TEAM_DRONE
          })
        )
      })
  }
}

export const GetDetailCalendarTeamDrone = (id, param = "") => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_DETAIL_TEAM_DRONE_CALENDAR
      })
    )
    dispatch(fieldOfficer.getCalendarDetail([]))

    await requestGet(FIELD_OFFICER_URL.GET_CALENDAR_DETAIL_TEAM_DRONE + id + param)
      .then((res) => {
        dispatch(fieldOfficer.getCalendarDetail(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actions: FIELD_OFFICER.GET_DETAIL_TEAM_DRONE_CALENDAR
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(
          general.setLoading({
            loading: false,
            actions: FIELD_OFFICER.GET_DETAIL_TEAM_DRONE_CALENDAR
          })
        )
      })
  }
}

export const GetListDetailTeamDroneCalendar = (id, param = "") => {
  return async (dispatch) => {
    dispatch(fieldOfficer.getDetailCalendarLoading(true))
    await requestGet(FIELD_OFFICER_URL.GET_CALENDAR_DETAIL_TEAM_DRONE_LIST + id + param)
      .then((res) => {
        dispatch(fieldOfficer.getCalendarList(res.data))
        dispatch(fieldOfficer.getDetailCalendarLoading(false))
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(fieldOfficer.getDetailCalendarLoading(false))
      })
  }
}

export const GetDetailPopCalendarTeamSchedule = (param = "") => {
  return async (dispatch) => {
    dispatch(fieldOfficer.getDetailCalendarLoading(true))
    await requestGet(FIELD_OFFICER_URL.GET_CALENDAR_DETAIL_TEAM_SCHEDULE + param)
      .then((res) => {
        dispatch(fieldOfficer.getCalendarList(res.data))
        dispatch(fieldOfficer.getDetailCalendarLoading(false))
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(fieldOfficer.getDetailCalendarLoading(false))
      })
  }
}

export const GetListFoHarvest = (param = "") => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_FO_HARVEST
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(FIELD_OFFICER_URL.GET_LIST_HARVEST + param)
      .then((res) => {
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_FO_HARVEST
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_HARVEST
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(
          general.setLoading({
            loading: true,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_HARVEST
          })
        )
      })
  }
}

export const GetListFoCoordinator = (param = "") => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_FO_COORDINATOR
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(FIELD_OFFICER_URL.GET_LIST_COORDINATOR + param)
      .then((res) => {
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_FO_COORDINATOR
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_COORDINATOR
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_COORDINATOR
          })
        )
      })
  }
}

export const GetListRegionalHead = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_REGIONAL_HEAD
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(FIELD_OFFICER_URL.GET_LIST_REGIONAL_HEAD() + param)
      .then((res) => {
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_REGIONAL_HEAD
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_REGIONAL_HEAD
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_REGIONAL_HEAD
          })
        )
      })
  }
}

export const GetListFoQAActive = (param = "") => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(FIELD_OFFICER_URL.GET_LIST_QUALITY_ASSURANCE_ACTIVE + param)
      .then((res) => {
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
          })
        )
      })
  }
}

export const GetListFoQAInactive = (param = "") => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(FIELD_OFFICER_URL.GET_LIST_QUALITY_ASSURANCE_INACTIVE + param)
      .then((res) => {
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FO_QUALITY_ASSURANCE
          })
        )
      })
  }
}

export const getListFieldManager = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_FIELD_MANAGER
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    requestGet(FIELD_OFFICER_URL.GET_LIST_FIELD_MANAGER() + param)
      .then((res) => {
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_FIELD_MANAGER
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FIELD_MANAGER
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_FIELD_MANAGER
          })
        )
      })
  }
}

export const getFieldManagerDetail = (id) => {
  return async (dispatch) => {
    if (id) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: FIELD_OFFICER.GET_DETAIL_FIELD_MANAGER
        })
      )
      try {
        setEmptyPageDataBeforeFetching(dispatch)
        const res = await mockFieldManagerDetail(id)
        dispatch(fieldOfficer.setFieldManagerDetail(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_DETAIL_FIELD_MANAGER
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export function getListHistoryTarget(regionalId, param) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_LIST_TARGET_HISTORY
      })
    )
    await requestGet(FIELD_OFFICER_URL.GET_LIST_TARGET_HISTORY(regionalId) + (param || "")).then(
      (res) => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: FIELD_OFFICER.GET_LIST_TARGET_HISTORY
          })
        )
        dispatch(
          fieldOfficer.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            },
            param: FIELD_OFFICER.GET_LIST_TARGET_HISTORY
          })
        ).catch((e) => {
          dispatch(
            general.setLoading({
              loading: false,
              actionInitType: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
            })
          )
          dispatch(general.setError(e))
        })
      }
    )
  }
}

export const getListTargetSubRegionalByRegionalIdAndYear = (regionalId, year) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
      })
    )
    try {
      setEmptyPageDataBeforeFetching(dispatch)
      const res = await requestGet(FIELD_OFFICER_URL.GET_TARGET_SUBREGIONAL_YEAR(year, regionalId))
      dispatch(fieldOfficer.setPageData({ pageData: res.data }))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
        })
      )
    } catch (e) {
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
        })
      )
      dispatch(general.setError(e))
    }
  }
}

export const getKpiGoalThisYearTargetRegional = (year, regionalId) => {
  return async (dispatch) => {
    try {
      setEmptyPageDataBeforeFetching(dispatch)
      const res = await requestGet(FIELD_OFFICER_URL.GET_TARGET_REGIONAL_YEAR(year, regionalId))
      dispatch(fieldOfficer.setKpiGoalThisYearTargetRegional("data" in res ? res.data : undefined))
    } catch (err) {
      dispatch(general.setError(err))
    }
  }
}

export const getKpiGoalThisYearTargetSubRegionalList = (year, regionalId, param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      const res = await requestGet(
        FIELD_OFFICER_URL.GET_TARGET_SUBREGIONAL_YEAR(year, regionalId) + param
      )
      dispatch(
        fieldOfficer.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          },
          param: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_KPI_GOAL_THIS_YEAR_TARGET_SUBREGIONAL_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getKpiGoalInitDataEditSubRegional = (year, regionalId) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: FIELD_OFFICER.GET_KPI_GOAL_INIT_DATA_EDIT_SUBREGIONAL_TARGET
      })
    )
    try {
      const res = await requestGet(
        FIELD_OFFICER_URL.GET_TARGET_SUBREGIONAL_YEAR_DETAIL(year, regionalId)
      )
      dispatch(fieldOfficer.setkpiGoalInitDataEditSubRegionalTarget(res))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: FIELD_OFFICER.GET_KPI_GOAL_INIT_DATA_EDIT_SUBREGIONAL_TARGET
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}