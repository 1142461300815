import { t } from "i18next"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useLocation } from "react-router-dom"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { initialFilterTable } from "../../../../../component/atoms/Tabel/index.jsx"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { KPI_GOAL_SETTING_TAB } from "../../../../../constants/StringConstant.js"
import { getKpiGoalThisYearTargetRegional, getListTargetSubRegionalByRegionalIdAndYear } from "../../../../../redux/actions/FieldOfficerAction.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"

export function useDetailKpiGoalSettingPage() {
  const { General, FieldOfficer } = useSelector((state) => state)
  const { year } = useParams()
  const subHeader = useSubheader()
  const location = useLocation()
  const dispatch = useDispatch()
  const user = loadUserLogin()
  const regionalId = user?.regional_id

  const [currentParam] = useState({
    ...initialFilterTable
  })

  useEffect(() => {
    if (regionalId && year) {
      dispatch(getKpiGoalThisYearTargetRegional(year, regionalId))
      fetchData()
    }
  }, [])

  function fetchData() {
    dispatch(getListTargetSubRegionalByRegionalIdAndYear(regionalId, year))
  }

  useEffect(() => {
    subHeader.setButton(null)
    const dataBreadcrumb = [
      {
        pathname: RoutesConstants.FIELD_OFFICER.ASSIGN_OPS_ADMIN(),
        title: t("label.ops_team")
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.THIS_YEAR),
        title: t("label.kpi_goal_setting")
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.HISTORY),
        title: t("label.history_kpi_goal_setting")
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.HISTORY),
        title: t("label.history_kpi_goal_setting")
      },
      {
        title: t("label.detail_history_kpi_goal_setting", { year: year })
      }
    ]
    subHeader.setBreadcrumbs(dataBreadcrumb)
  }, [])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...currentParam,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...currentParam,
          ...param,
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, currentParam]
  )

  return { year, General, FieldOfficer, currentParam, changePage }
}
