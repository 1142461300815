import { GeneralSlice } from "../reducers/General"
import { MasterDataSlice } from "../reducers/MasterData"
import { GENERAL, MASTER, MASTER_DATA } from "../../constants/InitTypeConstants"
import { requestDelete, requestGet, requestPatch, requestPost, requestPut } from "../../config"
import { AREA_URL, MASTER_DATA_URL, MASTER_URL, SUBMISSION_URL } from "../../constants/UrlConstants"
import { toAbsoluteUrl } from "../../_metronic/_helpers"
import {
  getUrlParsingCategoryItem,
  getUrlParsingPageFilter,
  getUrlParsingStatus
} from "../../config/EndpointCollection.js"

import { initialFilterTable } from "../../component/atoms/Tabel"
import swal from "sweetalert"

const { actions: general } = GeneralSlice
const { actions: masterData } = MasterDataSlice

export const uploadAction = ({ data, type, isEdit = false }) => {
  const newFormData = new FormData()
  if (isEdit) {
    newFormData.append("file", data, "photo.png")
  } else {
    newFormData.append("file", data)
  }

  newFormData.append("category", type)

  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: GENERAL.UPLOAD }))
    dispatch(
      masterData.uploadData({
        url: toAbsoluteUrl("/media/gif/loader.gif"),
        uploadType: type
      })
    )

    try {
      if (type === "FILE_EXCEL") {
        dispatch(masterData.uploadData({ url: data, uploadType: type }))
      } else {
        let res = await requestPost(MASTER_URL.UPLOAD_PHOTO, newFormData)
        dispatch(masterData.uploadData({ url: res.data, uploadType: type }))
      }
      dispatch(general.setLoading({ loading: false, actionInitType: GENERAL.UPLOAD }))
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: GENERAL.UPLOAD
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const uploadImageListAction = ({ data, type }) => {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: GENERAL.UPLOAD }))
    dispatch(
      masterData.uploadData({
        url: toAbsoluteUrl("/media/gif/loader.gif"),
        uploadType: type
      })
    )

    try {
      let dataList = []
      for (let i = 0; i < data.length; i++) {
        const newFormData = new FormData()
        newFormData.append("file", data[i], data[i].name)
        newFormData.append("category", type)
        let res = await requestPost(MASTER_URL.UPLOAD_PHOTO, newFormData)
        dataList.push(res.data)
      }
      dispatch(masterData.uploadData({ url: dataList, uploadType: type }))
      dispatch(general.setLoading({ loading: false, actionInitType: GENERAL.UPLOAD }))
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: GENERAL.UPLOAD
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearUploadAction = (type = null) => {
  return async (dispatch) => {
    dispatch(
      masterData.uploadData({
        url: "",
        uploadType: type
      })
    )
  }
}

export const getListActivityTypeSeeding = () => {
  return async (dispatch) => {
    await requestGet(MASTER_URL.LIST_ACTIVITY_TYPE_SEEDING())
      .then((res) => {
        dispatch(masterData.setActivityTypeSeeding(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const fetchProvince = (regionalId) => {
  return async (dispatch) => {
    try {
      let res = await requestGet(
        regionalId
          ? MASTER_URL?.LIST_PROVINCE_FILTER_BY_REGIONAL(regionalId)
          : MASTER_URL.GET_PROVINCE
      )
      dispatch(masterData.setProvinceList(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchProvinceByRegional = (regional) => {
  return async (dispatch) => {
    try {
      let param = getUrlParsingPageFilter({
        regional_id: regional
      })
      let res = await requestGet(MASTER_URL.GET_PROVINCE_BY_REGIONAL + param)
      dispatch(masterData.setProvinceList(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearFetchProvince = () => {
  return async (dispatch) => {
    dispatch(masterData.setProvinceList(false))
  }
}

export const fetchRegencyByProvinceId = (id) => {
  return async (dispatch) => {
    try {
      let res = await requestPost(MASTER_URL.GET_REGENCY, {
        province_id: id
      })
      dispatch(masterData.setRegencyList(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearFetchRegencyDistrictSubDistrik = () => {
  return async (dispatch) => {
    dispatch(masterData.setRegencyList([]))
    dispatch(masterData.setDistrictList([]))
    dispatch(masterData.setSubDistrictList([]))
  }
}

export const fetchDistrictByRegencyId = (id) => {
  return async (dispatch) => {
    try {
      let res = await requestPost(MASTER_URL.GET_DISTRICT, {
        regency_id: id
      })
      dispatch(masterData.setDistrictList(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const fetchSubDistrictByDistrictId = (id) => {
  return async (dispatch) => {
    try {
      let res = await requestGet(MASTER_URL.GET_SUB_DISTRICT + id)
      dispatch(masterData.setSubDistrictList(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchBanks = () => {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: MASTER.GET_BANKS }))
    try {
      let res = await requestGet(MASTER_URL.GET_BANKS)
      dispatch(masterData.setBankList(res.data))
      dispatch(general.setLoading({ loading: false, actionInitType: MASTER.GET_BANKS }))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchStatusAccounts = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_STATUS_ACCOUNTS
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_STATUS_ACCOUNTS)
      dispatch(masterData.setStatusAccountList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_STATUS_ACCOUNTS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const resetZones = () => {
  return (dispatch) => {
    dispatch(masterData.resetZones())
  }
}

export const fetchPlants = () => {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: MASTER.GET_PLANTS }))
    try {
      let res = await requestGet(MASTER_URL.GET_PLANTS)
      dispatch(masterData.setPlantList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_PLANTS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchVarietyPlant = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_VARIETY_PLANTS
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_VARIETY_PLANTS + id + "/plant-varieties")
      dispatch(masterData.setVarietyList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_VARIETY_PLANTS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchCategoryVideos = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_VIDEO_CATEGORIES
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_VIDEO_CATEGORIES)
      dispatch(masterData.setCategoryVideoList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_VIDEO_CATEGORIES
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearPlant = () => {
  return async (dispatch) => {
    dispatch(masterData.setPlantList([]))
  }
}

export const clearVariety = () => {
  return async (dispatch) => {
    dispatch(masterData.setVarietyList([]))
  }
}

export const getPlantType = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_PLANT_TYPE
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_PLANT_TYPE)
      dispatch(masterData.setPlantType(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MASTER.GET_PLANT_TYPE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getActivityTypeList = () => {
  return async (dispatch) => {
    try {
      let res = await requestGet(MASTER_URL.GET_ACTIVITY_TYPE_LIST)
      dispatch(masterData.setActivityTypeList(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getMachineType = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_MACHINE_TYPE
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_MACHINE_TYPE)
      dispatch(masterData.setMachineType(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_MACHINE_TYPE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPlantForm = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_PLANT_FORM
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_PLANT_FORM)
      dispatch(masterData.setPlantForm(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_PLANT_FORM
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getCategoryItem = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_CATEGORY_ITEM
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_CATEGORY_ITEM)
      dispatch(masterData.setCategoryList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_CATEGORY_ITEM
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getCategoryItemList = (id, param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingCategoryItem(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_CATEGORY_ITEM
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_CATEGORY_ITEM_LIST + id + urlData)
      dispatch(
        masterData.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_CATEGORY_ITEM
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getCategoryItemListMachine = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingCategoryItem(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_CATEGORY_ITEM
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_CATEGORY_ITEM_LIST_MACHINE + urlData)
      dispatch(
        masterData.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_CATEGORY_ITEM
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createCategoryItem = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.CREATE_CATEGORY_ITEM
        })
      )
      try {
        await requestPost(MASTER_DATA_URL.CREATE_CATEGORY_ITEM, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.CREATE_CATEGORY_ITEM
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.CREATE_CATEGORY_ITEM
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const createTreatment = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.CREATE_TREATMENT
        })
      )
      try {
        await requestPost(MASTER_DATA_URL.CREATE_TREATMENT, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.CREATE_TREATMENT
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.CREATE_TREATMENT
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getOwnerList = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_OWNER_LIST
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_OWNER_LIST)
      dispatch(masterData.setOwnerList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_OWNER_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getMasterTreatmentList = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_LIST_PAGE
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_TREATMENT + urlData)
      dispatch(
        masterData.setPageData({
          pageData: res?.data,
          page: {
            totalCount: res?.all_element,
            currentElement: res?.element,
            currentPage: res?.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(
        masterData.setPageData({
          pageData: [],
          page: {}
        })
      )
      dispatch(general.setError(e))
    }
  }
}

export const getPackageList = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_LIST_PAGE
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_PACKAGE + (param || ""))
      dispatch(
        masterData.setPageData({
          pageData: res?.data,
          page: {
            totalCount: res?.all_element,
            currentElement: res?.element,
            currentPage: res?.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(
        masterData.setPageData({
          pageData: [],
          page: {}
        })
      )
      dispatch(general.setError(e))
    }
  }
}

export const getDetailTreatment = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_DETAIL_TREATMENT
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_TREATMENT_DETAIL + id)
      dispatch(masterData.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_DETAIL_TREATMENT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getVariantAll = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_VARIANT_BY_PLANT_TYPE
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_VARIANT_BY_PLANT_TYPE)
      dispatch(masterData.setVariantList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MASTER.GET_VARIANT_BY_PLANT_TYPE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createTreatmentInsidePackage = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.CREATE_TREATMENT
        })
      )
      try {
        let res = await requestPost(MASTER_DATA_URL.CREATE_TREATMENT, data)
        dispatch(masterData.setMessages(res?.data))

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.CREATE_TREATMENT
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.CREATE_TREATMENT
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const editTreatmentInsidePackage = (id, data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.UPDATE_TREATMENT
        })
      )
      try {
        let res = await requestPut(MASTER_DATA_URL.UPDATE_TREATMENT + id, null, data)
        dispatch(masterData.setMessages(res?.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.UPDATE_TREATMENT
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.UPDATE_TREATMENT
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updateTreatment = (data, id) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.UPDATE_TREATMENT
        })
      )
      try {
        await requestPut(MASTER_DATA_URL.UPDATE_TREATMENT + id, null, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.UPDATE_TREATMENT
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.UPDATE_TREATMENT
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getCategoryReason = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER_DATA_URL.GET_CATEGORY_REASON
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_CATEGORY_REASON)
      dispatch(masterData.setCategoryReason(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER_DATA.GET_CATEGORY_REASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailReason = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER_DATA_URL.GET_DETAIL_REASON
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_DETAIL_REASON)
      dispatch(masterData.setDetailReason(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER_DATA_URL.GET_DETAIL_REASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createReason = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA_URL.CREATE_REASON
        })
      )
      try {
        await requestPost(MASTER_DATA_URL.CREATE_REASON, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA_URL.CREATE_REASON
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA_URL.CREATE_REASON
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const deleteReason = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.DELETE_REASON
      })
    )

    try {
      await requestDelete(MASTER_DATA_URL.DELETE_REASON + id)
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: MASTER_DATA.DELETE_REASON
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MASTER_DATA.DELETE_REASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const updateReason = (data, id) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.UPDATE_REASON
        })
      )
    }
    try {
      await requestPut(MASTER_DATA_URL.UPDATE_REASON + id, null, data)
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: MASTER_DATA.UPDATE_REASON
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MASTER_DATA.UPDATE_REASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailPackage = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_DETAIL_PACKAGE
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_PACKAGE_DETAIL + id)
      dispatch(masterData.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_DETAIL_PACKAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailTreatmentPackage = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_TREATMENT_DETAIL_PACKAGE
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_TREATMENT_PACKAGE_DETAIL + id)
      dispatch(masterData.setTreatmentDetail(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_TREATMENT_DETAIL_PACKAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createPackage = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.CREATE_PACKAGE
        })
      )
      try {
        const res = await requestPost(MASTER_DATA_URL.CREATE_PACKAGE, data)
        dispatch(masterData.setMessages(res.data))

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.CREATE_PACKAGE
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.CREATE_PACKAGE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updatePackage = (data, id) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MASTER_DATA.UPDATE_PACKAGE
        })
      )
      try {
        const res = await requestPut(MASTER_DATA_URL.UPDATE_PACKAGE + id, null, data)
        dispatch(masterData.setMessages(res.data))

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.UPDATE_PACKAGE
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.UPDATE_PACKAGE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updateStatusPackage = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.UPDATE_STATUS_PACKAGE
      })
    )
    try {
      await requestPatch(MASTER_URL.UPDATE_STATUS_PACKAGE(id))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: MASTER_DATA.UPDATE_STATUS_PACKAGE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MASTER_DATA.UPDATE_STATUS_PACKAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getTreatmentByPlantType = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_TREATMENT_BY_PLANT_TYPE
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_TREATMENT_BY_PLANT_TYPE + id)
      dispatch(masterData.setTreatmentList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_TREATMENT_BY_PLANT_TYPE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getVariantByArea = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_LIST_VARIANT_BY_AREA
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_LIST_VARIANT_BY_AREA + id)
      dispatch(masterData.setVariantList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_LIST_VARIANT_BY_AREA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getActivityStatusEnumFilter = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_STATUS_ENUM_ACTIVITY_STATUS
      })
    )
    await requestGet(MASTER_DATA_URL.GET_STATUS_ENUM_ACTIVITY_STATUS)
      .then((res) => {
        const { data } = res
        dispatch(masterData.setStatusActivity(data))

        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER.GET_STATUS_ENUM_ACTIVITY_STATUS
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getSubDistrictForFilterOrder = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingStatus(param)
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_SUB_DISTRICT
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_SUB_DISTRICT_FOR_ORDER + urlData)
      dispatch(masterData.setSubDistrictList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MASTER.GET_SUB_DISTRICT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getSubDistrictForFilterOrderPascaPlanting = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_SUB_DISTRICT
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_SUB_DISTRICT_FOR_ORDER_PASCA_PLANTING)
      dispatch(masterData.setSubDistrictList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MASTER.GET_SUB_DISTRICT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearFinishUpdateOrDelete = () => {
  return async (dispatch) => {
    try {
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: false,
          actionInitType: MASTER_DATA.CREATE_TREATMENT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearMessageMasterData = () => {
  return async (dispatch) => {
    try {
      dispatch(masterData.setMessages(null))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getReasonFailedHarvest = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_REASON_FAILED_HARVEST
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_REASON_FAILED_HARVEST)
      dispatch(masterData.setReasonList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_REASON_FAILED_HARVEST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPestTypeList = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MASTER.GET_PEST_TYPE_LIST
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_PEST_TYPE_LIST)
      dispatch(masterData.setPestTypeList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_PEST_TYPE_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export function syncErpPesticide() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.SYNC_ERP_PESTICIDE
      })
    )
    try {
      await requestPatch(MASTER_URL.SYNC_PESTICIDE).then(() => {
        dispatch(getCategoryItemList("PESTICIDE", { pageReq: initialFilterTable }))
      })
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: MASTER_DATA.SYNC_ERP_PESTICIDE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MASTER_DATA.SYNC_ERP_PESTICIDE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const resetUrlResponse = () => {
  return (dispatch) => {
    dispatch(masterData.resetUrlResponse())
  }
}

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    masterData.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export function addDataRegional(data) {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: MASTER.ADD_REGIONAL_DATA }))
    await requestPost(MASTER_URL.SUBMIT_CREATE_REGIONAL(), data)
      .then((res) => {
        dispatch(masterData.setMessages(res.data))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER.ADD_REGIONAL_DATA
          })
        )
        dispatch(
          general.setLoadingGlobal({ loading: false, actionInitType: MASTER.ADD_REGIONAL_DATA })
        )
      })
      .catch((e) => {
        swal(e?.message || "Terjadi Kesalahan Pada Sistem", {
          icon: "error"
        }).then()
        dispatch(
          general.setLoadingGlobal({ loading: false, actionInitType: MASTER.ADD_REGIONAL_DATA })
        )
      })
  }
}
export function EdiDataRegional(data, id) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({ loading: true, actionInitType: MASTER.EDIT_REGIONAL_MASTER_DATA })
    )
    await requestPut(MASTER_URL.EDIT_REGIONAL_MASTER_DATA(id), undefined, data)
      .then((res) => {
        dispatch(masterData.setMessages(res.data))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER.EDIT_REGIONAL_MASTER_DATA
          })
        )
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER.EDIT_REGIONAL_MASTER_DATA
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function submitEditSubRegional(data, subRegionalId) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({ loading: true, actionInitType: MASTER.CREATE_SUB_REGIONAL })
    )
    await requestPut(MASTER_URL.EDIT_SUB_REGIONAL(subRegionalId), undefined, {
      sub_regional_id: subRegionalId,
      ...data
    })
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER.EDIT_REGIONAL_MASTER_DATA
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER.CREATE_SUB_REGIONAL
          })
        )
        dispatch(
          general.setLoadingGlobal({ loading: false, actionInitType: MASTER.CREATE_SUB_REGIONAL })
        )
      })
      .catch((e) => {
        swal(e?.message || "Terjadi Kesalahan Pada Sistem", {
          icon: "error"
        }).then()
        dispatch(
          general.setLoadingGlobal({ loading: false, actionInitType: MASTER.CREATE_SUB_REGIONAL })
        )
      })
  }
}

export function submitCreateSubRegional(data) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({ loading: true, actionInitType: MASTER.CREATE_SUB_REGIONAL })
    )
    await requestPost(MASTER_URL.CREATE_SUB_REGIONAL(), data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: true,
            actionInitType: MASTER.EDIT_REGIONAL_MASTER_DATA
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER.CREATE_SUB_REGIONAL
          })
        )
        dispatch(
          general.setLoadingGlobal({ loading: false, actionInitType: MASTER.CREATE_SUB_REGIONAL })
        )
      })
      .catch((e) => {
        swal(e?.message || "Terjadi Kesalahan Pada Sistem", {
          icon: "error"
        }).then()
        dispatch(
          general.setLoadingGlobal({ loading: false, actionInitType: MASTER.CREATE_SUB_REGIONAL })
        )
      })
  }
}

export function getListAvailableRegencyRegional(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({ loading: true, actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL })
    )
    await requestGet(MASTER_URL.GET_LIST_AVAILABLE_REGENCY_SUB_REGIONAL(id))
      .then((res) => {
        dispatch(masterData.setListAvailableRegency(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export function getListAvailableRegencyRegionalEdit(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({ loading: true, actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL })
    )
    await requestGet(MASTER_URL.GET_LIST_AVAILABLE_REGENCY_SUB_REGIONAL_EDIT(id))
      .then((res) => {
        dispatch(masterData.setListAvailableRegency(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListProvinceForCreateRegional() {
  return async (dispatch) => {
    dispatch(
      general.setLoading({ loading: true, actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL })
    )
    await requestGet(MASTER_URL.GET_LIST_AVAILABLE_PROVINCE_FOR_CREATE_REGIONAL())
      .then((res) => {
        dispatch(masterData.setListAvailableProvince(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListProvinceEditRegional(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({ loading: true, actionInitType: MASTER.GET_LIST_PROVINCE_EDIT_REGIONAL })
    )
    await requestGet(MASTER_URL.GET_LIST_PROVINCE_EDIT_REGIONAL(id))
      .then((res) => {
        dispatch(masterData.setListAvailableProvince(res.data))

        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_AVAILABLE_PROVINCE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export function getListAvailableProvince(param) {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: MASTER.GET_AVAILABLE_PROVINCE }))
    await requestGet(MASTER_URL.GET_LIST_AVAILABLE_PROVINCE() + (param ? param : ""))
      .then((res) => {
        dispatch(
          masterData.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_AVAILABLE_PROVINCE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListRegionalMasterData(param) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({ loading: true, actionInitType: MASTER.GET_LIST_REGIONAL_MASTER_DATA })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(MASTER_URL.GET_LIST_REGIONAL_MASTER_DATA() + (param ? param : ""))
      .then((res) => {
        dispatch(
          masterData.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_LIST_REGIONAL_MASTER_DATA
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export function getListPlantTypePageable(param) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({ loading: true, actionInitType: MASTER.GET_LIST_PLANT_TYPE_PAGEABLE })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(MASTER_URL.GET_LIST_PLANT_TYPE_PAGEABLE() + param)
      .then((res) => {
        dispatch(
          masterData.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )

        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MASTER.GET_LIST_PLANT_TYPE_PAGEABLE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getUserMerchantListFilter() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_USER_MERCHANT_FILTER
      })
    )
    await requestGet(MASTER_URL.GET_USER_MERCHANT_FILTER)
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_USER_MERCHANT_FILTER
          })
        )
        dispatch(masterData.setUserMerchantList(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getPlantTypeUser() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_PLANT_TYPE_USER
      })
    )
    await requestGet(MASTER_URL.GET_PLANT_TYPE_USER())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_PLANT_TYPE_USER
          })
        )
        dispatch(masterData.setPlantTypeUser(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getLegendMap = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_LEGEND_MAP
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_LEGEND_MAP)
      dispatch(masterData.setLegendMap(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MASTER.GET_LEGEND_MAP
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export function createNewPlantType(data) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.CREATE_PLANT_TYPE
      })
    )
    await requestPost(MASTER_URL.CREATE_PLANT_TYPE(), data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.CREATE_PLANT_TYPE
          })
        )

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.CREATE_PLANT_TYPE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function setSelectedRegionalEdit(data) {
  return (dispatch) => {
    dispatch(masterData.setSelectedRegionalEdit(data))
  }
}
export function editPlantType(data, id) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.EDIT_PLANT_TYPE
      })
    )
    await requestPut(MASTER_URL.EDIT_PLANT_TYPE(id), "", data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.EDIT_PLANT_TYPE
          })
        )

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.EDIT_PLANT_TYPE
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListRegionalSelected() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({ loading: true, actionInitType: MASTER_DATA.EDIT_PLANT_TYPE })
    )
    await requestGet(MASTER_URL.GET_LIST_REGIONAL_SELECTED())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.EDIT_PLANT_TYPE
          })
        )
        dispatch(masterData.setListRegionalSelected(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function deleteSubRegionalActions(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.DELETE_SUB_REGIONAL
      })
    )
    await requestDelete(MASTER_URL.DELETE_SUB_REGIONAL(id), "")
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MASTER_DATA.DELETE_SUB_REGIONAL
          })
        )

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.DELETE_SUB_REGIONAL
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getDetailRegional(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_DETAIL_REGIONAL
      })
    )
    await requestGet(MASTER_URL.GET_DETAIL_REGIONAL(id))
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_DETAIL_REGIONAL
          })
        )
        dispatch(masterData.setDataDetailRegional(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function clearDetailRegional() {
  return async (dispatch) => {
    dispatch(masterData.setDataDetailRegional(null))
  }
}

export function getDetailSubRegional(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: MASTER_DATA.GET_DETAIL_REGIONAL
      })
    )
    await requestGet(MASTER_URL.GET_DETAIL_SUB_REGIONAL(id))
      .then((res) => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MASTER_DATA.GET_DETAIL_REGIONAL
          })
        )
        dispatch(masterData.setDetailSubRegional(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListRegionalAll() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_REGIONAL_ALL
      })
    )
    await requestGet(MASTER_URL.GET_LIST_REGIONAL_ALL())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_REGIONAL_ALL
          })
        )
        dispatch(masterData.setListRegionalAll(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListRegionalByUserLogin() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_REGIONAL_BY_USER_LOGIN
      })
    )
    await requestGet(MASTER_URL.GET_LIST_REGIONAL_BY_USER_LOGIN())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_REGIONAL_BY_USER_LOGIN
          })
        )
        dispatch(masterData.setListRegionalAll(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListRegionalHeadByUserLogin() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_RH_BY_USER_LOGIN
      })
    )
    await requestGet(MASTER_URL.GET_LIST_RH_BY_USER_LOGIN())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_RH_BY_USER_LOGIN
          })
        )
        dispatch(masterData.setListRegionalHeadByUserLogin(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListSubRegionalByRh(rhId) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_SUB_REGIONAL_BY_RH
      })
    )
    await requestGet(MASTER_URL.GET_LIST_SUB_REGIONAL_BY_RH(rhId))
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_SUB_REGIONAL_BY_RH
          })
        )
        dispatch(masterData.setListSubRegionalByRh(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListSubRegionalByFm(fmId) {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_SUB_REGIONAL_BY_FM
      })
    )
    await requestGet(MASTER_URL.GET_LIST_SUB_REGIONAL_BY_FM(fmId))
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_SUB_REGIONAL_BY_FM
          })
        )
        dispatch(masterData.setListSubRegionalByFm(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListFieldManagerByUserLogin() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_FIELD_MANAGER_BY_USER_LOGIN
      })
    )
    await requestGet(MASTER_URL.GET_LIST_FIELD_MANAGER_BY_USER_LOGIN())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_FIELD_MANAGER_BY_USER_LOGIN
          })
        )
        dispatch(masterData.setListFieldManagerByUserLogin(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListFieldSupervisorByUserLogin() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER_DATA.GET_LIST_FIELD_SUPERVISOR_BY_USER_LOGIN
      })
    )
    await requestGet(MASTER_URL.GET_LIST_FIELD_SUPERVISOR_BY_USER_LOGIN())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MASTER_DATA.GET_LIST_FIELD_SUPERVISOR_BY_USER_LOGIN
          })
        )
        dispatch(masterData.setListFieldSupervisorByUserLogin(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}


export const getVarietyFilterBlock = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MASTER.GET_VARIETY_PLANTS
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_VARIETAS())
      dispatch(masterData.setVarietyList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MASTER.GET_VARIETY_PLANTS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
