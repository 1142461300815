import React, { Fragment } from "react"
import { Container } from "@material-ui/core"
import { Button, Card, Col, Row } from "react-bootstrap"
import { CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls/index.js"
import { t } from "i18next"
import Divider from "@material-ui/core/Divider"
import { FileList, NoteView, TextInput, UploadComponent } from "../../../../component/index.jsx"
import { FormikProvider } from "formik"
import { checkValue } from "../../../helpers/TextHelper.js"
import { MenuBack } from "../../../../component/atoms/MenuBack/index.jsx"
import { Stepper } from "../../../../component/atoms/Stepper/index.jsx"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"
import { useEditTargetSubRegionalPage } from "./useEditTargetSubRegionalPage.js"
import clsx from "clsx"
import { UploadIcon } from "../../../../component/atoms/Icons/SVG/upload.jsx"
import { VARIABLE_CONSTANT } from "../../../../constants/VariableConstant.js"
import { Skeleton } from "@material-ui/lab"
import { FIELD_OFFICER_URL } from "../../../../constants/UrlConstants.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"

function EditTargetSubRegionalPage({ history }) {
  const page = useEditTargetSubRegionalPage()

  function header() {
    return (
      <div
        className={
          "bg-white d-flex justify-content-between align-content-center p-6 border-1 border-bottom border-bottom-light-dark"
        }>
        <MenuBack
          title={t("sub_header.edit_target_yearly")}
          onClick={() => {
            page.uiService.setExitFullPage()
            history.goBack()
          }}
        />
        <Stepper
          step={page.labelStep}
          activeStep={page.activeStep}
          setActiveStep={page.setActiveStep}
        />
      </div>
    )
  }

  return (
    <div>
      <div className={"mb-8"}>{header()}</div>
      <Container>
        <FormikProvider value={page.formik}>
          <Card>
            <CardBody
              className={"bg-gray-card border-gray border-gray-1-without-bottom card-rounded-top"}>
              <CardHeaderTitle>
                <span className={"font-weight-bolder"}>{`1. ${t("label.target_regional")
                  .toString()
                  .toUpperCase()}`}</span>
                <img className={"pl-4"} src={ICON_CONSTANS.IC_CHECKLIST} alt={"checklist"} />
              </CardHeaderTitle>
            </CardBody>
            <CardBody className={"border-gray rounded-card-bottom"}>
              <div style={{ display: "grid", gap: 16 }}>
                <div>
                  <div className="row  ">
                    {page.target ? (
                      page.target.map((item, i) => (
                        <div
                          key={i}
                          className={`col  ${i !== page.target.length - 1 ? "border-right" : ""}`}>
                          <div className="text-muted">{checkValue(item?.plant_type_name)}</div>
                          <div className="font-weight-bold" style={{ fontSize: "16px" }}>
                            {checkValue(item?.value, "Ha")}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Skeleton animation="wave" variant={"rect"} height={"50"} />
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <NoteView
            title={t("label.download_example_document_for_edit_target_title")}
            variant={"warning"}
            className={"mt-8 text-dark"}
            icon={ICON_CONSTANS.IC_NOTE_VIEW_WARNING_D9913D}
            titleClassName={"text-dark"}
            customVariantClassName={"background-warning-dark"}
            endContent={
              <a
                target={"_blank"}
                href={FIELD_OFFICER_URL.REFERENCE_LETTER_EDIT_SUBMISSION_PDF()}
                className="link-warning text-warning-D9913D underline font-weight-bolder link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                rel="noreferrer"
                download>
                <u>{t("label.document_example")}</u>
              </a>
            }
          />
          <Card className={"mt-8"}>
            <CardBody
              className={"bg-gray-card border-gray border-gray-1-without-bottom card-rounded-top"}>
              <CardHeaderTitle>
                <span className={"font-weight-bolder"}>{`2. ${t("label.target_sub_regional")
                  .toString()
                  .toUpperCase()}`}</span>
                {page.activeStep === 1 && (
                  <img className={"pl-4"} src={ICON_CONSTANS.IC_CHECKLIST} alt={"checklist"} />
                )}
              </CardHeaderTitle>
            </CardBody>
            <CardBody className={"border-gray rounded-card-bottom"}>
              {page.activeStep === 0 && (
                <NoteView title={t("label.setting_target_note_view_create_edit")} />
              )}
              <RowModule>
                <div>
                  <h5 className="font-weight-bold" style={{ fontSize: "16px" }}>
                    {t("label.total_sub_regional_target")}
                  </h5>
                  <p className="color-neutral">{t("label.total_sub_regional_target_warning")}</p>
                </div>
                <RowModule>
                  {page.totalTargetSubregional &&
                    Object.entries(page.totalTargetSubregional).map((totalTargetItem) => (
                      <div className="border-left pl-5" key={totalTargetItem[0]}>
                        <div className="text-muted">
                          {checkValue(totalTargetItem[1].plant_type_name)}
                        </div>
                        <div className="font-weight-bolder txt-20">
                          {checkValue(totalTargetItem[1].value, "Ha")}
                        </div>
                      </div>
                    ))}
                </RowModule>
              </RowModule>
              <Divider />
              <div>
                {Array.isArray(page.formik.values.sub_regional_target) &&
                  page.formik.values.sub_regional_target.map((srItem, srIndex) => (
                    <div key={srItem.sub_regional_id} className={"mt-8"}>
                      <RowModule>
                        <div className={"d-flex flex-column"}>
                          <span className={"font-weight-bolder txt-16"}>
                            {srItem.sub_regional_name}
                          </span>
                          <span>{srItem.regency_list.join(", ")}</span>
                        </div>
                        <RowModule>
                          {page.activeStep === 1 &&
                            Array.isArray(srItem.target) &&
                            srItem.target.map((targetItem) => (
                              <div
                                key={targetItem.plant_type_id}
                                className={clsx(
                                  "separator-left-solid",
                                  "separator-border-2",
                                  "mr-4",
                                  "pl-4",
                                  "pr-4"
                                )}>
                                <span className="text-dark-50">
                                  {checkValue(targetItem.plant_type_name)}
                                </span>
                                <h4>{checkValue(targetItem.value, "ha")}</h4>
                              </div>
                            ))}
                        </RowModule>
                      </RowModule>
                      {page.activeStep === 0 && (
                        <Row>
                          {Array.isArray(srItem.target) ? (
                            srItem.target.map((targetItem, targetIndex) => {
                              return (
                                <Col key={targetItem.plant_type_id}>
                                  <TextInput
                                    required
                                    editable={true}
                                    label={targetItem.plant_type_name}
                                    errorMessage={
                                      Array.isArray(page?.formik?.errors.sub_regional_target) &&
                                      page?.formik?.errors.sub_regional_target[srIndex] &&
                                      page?.formik?.errors.sub_regional_target[srIndex].target[
                                        targetIndex
                                      ] &&
                                      page?.formik?.errors.sub_regional_target[srIndex].target[
                                        targetIndex
                                      ].value
                                    }
                                    name={`sub_regional_target[${srIndex}].target[${targetIndex}].value`}
                                    placeholder={t("placeholder.insert_target")}
                                    value={targetItem.value}
                                    endIcon={"Ha"}
                                  />
                                </Col>
                              )
                            })
                          ) : (
                            <Skeleton animation="wave" variant={"rect"} height={"50"} />
                          )}
                        </Row>
                      )}
                      {srIndex !== page.formik.values.sub_regional_target.length - 1 && (
                        <Divider className={"mt-8"} />
                      )}
                    </div>
                  ))}
              </div>
              {page.activeStep === 0 && (
                <div className={"mt-8 position-relative"}>
                  <UploadComponent
                    urlParsing={page.urlDocument}
                    label={t("label.upload_letter_of_change_submission")}
                    name="document_url"
                    required={true}
                    editable={true}
                    onChange={page.onChangeUpload}
                    onDeleteFileList={() => {
                      page.resetUpload()
                    }}
                    isError={page.fileAttachments.length === 0}
                    fileList={page.fileAttachments}
                    typeUpload={"pdf"}
                    placeholder={<UploadIcon />}
                    maxSize={VARIABLE_CONSTANT.MAX_FILE_UPLOAD_PDF}
                    maxSizeText={VARIABLE_CONSTANT.MAX_SIZE_PDF}
                    isLoading={page?.General?.loading}
                  />
                </div>
              )}
              {page.activeStep === 0 && (
                <>
                  <div className={"separator separator-solid pt-8"} />
                  <div style={{ gap: 8 }} className="d-flex justify-content-end mt-8">
                    <button
                      onClick={() => page.onResetSubregionalTarget()}
                      className="btn btn-outline-maxxi">
                      {t("button.reset")}
                    </button>
                    <Button
                      onClick={() => page.handleStep()}
                      disabled={
                        !page.formik?.isValid ||
                        page?.General?.loading ||
                        page.fileAttachments.length === 0
                      }
                      className={"text-capitalize"}>
                      {t("button.save")}
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
          {page.activeStep === 1 && (
            <>
              <h4 className={"mt-8 text-uppercase"}>
                {t("label.regional_change_reason_letter").toUpperCase()}
              </h4>
              {page.fileAttachments && (
                <div className={"mt-10 d-flex flex-column"} style={{ gap: 15 }}>
                  {page.fileAttachments.map((item, i) => (
                    <Fragment key={i}>
                      <FileList name={item?.name} size={item?.size} key={i} type={item?.type} />
                    </Fragment>
                  ))}
                </div>
              )}
              <Button className={"w-full mt-8"} onClick={() => page.formik.handleSubmit()}>
                {t("button.submit_button")}
              </Button>
            </>
          )}
        </FormikProvider>
      </Container>
    </div>
  )
}

export default EditTargetSubRegionalPage
