import React, { useEffect, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { ButtonDropdown, TabLog, TabsComponent } from "../../../../component/index.jsx"
import { KPIGoalThisYear } from "./tab/KPIGoalThisYear.jsx"
import { useDispatch } from "react-redux"
import { setDataTabMultiple } from "../../../../redux/actions/FilterAction.js"
import { useParams } from "react-router"
import { KPI_GOAL_SETTING_TAB } from "../../../../constants/StringConstant.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { ENUM_LOG } from "../../../../constants/InitTypeConstants.js"
import { KpiGoalSettingHistory } from "./tab/KpiGoalHistoryPage.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"

export function KPIGoalSetting({ history }) {
  const subheader = useSubheader()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { tabs } = useParams()
  const [tabActive, setTabActive] = useState(
    tabs === KPI_GOAL_SETTING_TAB.THIS_YEAR
      ? 0
      : tabs === KPI_GOAL_SETTING_TAB.HISTORY
      ? 1
      : tabs === KPI_GOAL_SETTING_TAB.LOG
      ? 2
      : 0
  )
  const user = loadUserLogin()

  const renderComponent = () => {
    const steps = [
      t("label.this_year_target"),
      t("label.history").toUpperCase(),
      t("label.log").toUpperCase()
    ]
    const flows = [
      <KPIGoalThisYear key={KPI_GOAL_SETTING_TAB.THIS_YEAR} />,
      <KpiGoalSettingHistory key={KPI_GOAL_SETTING_TAB.HISTORY} />,
      <TabLog
        key={KPI_GOAL_SETTING_TAB.LOG}
        historyTabEnum={ENUM_LOG.TARGET}
        id={user?.regional_id ?? 0}
      />
    ]

    return {
      steps,
      flows
    }
  }

  const changeTabs = (data) => {
    dispatch(setDataTabMultiple(data))
    setTabActive(data)
    if (data === 0) {
      history.push(RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.THIS_YEAR))
    } else if (data === 1) {
      history.push(RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.HISTORY))
    } else if (data === 2) {
      history.push(RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.LOG))
    }
  }

  const buttonDropdownList = [
    {
      title: t("dropdown.yearly_target"),
      showed: true,
      action: () => {
        history.push(RoutesConstants.FIELD_OFFICER.CREATE_SETTING_TARGET_FORM())
      }
    },
    {
      title: t("dropdown.forecast"),
      showed: true
    }
  ]

  useEffect(() => {
    subheader.setTitle(t("sub_header.kpi_goal_setting"))
    subheader.setButton(
      <ButtonDropdown title={t("button.setting_target")} dropdownList={buttonDropdownList} />
    )
  }, [])

  return (
    <>
      <RowModule>
        <TabsComponent
          labelTab={renderComponent().steps}
          componentTab={renderComponent().flows}
          initValue={tabActive}
          changeTab={changeTabs}
        />
      </RowModule>
    </>
  )
}
