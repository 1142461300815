export const FORM_NEED = {
  harusDiIsi: " Harus di isi",
  mustBeNumber: " Must be a number",
  moreThan: (value) => `more than ${value}`
}

export function getStringErrorMassage(dataShow, formNeed) {
  return dataShow + formNeed
}

export function getMaximumErrorString(maximumInput) {
  return "Maximum " + maximumInput + " character"
}

export function getMinimumErrorString(minimumInput) {
  return "Minimum " + minimumInput + " character"
}

export function getMaximumErrorNumber(maximumInput) {
  return `Value must be less than ${maximumInput}`
}

export function getMinimumErrorNumber(minimumInput) {
  return `Value must be greater than ${minimumInput}`
}
