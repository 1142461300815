export const FIELD_OFFICER_ROLE_ENUM = {
  FIELD_OFFICER: "FIELD_OFFICER",
  FIELD_OFFICER_HARVEST: "FIELD_OFFICER_HARVEST",
  FIELD_OFFICER_AGRONOMIST: "FIELD_OFFICER_AGRONOMIST",
  FIELD_OFFICER_COORDINATOR: "FIELD_OFFICER_COORDINATOR",
  FIELD_OFFICER_QA: "FIELD_OFFICER_QA",
  REGIONAL_HEAD: "REGIONAL_HEAD",
  FIELD_MANAGER: "FIELD_MANAGER",
  FIELD_SUPERVISOR: "FIELD_SUPERVISOR"
}

export const ORDER_STATUS_ENUM = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  RESCHEDULED: "RESCHEDULED",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  CONFIRMED_FO: "CONFIRMED_FO",
  OVERDUE: "OVERDUE",
  CONFIRMED_ERP: "CONFIRMED_ERP",
  SPK_CREATED: "SPK_CREATED"
}

export const ORDER_STATUS_PLANTING_ENUM = {
  PRE_PLANTING: "PRE_PLANTING",
  PASCA_PLANTING: "PASCA_PLANTING",
  CLEARING_AREA: "LAND_CLEARING"
}

export const ROUTING_STATUS = {
  SCHEDULE_STRING: {
    PENDING: "Pending",
    RESCHEDULE: "Rescheduled",
    APPROVE: "Approved",
    REJECT: "Rejected",
    ALL: "all"
  },
  SPRAYING_PASCA_PLANTING_STRING: {
    PENDING: "pending",
    RESCHEDULED: "rescheduled",
    APPROVED: "scheduled",
    REJECT: "rejected",
    CANCEL: "canceled",
    DONE: "done",
    OVERDUE: "overdue"
  },
  MAC_SPRAYING_STRING: {
    PENDING: "pending",
    RESCHEDULED: "rescheduled",
    APPROVED: "approved",
    REJECT: "rejected",
    CANCEL: "canceled",
    DONE: "done",
    OVERDUE: "overdue"
  },
  STATUS_STRING: {
    PENDING: "pending",
    ACTIVE: "active",
    REJECT: "reject",
    REJECTED: "rejected",
    INACTIVE: "inactive"
  },
  STATUS_ENUM: {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    REJECT: "REJECT",
    REJECTED: "REJECTED",
    INACTIVE: "INACTIVE",
    SUBMITTED: "SUBMITTED",
    COMPLETED: "COMPLETED"
  },
  CERTIFICATION_STATUS_STRING: {
    PENDING: "pending",
    SUBMITTED: "submitted",
    APPROVED: "approved",
    REJECTED: "rejected"
  },
  ACTIVITY_REPORT: {
    READY_SEND_FINANCE: "ready-send-finance",
    SENDING_FINANCE: "sending-finance",
    NEED_BA: "need-ba",
    NO_BILL: "no-bill"
  }
}

export const ACTIVITY_STATUS_ENUM = {
  WAITING: "WAITING",
  NEED_BA: "NEED_BA",
  APPROVED: "APPROVED",
  COMPLETED: "COMPLETED"
}

export const ACTIVITY_METHODE_ENUM = {
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMATIC",
  USING_TOOL: "USING_TOOL"
}

export const PLANT_TYPE_ENUM = {
  PADI: "PADI",
  JAGUNG: "JAGUNG"
}

export const HARVEST_TYPE_ENUM = {
  PRODUCTION: "PRODUCTION",
  FREE_MARKET: "FREE_MARKET"
}

export const TEMPLATE_TYPE_ENUM = {
  FULL: "FULL",
  DEFAULT: "DEFAULT"
}

export const DATE_TYPE_ENUM = {
  ISO_DATE_ID: "dd MMMM yyyy",
  ISO_DATETIME_ID: "dd MMMM yyyy HH:mm:ss",
  ISO_TIME_ID: "HH:mm:ss",
  ISO_DATE_EURO: "yyyy-MM-dd",
  ISO_DATETIME_STRAP: "dd-MM-yyyy HH:mm:ss",
  ISO_DATE_STRAP: "dd-MM-yyyy",
  ISO_TIME_STRAP: "HH:mm:ss",
  ISO_TIME_STRAP_SHORT: "HH:mm",
  DDMMYYYY: "ddMMyyyy"
}

export const GENDER_ENUM = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const MACHINE_OWNER_TYPE_ENUM = {
  MAXXI_TANI: "MAXXI_TANI",
  MITRA: "MITRA",
  THIRD_PARTY: "THIRD_PARTY",
  FARMER: "FARMER",
  OTHER_MITRA: "OTHER_MITRA",
  OTHER_FARMER: "OTHER_FARMER",
  OTHER_VENDOR: "OTHER_VENDOR"
}

export const AREA_STATUS = {
  LEAD_AREA: "LEAD_AREA"
}

export const BUYER_TYPE_ENUM = {
  FACTORY: "FACTORY",
  FARMER: "FARMER",
  INDIVIDUAL: "INDIVIDUAL"
}

export const BUYER_TYPE_STRING = {
  FACTORY: "Pabrik",
  FARMER: "Petani",
  INDIVIDUAL: "Perseorangan"
}

export const PLANT_TYPE_STRING = {
  PADDY: "padi",
  CORN: "jagung",
  PROD_CORN: "jagung produksi"
}

export const ACTIVE_TAB_ENUM = {
  REPORT: "REPORT",
  SUBMISSION: "SUBMISSION",
  MASTER: "MASTER",
  SETTING: "SETTING",
  MAC: "MAC",
  ORDER: "ORDER",
  AREA: "AREA",
  FO: "FO",
  FARMER: "FARMER",
  MITRA: "MITRA"
}

export const REASON = {
  DISASTER: "5e0adb30-9116-4dab-a942-93391d67084d",
  PEST: "468ee20a-777b-47db-8e5f-d49e33c13458",
  OTHER_PARENT: "9691f70b-a789-4013-a49e-66cd311355a6",
  OTHER_DISASTER: "bb976b3f-0529-46e3-ac74-6485d5595512"
}

export const STATUS_VARIANT_CONSTANT = {
  COMPLETED: "completed",
  WARNING: "warning"
}

export const FARMER_GENDER = {
  MEN: "LAKI_LAKI",
  WOMAN: "PEREMPUAN"
}

export const ACTION_INIT_TYPE = {
  MITRA: {
    DEACTIVATE_MITRA: "DEACTIVATE_MITRA",
    UPLOAD: "UPLOAD",
    UPDATE_IMAGE: "UPDATE_IMAGE",
    SYNC_ERP: "SYNC_ERP"
  }
}

export const MITRA_STATUS = {
  ACTIVE: "Aktif",
  WAITING: "Sedang Menunggu",
  REJECT: "Ditolak",
  INACTIVE: "Tidak Aktif"
}

export const MITRA_TYPE = {
  EDUCATIONAL_INSTITUTIONS: "EDUCATIONAL_INSTITUTIONS",
  GOVERNMENT_AGENCIES: "GOVERNMENT_AGENCIES",
  PRIVATE_COMPANIES: "PRIVATE_COMPANIES",
  INDIVIDUAL: "INDIVIDUAL"
}

export const UI_STATUS = {
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  VERIFIED: "VERIFIED"
}

export const GENDER_TYPE = {
  LAKI_LAKI: "LAKI-LAKI",
  PEREMPUAN: "PEREMPUAN"
}

export const GENDER_REQUEST_ENUM = {
  MALE: "LAKI_LAKI",
  FEMALE: "PEREMPUAN"
}

export const MITRA_DOCUMENT = {
  KTP: "KTP",
  SWAFOTO: "SWAFOTO",
  BUSINESS_DOCUMENT: "BUSINESS_DOCUMENT",
  OTHER_IMAGE: "OTHER_IMAGE"
}

export const AREA_SALES_ORDER_TAB = {
  SO: "so",
  SPK: "spk"
}

export const AREA_UPDATE_STATUS = {
  REJECTED: "REJECTED",
  APPROVE: "APPROVE"
}

export const AREA_STATUS_ORDER_ENUM = {
  CONFIRMED_FO: "CONFIRMED_FO",
  CONFIRMED_ERP: "CONFIRMED_ERP",
  NEED_BA: "NEED_BA",
  WAITING: "WAITING",
  APPROVED: "APPROVED"
}

export const AREA_REGISTERED_ENUM = {
  ...ROUTING_STATUS.STATUS_STRING,
  ALL: "all",
  REGISTERED: "teregistrasi",
  AREA_KUR: "area-kur",
  AREA: "area"
}

export const AREA_STATUS_STRING = {
  ...ROUTING_STATUS.STATUS_STRING,
  REGISTERED: "teregistrasi",
  LEAD: "lead",
  UNASSIGN: "unassign",
  MAP: "map"
}

export const AREA_STATUS_ENUM = {
  ...ROUTING_STATUS.STATUS_ENUM,
  LEAD_AREA: "LEAD_AREA",
  UNASSIGNED: "UNASSIGNED"
}

export const PLANTING_STATUS_ENUM = {
  ...ROUTING_STATUS.STATUS_ENUM,
  REGISTERED: "REGISTERED"
}

export const FARMER_ACTIVATION_ENUM = {
  ...ROUTING_STATUS.STATUS_ENUM
}

export const FO_STATUS_ENUM = {
  ...ROUTING_STATUS.STATUS_ENUM
}

export const FO_UPLOAD_ENUM = {
  AVATAR: "AVATAR"
}

export const ACCOUNT_STATUS_ENUM = {
  ...ROUTING_STATUS.STATUS_ENUM
}

export const FARMER_DOCUMENT = {
  KTP: "KTP",
  SELFIE: "SELFIE",
  FILE_EXCEL: "FILE_EXCEL"
}

export const REASON_FAILURE_ENUM = {
  PEST_ATTACK: "PEST_ATTACK",
  FLOODING: "FLOODING",
  OTHER: "OTHER"
}

export const FO_MITRA_ASSIGN_MODE_STRING = {
  SINGLE: "single",
  BULK: "bulk"
}

export const POP_UP_TYPE_STRING = {
  EMPTY: "empty",
  BERHASIL: "berhasil",
  GAGAL: "gagal",
  NONIMAGE: "nonImage"
}

export const MASTER_DATA_CATEGORY_ITEM_STRING = {
  FERTILIZER: "fertilizer"
}

export const AREA_ACTIVITY_STRING = {
  APPROVED: "approved",
  MANUAL: "manual",
  WAITING: "waiting",
  REGISTERED: "teregistrasi"
}

export const ACCOUNT_STATUS_STRING = {
  ACTIVE: "Active",
  INACTIVE: "Inactive"
}

export const INSPECTION_STATUS_ENUM = {
  PASS: "PASS",
  NOT_PASS: "NOT_PASS"
}

export const INSPECTION_STATUS_STRING = {
  PASS: "Lulus",
  NOT_PASS: "Tidak Lulus"
}

export const ACTIVITY_TYPE_ENUM = {
  PRELIMINARY: "PRELIMINARY",
  VEGETATIVE: "VEGETATIVE",
  FLOWERING: "FLOWERING",
  RIPE: "RIPE",
  HARVEST: "HARVEST"
}

export const ACTIVITY_TYPE_STRING = {
  PRELIMINARY: "Pendahuluan",
  VEGETATIVE: "Vegetatif",
  FLOWERING: "Berbunga",
  RIPE: "Masak",
  HARVEST: "Panen"
}

export const KPI_GOAL_SETTING_TAB = {
  THIS_YEAR: "this-year",
  LOG: "log",
  HISTORY: "history"
}