import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { UploadIcon } from "../../../../../component/atoms/Icons/SVG/upload.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  AddressMolecule,
  SelectInput,
  TextInput,
  UploadComponent
} from "../../../../../component/index.jsx"
import { TextFieldAutoComplete } from "../../../../../component/template/GoogleMaterialExamples/inputs/TextFieldAutoComplete.jsx"
import { FIELD_OFFICER_ROLE_ENUM, FO_UPLOAD_ENUM } from "../../../../../constants/StringConstant.js"
import {
  getListSubRegionalByFm,
  getListSubRegionalByRh,
  resetUrlResponse,
  uploadAction
} from "../../../../../redux/actions/MasterDataAction.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"

export const DataFo = ({ isReadOnly, onKeyDown }) => {
  const { values: formValues } = useFormikContext()
  const { MasterData, FieldOfficer } = useSelector((state) => state)
  const user = loadUserLogin()
  const dispatch = useDispatch()
  const params = useParams()
  const { t } = useTranslation()
  const [avatar, setAvatar] = useState("")
  const [listRole, setListRole] = useState([])
  const [listRegional, setLitRegional] = useState([])
  const [listRegionalHead, setListRegionalHead] = useState([])
  const [listSubRegional, setListSubRegional] = useState([])
  const [listFieldManager, setListFieldManager] = useState([])
  const [listFieldSupervisor, setListFieldSupervisor] = useState([])

  const [selectedRegional, setSelectedRegional] = useState(formValues["regional"])
  const [selectedRole, setSelectedRole] = useState(formValues["roleFO"])
  const [selectedRegionalHead, setSelectedRegionalHead] = useState(formValues["regionalHead"])
  const [selectedSubRegional, setSelectedSubRegional] = useState(formValues["subRegional"])
  const [selectedFieldManager, setSelectedFieldManager] = useState(formValues["fieldManager"])
  const [selectedFieldSupervisor, setSelectedFieldSupervisor] = useState(
    formValues["fieldSupervisor"]
  )
  const [selectedSubRegionalByFm, setSelectedSubRegionalByFm] = useState(
    formValues["subRegionalByFm"]
  )

  function onChangeAvatar(e) {
    dispatch(uploadAction({ data: e, type: FO_UPLOAD_ENUM.AVATAR }))
  }

  useEffect(() => {
    if (selectedRegionalHead) {
      dispatch(getListSubRegionalByRh(selectedRegionalHead))
    }
  }, [selectedRegionalHead])

  useEffect(() => {
    if (selectedFieldManager) {
      dispatch(getListSubRegionalByFm(selectedFieldManager))
    }
  }, [selectedFieldManager])

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === FO_UPLOAD_ENUM.AVATAR) {
        setAvatar(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])

  useEffect(() => {
    if (formValues.avatar) {
      if (MasterData.urlResponseUpload === "") {
        formValues.avatar = ""
      }
    }
  }, [MasterData.uploadType, MasterData.urlResponseUpload])

  function onChangeRole() {
    setSelectedRegionalHead("")
    setSelectedRegional("")
    setSelectedSubRegional([])
    setSelectedFieldManager("")
    setSelectedSubRegionalByFm("")
    setSelectedFieldSupervisor("")

    formValues["regionalHead"] = ""
    formValues["regionalHead"] = ""
    formValues["subRegional"] = []
    formValues["fieldManager"] = ""
    formValues["fieldSupervisor"] = ""
    formValues["subRegionalByFm"] = ""
  }

  useEffect(() => {
    if (FieldOfficer?.role && FieldOfficer?.role?.length > 0) {
      setListRole([
        ...FieldOfficer.role.map((value) => {
          return {
            label: value?.display_name,
            value: value?.name
          }
        })
      ])
    }
  }, [FieldOfficer.role])

  useEffect(() => {
    if (MasterData?.listRegionalAll) {
      setLitRegional(
        MasterData.listRegionalAll.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    }
  }, [MasterData?.listRegionalAll])

  useEffect(() => {
    if (MasterData?.listFieldManagerByUserLogin) {
      setListFieldManager(
        MasterData.listFieldManagerByUserLogin.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    }
  }, [MasterData?.listFieldManagerByUserLogin])

  useEffect(() => {
    if (MasterData?.listFieldSupervisorByUserLogin) {
      setListFieldSupervisor(
        MasterData.listFieldSupervisorByUserLogin.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    }
  }, [MasterData?.listFieldSupervisorByUserLogin])

  useEffect(() => {
    if (selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER) {
      if (MasterData?.listSubRegionalByRh) {
        setListSubRegional(
          MasterData.listSubRegionalByRh.map((item) => {
            return {
              label: item.name,
              value: item.id,
              description: item.regency_list
            }
          })
        )
      }
    }
  }, [MasterData?.listSubRegionalByRh, selectedRole])

  useEffect(() => {
    if (selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR) {
      if (MasterData?.listSubRegionalByFm) {
        setListSubRegional(
          MasterData.listSubRegionalByFm.map((item) => {
            return {
              label: item.name,
              value: item.id,
              description: item.regency_list
            }
          })
        )
      }
    }
  }, [MasterData?.listSubRegionalByFm, selectedRole])

  useEffect(() => {
    if (MasterData?.listRegionalHeadByUserLogin) {
      setListRegionalHead(
        MasterData.listRegionalHeadByUserLogin.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    }
  }, [MasterData?.listRegionalHeadByUserLogin])

  useEffect(() => {
    if (formValues.hasOwnProperty("roleFO")) {
      setSelectedRole(formValues["roleFO"])
    }
  }, [formValues["roleFO"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("fieldSupervisor")) {
      setSelectedFieldSupervisor(formValues["fieldSupervisor"])
    }
  }, [formValues["fieldSupervisor"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("regionalHead")) {
      setSelectedRegionalHead(formValues["regionalHead"])
    }
  }, [formValues["regionalHead"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("subRegional")) {
      setSelectedSubRegional(formValues["subRegional"])
    }
  }, [formValues["subRegional"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("subRegionalByFm")) {
      setSelectedSubRegionalByFm(formValues["subRegionalByFm"])
    }
  }, [formValues["subRegionalByFm"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("fieldManager")) {
      setSelectedFieldManager(formValues["fieldManager"])
    }
  }, [formValues["fieldManager"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("regional")) {
      setSelectedRegional(formValues["regional"])
    }
  }, [formValues["regional"]])

  const placeholder = <UploadIcon />

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("messages.title.detail_ops_team").toUpperCase()} />
        <CardBody>
          <RowModule>
            <UploadComponent
              urlParsing={avatar}
              label={t("label.profile_picture")}
              name="avatar"
              editable={!isReadOnly}
              onChange={onChangeAvatar}
              placeholder={placeholder}
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              onKeyDown={onKeyDown}
              editable={!isReadOnly}
              label={t("label.name")}
              name="name"
              placeholder={t("placeholder.input_name")}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="roleFO"
              required
              editable={!params?.id}
              label={t("label.role")}
              placeholder={t("placeholder.select_operational_team")}
              options={listRole}
              onChange={onChangeRole}
              chooseOptionWithLabel={false}
              value={selectedRole}
            />
          </RowModule>
          {selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR && (
            <RowModule>
              <SelectInput
                name="fieldManager"
                required
                editable={!params?.id}
                label={t("label.assign_to_field_manager")}
                placeholder={t("placeholder.select_field_manager")}
                options={listFieldManager}
                chooseOptionWithLabel={false}
                value={selectedFieldManager}
              />
            </RowModule>
          )}
          {selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER && (
            <RowModule>
              <SelectInput
                name="fieldSupervisor"
                required
                editable={!params?.id}
                label={t("label.assign_to_field_supervisor")}
                placeholder={t("placeholder.select_field_supervisor")}
                options={listFieldSupervisor}
                chooseOptionWithLabel={false}
                value={selectedFieldSupervisor}
              />
            </RowModule>
          )}
          {selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR && (
            <RowModule>
              <SelectInput
                name="subRegionalByFm"
                required
                editable={!params?.id}
                label={t("label.sub_regional")}
                placeholder={t("placeholder.select_sub_regional")}
                options={listSubRegional}
                chooseOptionWithLabel={false}
                value={selectedSubRegionalByFm}
              />
            </RowModule>
          )}

          {selectedRole === FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD && (
            <RowModule>
              <SelectInput
                name="regional"
                required
                editable={!params?.id}
                label={t("label.regional")}
                placeholder={t("placeholder.select_or_type_regional")}
                options={listRegional}
                chooseOptionWithLabel={false}
                value={selectedRegional}
              />
            </RowModule>
          )}

          {selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER && (
            <RowModule>
              <SelectInput
                name="regionalHead"
                required
                editable={!params?.id}
                label={t("label.assign_to_regional_head")}
                placeholder={t("placeholder.select_regional_head")}
                options={listRegionalHead}
                chooseOptionWithLabel={false}
                value={selectedRegionalHead}
              />
            </RowModule>
          )}
          {selectedRole === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER && (
            <RowModule>
              <TextFieldAutoComplete
                required
                name={"subRegional"}
                editable={!params?.id}
                label={t("label.sub_regional")}
                placeholder={t("placeholder.select_sub_regional")}
                options={listSubRegional}
                value={selectedSubRegional}
              />
            </RowModule>
          )}

          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              type="number"
              label={t("label.phone_number")}
              name="phone"
              placeholder={t("placeholder.input_phone_number")}
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label={t("label.email")}
              name="email"
              placeholder={t("placeholder.input_email_address")}
            />
          </RowModule>
        </CardBody>
      </Card>
      <AddressMolecule
        byRegional={!!user?.regional_id}
        regionalId={!!user?.regional_id}
        isChangeRegional={!!user?.regional_id}
        name="fo"
        isReadOnly={isReadOnly}
        addressTitle={t("label.operational_team_address").toUpperCase()}
      />
    </>
  )
}
