import { useDispatch, useSelector } from "react-redux"
import { FlatIconAndText, FlatIconAndText2Row, TabLog } from "../../../../component/index.jsx"
import { TabsComponent, SwitchToggle } from "../../../../component/index.jsx"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import {
  getAreas,
  getDetail,
  updateStatus,
  getRejectReasons,
  getCheckCanDeactivate,
  resetCheckCanDeactivate,
  deactivateStatusUser,
  changeMitra,
  updateImage
} from "../../../../redux/actions/FarmerAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Popup, PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { FarmerRejectReasons } from "../../../../component/molecule/List/FarmerRejectReasons.jsx"
import { clearUploadAction } from "../../../../redux/actions/MasterDataAction.jsx"
import { FarmerTab } from "./tab/FarmerTab.jsx"
import { AreaTab } from "./tab/AreaTab.jsx"
import { Form } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import {
  clearSyncErp,
  getPageDataVerifiedCombobox,
  syncErp
} from "../../../../redux/actions/MitraAction.jsx"
import Select from "react-select"
import { ENUM_LOG, FARMER, GENERAL } from "../../../../constants/InitTypeConstants.js"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { ButtonDropdown } from "../../../../component/atoms/Button/ButtonDropdown.jsx"
import swal from "sweetalert"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { toMeter, toRupiah } from "../../../helpers/TextHelper.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { Skeleton } from "@material-ui/lab"
import {
  AREA_STATUS_STRING,
  FARMER_ACTIVATION_ENUM,
  FARMER_GENDER,
  ROUTING_STATUS
} from "../../../../constants/StringConstant.js"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"
import { useTranslation } from "react-i18next"

export const FarmerDetail = ({
  history,
  match: {
    params: { id, status }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const [farmerData, setFarmerData] = useState()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { Farmer, General, Mitra, MasterData } = useSelector((state) => state)

  const [rejectReasons, setRejectReasons] = useState([])
  const [showPopupReject, setShowPopupReject] = useState(false)
  const [showPopupApprove, setShowPopupApprove] = useState(false)
  const [activeUser, setActiveUser] = useState(true)
  const [showPopupChangeStatus, setShowPopupChangeStatus] = useState(false)
  const [reasonChangeStatus, setReasonChangeStatus] = useState(null)
  const [showPopup, setShowPopup] = useState({
    show: false,
    body: ""
  })
  const [showPopupReasonChangeMitra, setShowPopupReasonChangeMitra] = useState(false)
  const [showPopupSetMitra, setShowPopupSetMitra] = useState(false)
  const [reasonChangeMitra, setReasonChangeMitra] = useState(null)
  const [newMitraId, setNewMitraId] = useState(null)
  const [listMitra, setListMitra] = useState(null)
  const [statusPopup, setStatusPopup] = useState(null)
  const [showPopupSync, setShowPopupSynch] = useState(false)
  const [updateStatusFarmer, setUpdateStatusFarmer] = useState("")

  useEffect(() => {
    dispatch(resetCheckCanDeactivate())
    dispatch(clearUploadAction())
  }, [])

  useEffect(() => {
    dispatch(resetCheckCanDeactivate())
    dispatch(getDetail(id))
    dispatch(getAreas(id))
  }, [])

  useEffect(() => {
    const farmerStatus =
      (status.toUpperCase() === ROUTING_STATUS.STATUS_ENUM.ACTIVE && t("label.active")) ||
      (status.toUpperCase() === ROUTING_STATUS.STATUS_ENUM.PENDING && t("label.waiting")) ||
      (status.toUpperCase() === ROUTING_STATUS.STATUS_ENUM.INACTIVE && t("label.inactive")) ||
      (status.toUpperCase() === ROUTING_STATUS.STATUS_ENUM.REJECTED && t("label.rejected"))
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.FARMER.LIST_FARMER(ROUTING_STATUS.STATUS_ENUM.PENDING),
        title: t("label.farmer")
      },
      {
        pathname: RoutesConstants.FARMER.LIST_FARMER(status.toUpperCase()),
        title: farmerStatus
      },
      {
        title: General.loading ? "" : farmerData?.name
      }
    ])
    subHeader.setTitle(t("sub_header.farmer_detail"))
  }, [farmerData])

  useEffect(() => {
    if (Mitra?.combobox) {
      setListMitra(
        Mitra?.combobox.map((e) => ({
          label: e?.name + ", " + e?.code,
          value: e?.id
        }))
      )
    }
  }, [Mitra?.combobox])

  useEffect(() => {
    if (privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER1) > -1 && status === "active") {
      subHeader.setButton(
        <Button
          className={activeUser ? "btn btn-outline-primary" : "btn btn-dark"}
          disabled={!activeUser ? true : false}
          onClick={() => {
            if (activeUser) {
              history.push(RoutesConstants.FARMER.EDIT_FARMER_STATUS(status, id))
            }
          }}>
          Edit
        </Button>
      )
    }
  }, [activeUser])

  useEffect(() => {
    switch (status) {
      case AREA_STATUS_STRING.ACTIVE:
        {
          privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER) > -1 &&
            subHeader.setButton(<ButtonDropdown title={"Action"} dropdownList={listDropdown} />)
        }

        break
      case AREA_STATUS_STRING.REJECTED:
        {
          privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER) > -1 &&
            subHeader.setButton(
              <Button
                variant="secondary"
                onClick={() => {
                  history.push(RoutesConstants.FARMER.EDIT_FARMER_STATUS(status, id))
                }}>
                Edit
              </Button>
            )
        }
        break

      case AREA_STATUS_STRING.PENDING:
        subHeader.setButton(
          <RowModule>
            <>
              <Button
                variant="danger"
                onClick={() => {
                  setShowPopupReject(true)
                }}>
                Reject
              </Button>
              {privileges.indexOf(PRIVILEGE_ENUM.APPROVAL_FARMER_REGISTRATION) > -1 && (
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => {
                    setShowPopupApprove(true)
                  }}>
                  Approve
                </Button>
              )}
            </>
          </RowModule>
        )
        break
      case AREA_STATUS_STRING.LEAD:
        {
          privileges.indexOf(PRIVILEGE_ENUM.FARMER_LEAD_DELETE) > -1 &&
            subHeader.setButton(
              <Button
                variant="danger"
                onClick={() => {
                  history.push(RoutesConstants.FARMER.EDIT_FARMER(id))
                }}>
                Delete
              </Button>
            )
        }
        break

      default:
        break
    }
  }, [status, farmerData])

  useEffect(() => {
    if (Farmer?.rejectReasons?.length === 0) {
      dispatch(getRejectReasons())
    }
  }, [Farmer.rejectReasons])

  useEffect(() => {
    if (farmerData) {
      if (Farmer?.canDeactivate?.status === true) {
        setShowPopupChangeStatus(true)
      } else if (Farmer?.canDeactivate?.status === false) {
        swal({
          text: Farmer?.canDeactivate?.message,
          icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_INVALID_CHANGE_STATUS)
        })
      }
    }
  }, [Farmer.canDeactivate])

  useEffect(() => {
    if (Farmer.data !== undefined) {
      setFarmerData(Farmer.data)
      setActiveUser(Farmer.data.farmer_activation === FARMER_ACTIVATION_ENUM.ACTIVE ? true : false)
    }
  }, [Farmer.data])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === FARMER.DEACTIVATE_FARMER) {
        setShowPopupChangeStatus(false)
        setReasonChangeStatus(null)
        dispatch(getDetail(id))
        dispatch(resetCheckCanDeactivate())
        subHeader.setButton(
          <Button className="btn btn-dark" disabled={true}>
            Edit
          </Button>
        )
        swal({
          text: Farmer.messages,
          icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_FARMER_NONACTIVE)
        })
      } else if (General.actionInitType === FARMER.CHANGE_MITRA) {
        dispatch(getDetail(id))
        setShowPopupReasonChangeMitra(false)
        setReasonChangeMitra(null)
        setShowPopupSetMitra(false)
        setNewMitraId(null)
        swal({
          text: Farmer.messages,
          icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_CHANGE_MITRA)
        })
      } else if (General.actionInitType === GENERAL.UPLOAD) {
        const data = {
          upload_enum: statusPopup,
          url: MasterData.urlResponseUpload
        }
        dispatch(updateImage({ id, data }))
      } else if (General.actionInitType === FARMER.UPDATE_IMAGE) {
        dispatch(getDetail(id))
      } else if (General.actionInitType === FARMER.UPDATE_STATUS) {
        history.push(RoutesConstants.FARMER.LIST_FARMER(ROUTING_STATUS.STATUS_ENUM.PENDING))
        swal({
          text: Farmer.messages,
          icon: toAbsoluteUrl(
            updateStatusFarmer === ROUTING_STATUS.STATUS_ENUM.REJECTED
              ? ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_REJECT
              : ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_APPROVE
          )
        })
      }
      if (General.actionInitType === FARMER.DEACTIVATE_FARMER) {
        swal({
          text: Farmer.messages,
          icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_FARMER_NONACTIVE)
        })
      }
    }
  }, [General.isFinishUpdateOrDelete, Farmer])

  useEffect(() => {
    if (General.isFinishSyncErp) {
      swal({
        text: Mitra.messages,
        icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_SEND_ERP)
      })
      dispatch(clearSyncErp())
    }
  }, [General.isFinishSyncErp])

  const descriptionLeft = (
    <>
      <div className="d-flex flex-row">
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={farmerData?.code ? farmerData?.code : "-"}
            svg={ICON_CONSTANS.IC_FARMER}
          />
          <FlatIconAndText
            text={farmerData?.regional ? farmerData?.regional : "-"}
            svg={ICON_CONSTANS.IC_PIN_LOCATION_2}
          />
          {privileges.indexOf(PRIVILEGE_ENUM.FARMER_KTP_NUMBER) > -1 && (
            <FlatIconAndText
              text={farmerData?.ktp_no ? farmerData?.ktp_no : "-"}
              svg={ICON_CONSTANS.IC_ACCOUNT}
            />
          )}
          <FlatIconAndText
            text={
              farmerData?.gender === FARMER_GENDER.MEN
                ? t("label.men")
                : farmerData?.gender === FARMER_GENDER.WOMAN
                ? t("label.woman")
                : "-"
            }
            svg={ICON_CONSTANS.IC_GENDER}
          />
          <FlatIconAndText
            text={farmerData?.dob ? farmerData?.dob : "-"}
            svg={ICON_CONSTANS.IC_CALENDER}
          />
          {privileges.indexOf(PRIVILEGE_ENUM.FARMER_PHONE_NUMBER) > -1 && (
            <FlatIconAndText
              text={farmerData?.phone ? farmerData?.phone : "-"}
              svg={ICON_CONSTANS.IC_PHONE}
            />
          )}
        </div>
      </div>
      {General.loading ? (
        <>
          <Skeleton animation="wave" variant={"text"} width={250} />
          <Skeleton animation="wave" variant={"text"} width={200} />
        </>
      ) : (
        <>
          <span className="font-weight-bold text-dark-50">
            {farmerData != null ? farmerData?.address : ""} -{" "}
            {farmerData != null ? farmerData?.sub_district?.name : ""} -{" "}
            {farmerData != null ? farmerData?.district?.name : ""}
          </span>
          <span className="font-weight-bold text-dark-50">
            {farmerData != null ? farmerData?.regency?.name : ""} -{" "}
            {farmerData != null ? farmerData?.province?.name : ""}
          </span>
        </>
      )}
    </>
  )

  const descriptionRight = (
    <>
      {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER) > -1 &&
        (status === "active" || status === "inactive") && (
          <div className="d-flex flex-column">
            <SwitchToggle
              checked={activeUser}
              label="Status User"
              onChange={inactiveUser}
              withLabel={true}
              withStatusText={true}
              statusText={activeUser ? "Active" : "Inactive"}
              disabled={!activeUser ? true : false}
            />
          </div>
        )}
    </>
  )

  const flatIconAndText2Rows = (
    <>
      {/*{privileges.indexOf(Privilege.FARMER_BANK_ACCOUNT_NO) > -1 && (<>*/}
      <FlatIconAndText2Row
        rounded={true}
        title={t("label.total_area")}
        description={farmerData?.total_area ? farmerData?.total_area : "-"}
        svg={ICON_CONSTANS.IC_FIELD_BIG}
      />
      <FlatIconAndText2Row
        rounded={true}
        title={t("label.land_area_total")}
        description={farmerData?.total_land_area ? toMeter(farmerData?.total_land_area) : "-"}
        svg={ICON_CONSTANS.IC_AREA}
      />
      {farmerData?.has_kur && (
        <FlatIconAndText2Row
          rounded={true}
          title="Plafon KUR BNI"
          description={farmerData?.kur_plafond ? toRupiah(farmerData?.kur_plafond) : "-"}
          svg={ICON_CONSTANS.IC_INCOME}
        />
      )}
    </>
  )

  const farmerUpdateStatus = async (status = "") => {
    dispatch(updateStatus(id, status.toUpperCase(), rejectReasons))
  }

  const renderContents = () => {
    const labels = [t("label.farmer_detail").toUpperCase()]
    const components = [
      <FarmerTab
        key={0}
        farmerData={farmerData}
        status={status}
        descriptionLeft={descriptionLeft}
        descriptionRight={descriptionRight}
        flatIconAndText2Rows={flatIconAndText2Rows}
        setShowPopupReasonChangeMitra={setShowPopupReasonChangeMitra}
        setStatusPopup={setStatusPopup}
      />
    ]

    if (status === AREA_STATUS_STRING.ACTIVE || status === AREA_STATUS_STRING.INACTIVE) {
      labels.push(t("label.area_name").toUpperCase())
      components.push(<AreaTab id={id} />)
    }

    if (status) {
      labels.push(t("label.log").toUpperCase())
      components.push(<TabLog historyTabEnum={ENUM_LOG.FARMER} id={id} />)
    }

    return {
      labels,
      components
    }
  }

  const handleSubmitERP = () => {
    const code = farmerData.code
    const urlName = "farmer"
    dispatch(syncErp(urlName, code))
  }

  const listDropdown = [
    {
      title: t("label.edit_farmer"),
      icon: ICON_CONSTANS.IC_EDIT,

      action: () => {
        if (activeUser) {
          history.push(RoutesConstants.FARMER.EDIT_FARMER_STATUS(status, id))
        }
      },
      showed: status.toUpperCase() === ROUTING_STATUS.STATUS_ENUM.ACTIVE ? true : false
    },
    {
      title: t("label.change_mitra"),
      icon: ICON_CONSTANS.IC_MITRA,
      action: () => {
        setShowPopupReasonChangeMitra(true)
      },
      showed: farmerData?.is_allow_change_mitra && status.toUpperCase() === ROUTING_STATUS.STATUS_ENUM.ACTIVE
    },
    {
      title: "Sync data ERP",
      icon: ICON_CONSTANS.IC_SYNC,
      action: handleSubmitERP,
      showed: privileges.indexOf(PRIVILEGE_ENUM.CREATE_FARMER) > -1
    }
  ]

  function inactiveUser() {
    dispatch(getCheckCanDeactivate(id))
  }

  function handleSubmitChangeStatusUser() {
    dispatch(deactivateStatusUser(id, { reason: reasonChangeStatus }))
  }

  return (
    <>
      {/* Popup Success */}
      <Popup
        show={showPopup.show}
        body={showPopup.body}
        onClick={() => {
          setShowPopup({
            show: false,
            body: ""
          })
        }}
      />
      {/* Popup for reject */}
      <PopupQuestion
        show={showPopupReject}
        title={t("messages.title.farmer_reject_reason")}
        bodyCustom={
          <FarmerRejectReasons
            items={Farmer?.rejectReasons}
            onChange={(list) => {
              setRejectReasons(list)
            }}
          />
        }
        textOk="Reject"
        onCancel={() => {
          setShowPopupReject(false)
        }}
        disable={rejectReasons.length > 0 ? false : true}
        variantOkButton={"danger"}
        onOk={() => {
          farmerUpdateStatus("REJECTED")
          setShowPopupReject(false)
          setUpdateStatusFarmer("REJECTED")
        }}
      />
      {/* Popup for approve */}
      <PopupQuestion
        show={showPopupApprove}
        title={t("messages.title.farmer_approve")}
        bodyCustom={
          <span>
            Data Petani yang disetujui akan berubah menjadi{" "}
            <span className="font-weight-bold">terverifikasi</span>. Pastikan semua data telah
            lengkap dan benar.
          </span>
        }
        textOk="Approve"
        onCancel={() => {
          setShowPopupApprove(false)
        }}
        onOk={() => {
          farmerUpdateStatus(ROUTING_STATUS.STATUS_ENUM.ACTIVE)
          setShowPopupApprove(false)
        }}
      />
      {/* Popup for synch farmer */}
      <PopupQuestion
        show={showPopupSync}
        title={t("messages.title.erp_synchronization")}
        bodyCustom={<span>{t("messages.confirmation.erp_synchronization_confirmation")}</span>}
        textOk="Approve"
        onCancel={() => {
          setShowPopupSynch(false)
        }}
        onOk={handleSubmitERP}
      />
      {/* Popup for change status farmer */}
      <PopupQuestion
        show={showPopupChangeStatus}
        title={t("messages.title.change_farmer_status")}
        bodyCustom={
          <Form.Group className="mb-3">
            <Form.Label>{t("messages.confirmation.change_farmer_status_add_reason")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reasonChangeStatus}
              onChange={(e) => {
                setReasonChangeStatus(e.target.value)
              }}
              placeholder={t("placeholder.change_farmer_status_input")}
            />
            <div className="p-5 mt-3 rounded" style={{ background: "#F8F8F8" }}>
              <i className="fa fa-info-circle mr-2" style={{ color: "#808080" }}></i>
              {t("label.every_action_will_be_recorded_in_the_log_history")}
            </div>
          </Form.Group>
        }
        variantCancelButton="outline-secondary"
        variantOkButton={reasonChangeStatus ? "primary" : "secondary"}
        disable={reasonChangeStatus === null || reasonChangeStatus === ""}
        textOk={t("button.next")}
        onCancel={() => {
          setShowPopupChangeStatus(false)
          setReasonChangeStatus(null)
        }}
        onOk={handleSubmitChangeStatusUser}
      />
      {/* Popup for change status farmer */}
      <PopupQuestion
        show={showPopupReasonChangeMitra}
        title={t("messages.title.change_farmer_mitra")}
        bodyCustom={
          <Form.Group className="mb-3">
            <Form.Label>{t("label.change_farmer_mitra")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reasonChangeMitra}
              onChange={(e) => {
                setReasonChangeMitra(e.target.value)
              }}
              placeholder={t("placeholder.change_farmer_mitra_input")}
            />
          </Form.Group>
        }
        variantCancelButton="outline-secondary"
        variantOkButton={reasonChangeMitra ? "primary" : "secondary"}
        disable={reasonChangeMitra === null || reasonChangeMitra === ""}
        textOk={t("button.next")}
        onCancel={() => {
          setShowPopupReasonChangeMitra(false)
          setReasonChangeMitra(null)
        }}
        onOk={() => {
          dispatch(getPageDataVerifiedCombobox())
          setShowPopupReasonChangeMitra(false)
          setShowPopupSetMitra(true)
        }}
      />
      {/* Popup for choose mitra changed */}
      <PopupQuestion
        show={showPopupSetMitra}
        title={t("messages.title.select_mitra_replacement")}
        bodyCustom={
          <div className="mb-3">
            <label>{t("label.select_farmer_mitra_assignment")}</label>
            <Select
              name="mitraId"
              onChange={(e) => setNewMitraId(e.value)}
              label={null}
              placeholder={t("placeholder.select_one")}
              options={listMitra}
              withoutFeedback={true}
              clearNow={true}
            />
          </div>
        }
        variantCancelButton="outline-secondary"
        variantOkButton={newMitraId ? "primary" : "secondary"}
        disable={newMitraId === null || !newMitraId}
        textOk={t("button.next")}
        onCancel={() => {
          setShowPopupReasonChangeMitra(false)
          setReasonChangeMitra(null)
          setShowPopupSetMitra(false)
          setNewMitraId(null)
        }}
        onOk={() => dispatch(changeMitra(id, { reason: reasonChangeMitra, mitra_id: newMitraId }))}
      />
      <TabsComponent
        labelTab={renderContents().labels}
        componentTab={renderContents().components}
      />
    </>
  )
}