import React from "react"
import {
  CardDetail,
  DetailText,
  FlatIconAndText,
  FlatIconAndText2Row,
  SwitchToggle
} from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { Card } from "react-bootstrap"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { FIELD_OFFICER_ROLE_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"

export const DetailOfficer = ({ data, activeUser, inactiveUser, role }) => {
  const { General } = useSelector((state) => state)
  const { t } = useTranslation()
  const subTitle = () => (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex flex-wrap mb-4">
          {!role && (
            <FlatIconAndText
              flatIcon="flaticon2-setting"
              text={checkValue(data?.role_string)}
              svg={ICON_CONSTANS.IC_ROLE_ACCESS}
            />
          )}

          <FlatIconAndText
            flatIcon="flaticon2-email"
            text={checkValue(data?.email)}
            svg={ICON_CONSTANS.IC_MAIL}
          />
          <FlatIconAndText
            flatIcon="flaticon2-phone"
            text={checkValue(data?.phone_number)}
            svg={ICON_CONSTANS.IC_PHONE}
          />
        </div>
        {General.loading ? (
          <>
            <Skeleton animation="wave" variant={"text"} width={250} />
            <Skeleton animation="wave" variant={"text"} width={200} />
          </>
        ) : (
          <span className="font-weight-bold text-dark-50">{checkValue(data?.full_address)}</span>
        )}
      </div>
    </>
  )

  const descriptionRight = (
    <>
      <div className="d-flex flex-column ">
        <SwitchToggle
          checked={activeUser === ROUTING_STATUS.STATUS_ENUM.ACTIVE}
          label={t("label.status_user")}
          onChange={inactiveUser}
          withLabel={true}
          withStatusText={true}
          statusText={
            activeUser === ROUTING_STATUS.STATUS_ENUM.ACTIVE
              ? t("label.active")
              : t("label.inactive")
          }
          disabled={activeUser === ROUTING_STATUS.STATUS_ENUM.INACTIVE}
        />
      </div>
    </>
  )
  const flatIconAndText2Rows = () => {
    return (
      <>
        {data?.role === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR && data?.field_manager_name && (
          <FlatIconAndText2Row
            title={t("label.field_manager")}
            description={checkValue(data?.field_manager_name)}
            enableSeparator={true}
          />
        )}
        {data?.role === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER && data?.regional_head_name && (
          <FlatIconAndText2Row
            title={t("label.regional_head")}
            description={checkValue(data?.regional_head_name)}
            enableSeparator={true}
          />
        )}
        {(data?.role === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR ||
          data?.role === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER) &&
          data?.sub_regional_list && (
            <FlatIconAndText2Row
              title={t("label.sub_regional")}
              description={
                data?.sub_regional_list.length > 0
                  ? data?.sub_regional_list.map((item) => item.name).join(", ")
                  : "-"
              }
            />
          )}
        {data?.role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER && data?.field_supervisor_name && (
          <FlatIconAndText2Row
            title={t("label.field_supervisor")}
            description={checkValue(data?.field_supervisor_name)}
          />
        )}
        {data?.role === FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD && data?.regional_name && (
          <FlatIconAndText2Row
            title={t("label.regional")}
            description={checkValue(data?.regional_name)}
          />
        )}
      </>
    )
  }
  return (
    <>
      <RowModule>
        {activeUser === ROUTING_STATUS.STATUS_ENUM.INACTIVE && (
          <RowModule>
            <Card style={{ background: "#F8F8F8" }}>
              <CardHeader
                title={t("card_title.status_change_reason")}
                className="mb-0 pb-2 bg-gray-card"
              />
              <CardBody>{data?.field_officer_deactivation_reason}</CardBody>
            </Card>
          </RowModule>
        )}
      </RowModule>
      <RowModule withoutSeparator={true}>
        <CardDetail
          titleImage={data?.url_profile}
          titleCardDetail={data?.name}
          descriptionLeft={subTitle()}
          descriptionRight={descriptionRight}
          flatIconAndText2Rows={flatIconAndText2Rows()}
          wrap={false}
        />
      </RowModule>
      {data?.url_profile &&
        data?.role !== FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD &&
        data?.role !== FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER && (
          <RowModule withoutSeparator={true} customColumnCss="col-lg-6 col-sm-6 col-md-6">
            <DetailText
              title={t("label.profile_picture").toUpperCase()}
              menu="AVATAR"
              value={data?.url_profile}
              type="image"
              withoutSeparator={true}
            />
          </RowModule>
        )}
    </>
  )
}