import { useFormik } from "formik"
import { resetUrlResponse, uploadAction } from "../../../../redux/actions/MasterDataAction.jsx"
import { UPLOAD } from "../../../../constants/InitTypeConstants.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import {
  getDetailTargetByYearTargetRegional,
  getDetailTargetByYearTargetSubRegional,
  submitEditSettingTargetSubRegional
} from "../../../../redux/actions/FieldOfficerAction.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import SVG from "react-inlinesvg"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import * as Yup from "yup"
import { debounce } from "lodash"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { useHistory } from "react-router-dom"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { KPI_GOAL_SETTING_TAB } from "../../../../constants/StringConstant.js"

export function useEditTargetSubRegionalPage() {
  const dispatch = useDispatch()
  const user = loadUserLogin()
  const subHeader = useSubheader()
  const uiService = useHtmlClassService()
  const { t } = useTranslation()
  const history = useHistory()

  const regionalId = user?.regional_id
  const { year } = useParams()
  const { MasterData, General, FieldOfficer } = useSelector((state) => state)
  const [urlDocument, setUrlDocument] = useState(null)
  const [fileAttachments, setFileAttachments] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [activeForm, setActiveForm] = useState(0)
  const [totalTargetSubregional, setTotalTargetSubregional] = useState(undefined)
  const [target, setTarget] = useState(undefined)
  const [fileName, setFileName] = useState("")

  const labelStep = [t("label.edit_target"), t("label.preview")]

  useEffect(() => {
    subHeader.setButton()
  }, [])

  let formik
  formik = useFormik({
    initialValues: {
      year: year,
      target: [],
      sub_regional_target: [],
      document_url: ""
    },
    validationSchema: Yup.object().shape({
      sub_regional_target: Yup.array()
        .of(
          Yup.object({
            target: Yup.array().of(
              Yup.object({
                value: Yup.number()
                  .typeError(
                    t("label.input_must_number", { input: t("label.target_sub_regional") })
                  )
                  .moreThan(
                    -1,
                    t("label.input_must_positive_number", { input: t("label.target_sub_regional") })
                  )
                  .test(
                    "no-more-than-0-less-than-1-decimal",
                    t("label.input_must_not_more_than_less_than", {
                      input: t("label.target_sub_regional"),
                      more: 0,
                      less: 1
                    }),
                    (value) => {
                      return !(value > 0 && value < 1)
                    }
                  )
                  .test(
                    "decimal-one-point",
                    t("label.input_must_have_one_point", { input: t("label.target_sub_regional") }),
                    (value) => {
                      const stringValue = String(value)
                      const parts = stringValue.split(".")
                      if (parts.length > 1) {
                        return parts[1].length === 1
                      } else {
                        return true
                      }
                    }
                  )
                  .required(
                    t("label.input_must_inputted", { input: t("label.target_sub_regional") })
                  )
              })
            )
          })
        )
        .test((srTargetTest) => {
          const sumTarget = generateTotal(srTargetTest)
          let isTargetValid = checkSubRegionalTargetWithTotalTarget(sumTarget)
          return !isTargetValid
        }),
      document_url: Yup.string()
        .trim()
        .min(1)
        .test(
          "document-url-must-pdf",
          t("label.input_must_custom", { input: "Document", custom: "pdf file" }),
          (value) => {
            if (value) {
              if (value === "") return true
              if (value.includes("loader.gif")) return true
              if (value.includes("https://")) return true
              if (!value.includes("loader.gif") && !value.includes("https://")) {
                setFileName(value)
              }
              return fileName.includes(".pdf")
            }
          }
        )
    }),
    onSubmit: (formikPayload) => {
      if (activeStep === 1) {
        const srPayload = formikPayload?.sub_regional_target.map((srItem) => {
          return {
            id: srItem.id,
            target: srItem.target.map((srTarget) => {
              return {
                id: srTarget.id,
                value: srTarget.value
              }
            })
          }
        })
        const payload = { sub_regionals: srPayload, document_url: formikPayload?.document_url }
        dispatch(submitEditSettingTargetSubRegional(regionalId, payload))
          .then(() => {
            return swal({
              text: t("messages.response.success_setting_target_sub_regional"),
              icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.IL_SUCCESS_SETTING_TARGET)
            })
          })
          .then(() => {
            history.push(
              RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.THIS_YEAR)
            )
          })
      }
    }
  })

  function showToastValidationSubRegional() {
    toast(t("label.note_view_setting_target_sub_regional_description"), {
      position: "top-center",
      autoClose: 5000,
      style: {
        background: "#353535"
      },
      icon: <SVG src={ICON_CONSTANS.IC_BULLET_CLOSE_RED} />,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark"
    })
  }

  useEffect(() => {
    formik?.setFieldValue("target", [])
    setTarget([])
    if (Array.isArray(FieldOfficer?.page?.target)) {
      formik?.setFieldValue("target", FieldOfficer?.page?.target)
      setTarget(FieldOfficer?.page?.target)
    }
  }, [FieldOfficer?.page?.target])

  useEffect(() => {
    formik?.setFieldValue("sub_regional_target", [])
    if (
      FieldOfficer?.kpiGoalInitDataEditSubRegional &&
      FieldOfficer?.kpiGoalInitDataEditSubRegional.length > 0
    ) {
      if (
        Array.isArray(formik?.values.sub_regional_target) &&
        formik?.values.sub_regional_target.length === 0
      ) {
        formik?.setFieldValue("sub_regional_target", FieldOfficer?.kpiGoalInitDataEditSubRegional)
      }
    }
  }, [FieldOfficer?.kpiGoalInitDataEditSubRegional])

  const generateTotal = (targetSubRegionals) => {
    if (!targetSubRegionals) return

    return targetSubRegionals.reduce((targetObj, item) => {
      return item.target.reduce((acc, targetItem) => {
        const plantId = targetItem.plant_type_id
        const plantValue = targetItem.value

        if (!acc[plantId]) {
          acc[plantId] = {
            plant_type_name: targetItem.plant_type_name,
            value: parseInt(plantValue)
          }
        } else {
          acc[plantId] = {
            ...acc[plantId],
            value: parseInt(acc[plantId].value) + parseInt(plantValue)
          }
        }

        return acc
      }, targetObj)
    }, {})
  }

  const handleStep = () => {
    if (activeStep === 0) {
      setActiveStep(1)
    } else if (activeStep === 1) {
      setActiveStep(activeStep - 1)
    }
  }

  const checkSubRegionalTargetWithTotalTarget = (targets) => {
    let isTargetValid = false
    const srTarget = Object.entries(targets)
    srTarget.forEach((totalItem) => {
      if (formik?.values?.target) {
        const targetData = formik?.values?.target.find(
          (regItem) => regItem.plant_type_id === totalItem[0]
        )
        if (targetData) {
          if (totalItem[1].value > targetData.value) {
            isTargetValid = true
          }
        }
      }
    })
    return isTargetValid
  }

  useEffect(() => {
    let isTargetInvalid = false
    if (formik?.values?.sub_regional_target) {
      const targets = generateTotal(formik?.values?.sub_regional_target)
      setTotalTargetSubregional(targets)
      isTargetInvalid = !checkSubRegionalTargetWithTotalTarget(targets)
    }
    const debounceCheck = debounce(() => {
      toast.dismiss()
      if (!isTargetInvalid) {
        showToastValidationSubRegional()
      }
    }, 1000)
    debounceCheck()
    return () => {
      debounceCheck.cancel()
    }
  }, [formik?.values])

  function fetchData() {
    if (year && regionalId) {
      dispatch(getDetailTargetByYearTargetRegional(year, regionalId)).then(() => {
        return dispatch(getDetailTargetByYearTargetSubRegional(year, regionalId))
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [year, regionalId])

  useEffect(() => {
    uiService.setFullPage()
    return () => {
      uiService.setExitFullPage()
    }
  }, [])

  function onChangeUpload(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.AVATAR })).then(() => {
      setFileAttachments([e])
    })
  }

  useEffect(() => {
    if (MasterData.uploadType === UPLOAD.AVATAR) {
      setUrlDocument(MasterData.urlResponseUpload)
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])

  function onResetSubregionalTarget() {
    resetUpload()
    formik?.setFieldValue("sub_regional_target", FieldOfficer?.kpiGoalInitDataEditSubRegional)
  }

  function resetUpload() {
    setFileAttachments([])
    formik?.setFieldValue("document_url", null)
    setUrlDocument(null)
    setFileName("")
    dispatch(resetUrlResponse())
  }

  return {
    formik,
    onChangeUpload,
    urlDocument,
    fileAttachments,
    setFileAttachments,
    General,
    labelStep,
    activeStep,
    setActiveStep,
    uiService,
    activeForm,
    setActiveForm,
    totalTargetSubregional,
    handleStep,
    target,
    onResetSubregionalTarget,
    resetUpload
  }
}
