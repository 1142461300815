import React from "react"
import { ICON_CONSTANS } from "../../../constants/ImageConstants"

export const NoteView = ({
  className,
  icon,
  title,
  variant = "success",
  titleClassName,
  endContent,
  customVariantClassName = ""
}) => {
  function checkIcons() {
    if (!icon) {
      switch (variant) {
        case "success":
          return ICON_CONSTANS.IC_NOTE_VIEW_SUCCESS
        case "primary":
          return ICON_CONSTANS.IC_NOTE_VIEW_PRIMARY
        case "danger":
          return ICON_CONSTANS.IC_NOTE_VIEW_DANGER
        case "warning":
          return ICON_CONSTANS.IC_NOTE_VIEW_WARNING
        case "info":
          return ICON_CONSTANS.IC_NOTE_VIEW_INFO
        case "completed":
          return ICON_CONSTANS.IC_NOTE_VIEW_COMPLETED
        default:
          return ICON_CONSTANS.IC_NOTE_VIEW_SUCCESS
      }
    } else {
      return icon
    }
  }

  return (
    <>
      <div className={`alert mb-6 w-100 ${className}`}>
        <div
          className={`alert-item alert-${variant} ${customVariantClassName} d-flex align-items-center`}>
          <div className="d-flex align-items-center justify-content-between  w-100">
            <div className="d-flex align-items-center w-100 " style={{ flex: 1 }}>
              {checkIcons() && (
                <div>
                  <img
                    alt={title}
                    style={{ height: 19.5, width: 19.5, marginLeft: 2.25 }}
                    className="mr-2"
                    src={checkIcons()}
                  />
                </div>
              )}

              <div className={`text ${titleClassName}`} style={{ whiteSpace: "pre-line" }}>
                {title}
              </div>
            </div>
            {endContent && endContent}
          </div>
        </div>
      </div>
    </>
  )
}