import { useFormik } from "formik"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import swal from "sweetalert"
import * as Yup from "yup"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { useHtmlClassService } from "../../../../_metronic/layout"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { KPI_GOAL_SETTING_TAB } from "../../../../constants/StringConstant.js"
import { submitSettingTargetRegional } from "../../../../redux/actions/FieldOfficerAction.jsx"
import { fetchPlants, getDetailRegional } from "../../../../redux/actions/MasterDataAction"
import { getMinimumErrorNumber } from "../../../helpers/StringCollection.js"
import { loadUserLogin } from "../../../service/userManagementAction.js"
export function useSettingTargetPage() {
  const uiService = useHtmlClassService()
  const dispatch = useDispatch()
  const { MasterData, General } = useSelector((state) => state)
  const user = loadUserLogin()
  const regionalId = user?.regional_id
  const history = useHistory()

  const [dataTargetRegional, setDataTargetRegional] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [activeForm, setActiveForm] = useState(0)
  const [listSubRegional, setListSubRegional] = useState([])
  const [isDoneRegionalForm, setIsDoneRegionalForm] = useState(false)
  const [groupSubRegional, setGroupSubRegional] = useState([])
  const [isPreviewSubRegional, setIsPreviewSubRegional] = useState(false)
  const [isDoneSubRegional, setIsDoneSubRegional] = useState(false)

  const labelStep = [t("label.input_target"), t("label.preview")]
  const validationSchema = Yup.object().shape({
    target: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .test("is-not-zero", getMinimumErrorNumber(0), (val, ctx) => {
            if (ctx?.options?.index === 2) return true
            return val !== 0
          })
          .required("Value harus diisi")
      })
    )
  })
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      year: new Date().getFullYear(),
      target: [],
      sub_regionals: []
    },
    onSubmit: (value) => {
      const data = {
        year: value.year,
        regional: value.target.map((item) => {
          return {
            plant_type_id: item.plant_type_id,
            value: parseInt(item.value)
          }
        }),
        sub_regionals: value.sub_regionals.map((item) => {
          return {
            id: item.id,
            target: item.target.map((e) => {
              return {
                plant_type_id: e.plant_type_id,
                value: parseInt(e.value)
              }
            })
          }
        })
      }
      dispatch(submitSettingTargetRegional(regionalId, data))
    }
  })

  useEffect(() => {
    if (General?.isFinishUpdateOrDelete)
      swal({
        text: t("messages.response.success_setting_target_regional_and_sub_regional"),
        icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.IL_SUCCESS_SETTING_TARGET)
      }).then(() => {
        history.push(RoutesConstants.FIELD_OFFICER.KPI_GOAL_SETTING(KPI_GOAL_SETTING_TAB.THIS_YEAR))
      })
  }, [General?.isFinishUpdateOrDelete])

  function checkDisableButtonRegional() {
    const data = formik.values.target
    return data.some(
      (item, index) => index !== 2 && (isNaN(item.value) || parseInt(item.value) < 1)
    )
  }




  function checkDisableButtonSubRegional() {
    const data = formik.values.sub_regionals;
    return !data.every(item =>
      item.target.every(targetItem =>
        !isNaN(targetItem.value) && parseInt(targetItem.value) >= 0
      )
    );
  }
  useEffect(() => {
    const data = formik.values?.sub_regionals
    if (!data) return
    const grouped = data
      .flatMap((item) => item.target)
      .reduce((acc, curr) => {
        const id = curr.plant_type_id
        const value = Number(curr.value)

        if (!acc[id]) {
          acc[id] = { plant_type_id: id, label: curr.label, value: 0 }
        }
        acc[id].value += value
        return acc
      }, {})
    if (!grouped) return
    const result = Object.values(grouped)
    setGroupSubRegional(result)
  }, [formik.values?.sub_regionals])

  function showToastValidationSubRegional() {
    toast(t("label.note_view_setting_target_sub_regional_description"), {
      position: "top-center",
      autoClose: 5000,
      style: {
        background: "#353535"
      },
      icon: <SVG src={ICON_CONSTANS.IC_BULLET_CLOSE_RED} />,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark"
    })
  }

  useEffect(() => {
    uiService.setFullPage()
    return () => {
      uiService.setExitFullPage()
    }
  }, [])

  useEffect(() => {
    if (regionalId) {
      dispatch(fetchPlants())
      dispatch(getDetailRegional(regionalId))
    }
  }, [])

  useEffect(() => {
    if (!MasterData?.plantList) return
    const data = MasterData?.plantList
    const formData = data.map((item) => {
      return {
        plant_type_id: item.id,
        label: item.name
      }
    })
    formik.setFieldValue("target", formData)
  }, [MasterData?.plantList])

  function onResetRegionalForm() {
    if (!MasterData?.plantList) return
    const data = MasterData?.plantList
    const formData = data.map((item) => {
      return {
        plant_type_id: item.id,
        value: "",
        label: item.name
      }
    })
    formik.setFieldValue("target", formData)
  }

  useEffect(() => {
    if (activeForm === 1) {
      const handler = setTimeout(() => {
        if (!checkValue()) {
          showToastValidationSubRegional()
        }
      }, 500)

      return () => clearTimeout(handler)
    }
  }, [groupSubRegional, activeForm, formik.values.target])

  function checkValue() {
    const regional = formik.values?.target || []
    const subRegional = groupSubRegional || []

    const regionalMap = regional.reduce((acc, curr) => {
      acc[curr.plant_type_id] = parseInt(curr.value)
      return acc
    }, {})

    const result = subRegional.every(
      (item) => Number(item.value) <= (regionalMap[item.plant_type_id] || 0)
    )

    return result
  }
  function buildTargetSubRegional() {
    const data = MasterData?.plantList
    return data.map((item) => {
      return {
        plant_type_id: item.id,
        value: "",
        label: item.name
      }
    })
  }
  useEffect(() => {
    if (MasterData?.detailRegional && MasterData?.plantList) {
      const data = MasterData?.detailRegional?.list_sub_regional
      if (data) {
        setListSubRegional(data)
        const formDataSubRegional = data.map((item) => {
          return {
            id: item.id,
            regency_list: item.regency_list,
            name: item.name,
            target: buildTargetSubRegional()
          }
        })
        formik.setFieldValue("sub_regionals", formDataSubRegional)
      }
    }
  }, [MasterData?.detailRegional, MasterData?.plantList])

  function onNextFormRegional() {
    setIsDoneRegionalForm(true)
    setDataTargetRegional(formik.values.target)
    setActiveForm(1)
  }

  function onSubmitSubRegional() {
    setIsPreviewSubRegional(true)
    setIsDoneSubRegional(true)
  }

  function onClickEditSubRegional() {
    if (isDoneSubRegional) {
      setIsDoneSubRegional(false)
      setActiveForm(1)
      setIsPreviewSubRegional(false)
    }
  }

  function onResetSubRegional() {
    const data = formik.values?.sub_regionals.map((item) => {
      return {
        ...item,
        target: item.target.map((e) => {
          return {
            ...e,
            value: ""
          }
        })
      }
    })
    formik.setFieldValue("sub_regionals", data)
  }

  function checkDisableButtonSubmiFinal() {
    return !isPreviewSubRegional
  }

  return {
    uiService,
    labelStep,
    activeStep,
    setActiveStep,
    isDoneSubRegional,
    isPreviewSubRegional,
    formik,
    activeForm,
    dataTargetRegional,
    onNextFormRegional,
    onResetSubRegional,
    isDoneRegionalForm,
    setActiveForm,
    listSubRegional,
    groupSubRegional,
    onClickEditSubRegional,
    onSubmitSubRegional,
    checkDisableButtonRegional,
    checkDisableButtonSubmiFinal,
    onResetRegionalForm,
    checkDisableButtonSubRegional,
    checkValue
  }
}
