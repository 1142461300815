import { CardBody } from "../../_metronic/_partials/controls/index.js"
import React from "react"
import clsx from "clsx"

export function HeaderStepper({ index = 0, title = "", description, activeIndex }) {
  function checkActive() {
    return activeIndex === index
  }

  return (
    <CardBody
      style={{
        backgroundColor: checkActive() ? "#FFF" : "#F5F6F9"
      }}
      className={"border-right  d-flex align-items-center justify-content-center"}>
      <div className={"d-flex  align-items-center justify-content-center"} style={{ gap: 16 }}>
        <div
          className={`border ${
            checkActive() ? "bg-primary text-white" : "header-stepper-tab-inactive"
          } font-weight-bold d-flex align-items-center justify-content-center`}
          style={{ height: 32, width: 32, borderRadius: 4 }}>
          {index + 1}
        </div>
        <div>
          <div
            className={clsx("font-weight-bold", checkActive() ? "text-primary" : "text-dark-75")}>
            {title}
          </div>
          {description && <div>{description}</div>}
        </div>
      </div>
    </CardBody>
  )
}
