import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import SVG from "react-inlinesvg"
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers"
import { PrivilegeSelected } from "../../../../../app/service/userManagementAction"
import { useTranslation } from "react-i18next"

export function AsideLoad({ layoutProps, parent, titleMenu, fullTitleMenu = null }) {
  const menuTampil = PrivilegeSelected(parent)
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : ""
  }

  const { t } = useTranslation()

  return (
    <>
      {/* begin::Menu Nav */}

      <h2 className="menu-title">{t(fullTitleMenu !== null ? fullTitleMenu : titleMenu)}</h2>

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menuTampil.map((value, index) => {
          if (value.iconMenu) {
            return (
              <li
                key={index}
                className={`menu-item ${getMenuItemActive(value.navigateLink, false)}`}
                aria-haspopup="true">
                <NavLink className="menu-link" to={value.navigateLink}>
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl(value.iconMenu)} />
                  </span>
                  <span className="menu-text">{t(value.menuTitle)}</span>
                </NavLink>
              </li>
            )
          } else {
            return <div key={index}></div>
          }
        })}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
