import React, { useCallback, useEffect, useRef, useState } from "react"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { IconButton } from "@material-ui/core"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import clsx from "clsx"
import { useDispatch, useSelector } from "react-redux"
import {
  getKpiGoalThisYearTargetRegional,
  getKpiGoalThisYearTargetSubRegionalList
} from "../../../../../redux/actions/FieldOfficerAction.jsx"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { Skeleton } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { EmptyCard, Search, TableSkeleton, TableView } from "../../../../../component/index.jsx"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { Link, useHistory } from "react-router-dom"
import {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../component/atoms/Tabel/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import SVG from "react-inlinesvg"
import { useUrlParam } from "../../../../hooks/useUrlParams.jsx"
import { useLocation } from "react-router"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"

export function KPIGoalThisYear() {
  const dispatch = useDispatch()
  const { General, FieldOfficer } = useSelector((state) => state)
  const thisYear = new Date().getFullYear()
  const { t } = useTranslation()
  const history = useHistory()
  const user = loadUserLogin()
  const location = useLocation()
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [search, setSearch] = useState({ keyword: "" })
  const formRef = useRef()
  const [isTargetRegionalLoading, setIsTargetRegionalLoading] = useState(true)
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const urlParams = useUrlParam()
  const [currentParam] = useState({
    ...initialFilterTable
  })
  const privileges = loadUserLogin().privilege
  useEffect(() => {
    if (user?.regional_id) {
      dispatch(getKpiGoalThisYearTargetRegional(thisYear, user?.regional_id)).then(() =>
        setIsTargetRegionalLoading(false)
      )
    }
  }, [user?.regional_id])

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setIsActiveSearch(true)
    }
  }, [queryStringObj])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  function onSearch(value) {
    setSearch({ keyword: value.keyword })
    setIsActiveSearch(true)
    let param = {
      ...currentParam,
      keyword: value.keyword
    }
    const url = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: url })
  }

  function resetSearch() {
    let param = {
      ...currentParam,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ keyword: "" })
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...currentParam,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...currentParam,
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search, currentParam]
  )

  const fetchQueryParamData = (param) => {
    return dispatch(getKpiGoalThisYearTargetSubRegionalList(thisYear, user?.regional_id, param))
  }

  useEffect(() => {
    const result = location?.search
    if (user?.regional_id) fetchQueryParamData(result)
  }, [location.search, user?.regional_id])

  const columnProperties = [
    {
      text: t("table_header.name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-size-lg d-flex flex-column"}>
              <span className={"font-weight-bolder"}>{checkValue(e.sub_regional_name)}</span>
              {Array.isArray(e.regency_list) && <span>{e.regency_list.join(", ")}</span>}
            </div>
          </>
        )
      },
      headerStyle: () => {
        return { width: "45%" }
      }
    },
    {
      text: t("table_header.paddy"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-normal font-size-lg"}>
              {checkValue(String(e.paddy), "Ha")}
            </div>
          </>
        )
      }
    },
    {
      text: t("table_header.corn"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-normal font-size-lg"}>
              {checkValue(String(e.corn), "Ha")}
            </div>
          </>
        )
      }
    },
    {
      text: t("table_header.corn_production"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <div className={"font-weight-normal font-size-lg"}>
              {checkValue(String(e.corn_production), "Ha")}
            </div>
          </>
        )
      }
    },
    {
      text: "Forecast",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div>
            {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER) > -1 && (
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">Detail {e.regional_name}</Tooltip>}>
                <div className="btn btn-icon btn-detail btn-hover-secondary btn-md mx-3">
                  <DetailSvgIcon size="2x" />
                </div>
              </OverlayTrigger>
            )}
          </div>
        )
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    }
  ]

  return (
    <Card>
      <CardBody>
        {!isTargetRegionalLoading ? (
          FieldOfficer?.kpiGoalThisYearTargetRegional ? (
            <>
              <div className="mb-5 pb-5">
                <h2 className="font-weight-bolder">
                  {t("label.plant_target_year", { year: thisYear }).toUpperCase()}
                </h2>
              </div>
              <div className="mb-5 mt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="font-weight-bolder">{t("label.regional_target").toUpperCase()}</h4>
                  <Link
                    to={RoutesConstants.FIELD_OFFICER.EDIT_TARGET_REGIONAL(
                      new Date().getFullYear()
                    )}>
                    <IconButton disableRipple={true} disableFocusRipple={true}>
                      <img src={ICON_CONSTANS.IC_EDIT_BLACK} alt="edit" />
                    </IconButton>
                  </Link>
                </div>
                {FieldOfficer?.kpiGoalThisYearTargetRegional ? (
                  <Card className="rounded mt-4">
                    <CardBody
                      fit
                      className="rounded d-flex justify-content-between align-items-center bg-gray-card border-gray">
                      <div>
                        <h4>
                          {checkValue(FieldOfficer.kpiGoalThisYearTargetRegional.regional_name)}
                        </h4>
                        <span>
                          {checkValue(
                            FieldOfficer.kpiGoalThisYearTargetRegional.regional_head_name
                          )}
                        </span>
                      </div>
                      {(FieldOfficer?.kpiGoalThisYearTargetRegional?.target || []).length > 0 && (
                        <div className="d-flex">
                          {FieldOfficer?.kpiGoalThisYearTargetRegional?.target?.map(
                            (targetItem) => (
                              <div
                                key={targetItem.plant_type_id}
                                className={clsx(
                                  "separator-left-solid",
                                  "separator-border-2",
                                  "mr-4",
                                  "pl-4",
                                  "pr-4"
                                )}>
                                <span className="text-dark-50">
                                  {checkValue(targetItem.plant_type_name)}
                                </span>
                                <h4>{checkValue(targetItem.value, "Ha")}</h4>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                ) : (
                  <Skeleton animation="wave" className="rounded" variant={"rect"} height={100} />
                )}
              </div>
              <div className="mb-5 mt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="font-weight-bolder">
                    {t("label.target_sub_regional").toUpperCase()}
                  </h4>
                  <Link
                    to={RoutesConstants.FIELD_OFFICER.EDIT_TARGET_SUBREGIONAL(
                      new Date().getFullYear()
                    )}>
                    <IconButton disableRipple={true}>
                      <SVG src={ICON_CONSTANS.IC_EDIT_BLACK} alt="edit" />
                    </IconButton>
                  </Link>
                </div>

                <div className="filter-container mt-5 mb-4">
                  <Search
                    customClass="w-full"
                    isActiveSearch={isActiveSearch}
                    placeholder={t("placeholder.search_sub_regional")}
                    onSubmitForm={onSearch}
                    initialValues={search}
                    innerRef={formRef}
                    name={"keyword"}
                    trigerReset={resetSearch}
                  />
                </div>
                <Card className="mt-4">
                  <CardBody>
                    {General?.loading ? (
                      <TableSkeleton column={5} row={10} />
                    ) : (FieldOfficer?.pageData ?? []).length === 0 ? (
                      <EmptyCard
                        subtitle={
                          isActiveSearch ? t("messages.response.empty_search_subtitle") : ""
                        }
                        image={
                          isActiveSearch
                            ? ILLUSTRATION_ASSETS_V2.mitra.IL_EMPTY_STATE_NO_DATA
                            : ILLUSTRATION_ASSETS_V2.mitra.IL_EMPTY_STATE
                        }
                        body={
                          isActiveSearch
                            ? t("messages.response.searching_not_found")
                            : t("messages.response.empty_state", { name: "Target Regional" })
                        }
                      />
                    ) : (
                      ""
                    )}
                    <TableView
                      dataTable={FieldOfficer?.pageData ?? []}
                      currentPage={FieldOfficer?.page?.currentPage ?? 1}
                      currentElement={FieldOfficer?.page?.currentElement ?? 10}
                      totalCount={FieldOfficer?.page?.totalCount ?? 0}
                      columnProperties={columnProperties}
                      callbackAfterPageChange={changePage}
                      loadingGetData={General?.loading}
                      initialFilterTable={currentParam}
                      ignoreSinglePage
                      rowClasses={() => {
                        return "cursor-pointer"
                      }}
                    />
                  </CardBody>
                </Card>
              </div>
            </>
          ) : (
            <EmptyCard
              image={ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA}
              body={t("messages.response.empty_target_state", { year: thisYear })}
            />
          )
        ) : (
          <>
            <Skeleton
              animation="wave"
              className="rounded mb-10"
              variant={"text"}
              height={36}
              width={128}
            />
            <div className={"d-flex justify-content-between align-items-center mb-5"}>
              <Skeleton
                animation="wave"
                className="rounded"
                variant={"text"}
                height={36}
                width={128}
              />
              <Skeleton animation="wave" variant={"circle"} height={32} width={32} />
            </div>
            <Skeleton animation="wave" className="rounded mb-5" variant={"rect"} height={100} />
            <div className={"d-flex justify-content-between align-items-center mb-5"}>
              <Skeleton
                animation="wave"
                className="rounded"
                variant={"text"}
                height={36}
                width={128}
              />
              <Skeleton animation="wave" variant={"circle"} height={32} width={32} />
            </div>
            <TableSkeleton column={6} row={5} />
          </>
        )}
      </CardBody>
    </Card>
  )
}
