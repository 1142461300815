import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

/**
 *
 * @param flatIcon src/_metronic/_assets/plugins/flaticon/flaticon.css
 */
export function FlatIconAndText({ flatIcon, text, onClick, svg }) {
  const { General } = useSelector((state) => state)
  let classNameFlatIcon = "flaticon2-cd mr-2 font-size-lg"
  if (flatIcon) {
    classNameFlatIcon = flatIcon + " mr-2 font-size-lg"
  }
  let classNameDiv =
    "d-flex align-items-center text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
  if (onClick)
    classNameDiv = "text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
  return (
    <>
      {General.loading ? (
        <div className={classNameDiv}>
          <Skeleton animation="wave" variant={"circle"} width={16} height={16} className={"mr-2"} />
          <Skeleton animation="wave" variant={"text"} width={100} />
        </div>
      ) : (
        <div className={classNameDiv}>
          {svg ? (
            <>
              <SVG className="mr-2" src={toAbsoluteUrl(svg)} />
              {text}
            </>
          ) : (
            <>
              <i className={classNameFlatIcon} />
              {text}
            </>
          )}
        </div>
      )}
    </>
  )
}

/**
 *
 * @param flatIcon  src/_metronic/_assets/plugins/flaticon/flaticon.css
 */
export function FlatIconAndText2Row({
  title,
  description,
  onClick,
  svg,
  link,
  is_hst,
  status,
  rounded,
  enableFill = true,
  enableSeparator = false,
}) {
  const { General } = useSelector((state) => state)

  let classNameDiv = "d-flex align-items-center mr-5 mb-2"
  if (onClick) classNameDiv = "text-hover-primary d-flex align-items-center flex-lg-fill mr-5 mb-2"
  if (enableFill) classNameDiv = "d-flex align-items-center flex-lg-fill mr-5 mb-2"
  if (enableSeparator) classNameDiv = "d-flex align-items-center mr-5 mb-2 pr-7 separator-right-solid"
  if (onClick && enableFill) classNameDiv = "text-hover-primary flex-lg-fill d-flex align-items-center mr-5 mb-2"
  return (
    <>
      {General.loading ? (
        <div className={classNameDiv}>
          <Skeleton animation="wave" variant={"circle"} width={32} height={32} className={"mr-2"} />
          <div className="d-flex flex-column text-dark-75">
            <Skeleton animation="wave" variant={"text"} width={80} />
            <Skeleton animation="wave" variant={"text"} width={120} />
          </div>
        </div>
      ) : (
        <div className={classNameDiv}>
          <span className="mr-4">
            {svg ? (
              <>
                {rounded ? (
                  <div
                    style={{ height: 40, width: 40 }}
                    className={`d-flex  align-items-center justify-content-center bg-gray-card rounded-circle`}>
                    <SVG className="" src={toAbsoluteUrl(svg)} />
                  </div>
                ) : (
                  <SVG className="mr-2 mb-1" src={toAbsoluteUrl(svg)} />
                )}
              </>
            ) : (
              ""
            )}
          </span>
          <div className="d-flex flex-column text-dark-75">
            <span className="font-weight-bold text-dark-50 font-size-sm">{title}</span>
            <span className="font-weight-bolder font-size-h5">{description}</span>
          </div>
          {status === "active" && link && !is_hst && (
            <a className={"link ml-8"} onClick={link}>
              Remove
            </a>
          )}
        </div>
      )}
    </>
  )
}
