import React from "react"
import { Button, Card } from "react-bootstrap"
import { CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls/index.js"
import { t } from "i18next"
import Divider from "@material-ui/core/Divider"
import { FileList, NoteView, TextInput, UploadComponent } from "../../../../component/index.jsx"
import { FormikProvider } from "formik"
import { useEditTargetRegionalPage } from "./useEditTargetRegionalPage.js"
import { UploadIcon } from "../../../../component/atoms/Icons/SVG/upload.jsx"
import { VARIABLE_CONSTANT } from "../../../../constants/VariableConstant.js"
import { checkValue } from "../../../helpers/TextHelper.js"
import { FIELD_OFFICER_URL } from "../../../../constants/UrlConstants.js"
import { useHistory } from "react-router-dom"
import { MenuBack } from "../../../../component/atoms/MenuBack/index.jsx"
import { Stepper } from "../../../../component/atoms/Stepper/index.jsx"
import { Container } from "@material-ui/core"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

function EditTargetRegionalPage() {
  const page = useEditTargetRegionalPage()
  const history = useHistory()

  function header() {
    return (
      <div
        className={
          "bg-white d-flex justify-content-between align-content-center p-6 border-1 border-bottom border-bottom-light-dark"
        }>
        <MenuBack
          title={t("label.edit_yearly_target")}
          onClick={() => {
            page.uiService.setExitFullPage()
            history.goBack()
          }}
        />
        <Stepper
          step={page.labelStep}
          activeStep={page.activeStep}
          setActiveStep={page.setActiveStep}
        />
      </div>
    )
  }

  function formComponent() {
    return (
      <FormikProvider value={page.formik}>
        <Card>
          <CardBody grayBackround>
            <CardHeaderTitle>
              {`1. ${t("label.target_regional").toString().toUpperCase()}`}
            </CardHeaderTitle>
          </CardBody>
          <Divider />
          <CardBody>
            <div style={{ display: "grid", gap: 16 }}>
              <NoteView title={t("label.setting_target_note_view_create_edit")} />
              <TextInput type="number" endIcon={<img alt="calendar" src={ICON_CONSTANS.IC_CALENDER_INPUT} />} required label={t("label.year")} name={"year"} editable={false} />
              <div className="row">
                {page.formik.values.target.map((item, i) => (
                  <div key={i} className="col-12 col-sm-6 col-md-4">
                    <TextInput
                      required
                      type="number"
                      endIcon={"Ha"}
                      editable={true}
                      label={checkValue(item?.plant_type_name)}
                      name={`target[${i}].value`}
                      errorMessage={
                        page?.formik?.touched?.target &&
                        page?.formik?.touched?.target[i]?.value &&
                        page?.formik?.errors?.target &&
                        page?.formik?.errors?.target[i]?.value
                      }
                    />
                  </div>
                ))}
              </div>

              <div className={"position-relative"}>
                <UploadComponent
                  label={t("label.upload_paper_reason_of_changes")}
                  required={true}
                  onDeleteFileList={page.onRemoveFile}
                  name="document_url"
                  fileList={page.fileAttachments}
                  typeUpload={"pdf"}
                  editable={true}
                  onChange={page.onChangeUpload}
                  placeholder={<UploadIcon />}
                  maxSize={VARIABLE_CONSTANT.MAX_FILE_UPLOAD_PDF}
                  maxSizeText={VARIABLE_CONSTANT.MAX_SIZE_PDF}
                  isLoading={page?.General?.loading}
                />

                <div style={{ gap: 8, marginTop: 16 }} className="d-flex justify-content-end">
                  <button onClick={page.onClickReset} className="btn btn-outline-maxxi">
                    {t("button.reset")}
                  </button>
                  <Button onClick={() => page.formik.handleSubmit()} disabled={page.checkDisableButtonRegional()}>{t("button.save")}</Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </FormikProvider>
    )
  }

  function preview() {
    return (
      <div style={{ gap: 16, display: "grid" }} >
        <Card>
          <CardBody grayBackround>
            <div className={"d-flex justify-content-between align-items-center "}>
              <CardHeaderTitle>
                {`1. ${t("label.target_regional").toString().toUpperCase()}`}
                <img className={"pl-4"} src={ICON_CONSTANS.IC_CHECKLIST} alt={"checklist"} />
              </CardHeaderTitle>
              <div>
                <Button onClick={() => page.setActiveStep(0)} variant={"link"}>
                  {t("label.edit")}
                </Button>
              </div>
            </div>
          </CardBody>
          <Divider />
          <CardBody>
            <div className="">
              <div className="row  ">
                {page.formik.values.target.map((item, i) => (
                  <div
                    key={i}
                    className={`col  ${i !== page.formik.values.target.length - 1 ? "border-right" : ""
                      }`}>
                    <div className="text-muted">{checkValue(item?.plant_type_name)}</div>
                    <div className="font-weight-bold">{checkValue(item?.value)} Ha</div>
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
        <h2>{t("label.regional_change_reason_letter").toUpperCase()}</h2>
        {
          page?.fileAttachments &&
          <Card>
            <CardBody>
              {page?.fileAttachments.map((item, i) => (
                <FileList
                  name={item?.name}
                  size={item?.size}
                  key={i}
                  type={item?.type}
                />
              ))}
            </CardBody>
          </Card>
        }
      </div>
    )
  }

  return (
    <>
      {header()}
      <div className="pt-8">

        <Container>
          <NoteView
            title={t("label.download_example_document_for_edit_target_title")}
            variant={"warning"}
            endContent={
              <a
                target={"_blank"}
                href={FIELD_OFFICER_URL.REFERENCE_LETTER_EDIT_SUBMISSION_PDF()}
                className="link-warning text-warning link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                rel="noreferrer"
                download>
                {t("label.document_example")}
              </a>
            }
          />
          {page?.activeStep === 0 ? formComponent() : preview()}

          <div className="mt-8 w-full">
            <Button onClick={page.onSubmit} disabled={page.activeStep !== 1} className="w-100">{t("button.submit")}</Button>
          </div>
        </Container>
      </div>
    </>
  )
}

export default EditTargetRegionalPage
