import React, { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { TabsInputComponent } from "../../../../component/index.jsx"
import {
  ILLUSTRATION_ASSETS_V2,
  ILUSTRATION_CONSTANS
} from "../../../../constants/ImageConstants.js"
import { GENERAL } from "../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { FIELD_OFFICER_ROLE_ENUM, ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import {
  clearDetailFo,
  createFo,
  getDetail,
  getListRoleFO,
  updateFo
} from "../../../../redux/actions/FieldOfficerAction.jsx"
import {
  getListFieldManagerByUserLogin,
  getListFieldSupervisorByUserLogin,
  getListRegionalByUserLogin,
  getListRegionalHeadByUserLogin
} from "../../../../redux/actions/MasterDataAction.jsx"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../helpers/StringCollection.js"
import { DataFo } from "./step/DataFo.jsx"
import { PreviewFieldOfficer } from "./step/Preview.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"

export const FieldOfficerFormWrapper = ({
  history,
  match: {
    params: { id, tab }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const { FieldOfficer, MasterData, General } = useSelector((state) => state)
  const [stepsTitle, setStepsTitle] = useState(["Data Operational Team"])
  const { t } = useTranslation()
  const [isSubmit] = useState(true)
  const [formData, setFormData] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const [swalMessage, setSwalMessage] = useState("")
  const [successNavigationPath, setSuccessNavigationPath] = useState("")
  const [initialData, setInitialData] = useState({
    id: "",
    name: "",
    phone: "",
    email: "",
    avatar: "",
    foProvince: "",
    foPregency: "",
    foDistrict: "",
    foSubDistrict: "",
    foPostalCode: "",
    foAddress: "",
    roleFO: "",
    regional: "",
    regionalHead: "",
    regionalId: loadUserLogin().regional_id,
    subRegional: []
  })
  const [swalIcon, setSwallIcon] = useState("")
  const [role, setRole] = useState(null)

  const breadcrumbData = [
    {
      pathname:
        FieldOfficer?.detail.role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA
          ? RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.ACTIVE)
          : RoutesConstants.FIELD_OFFICER.LIST_FO(
              FieldOfficer?.detail.role ?? FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD
            ),
      title:
        FieldOfficer?.detail.role === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR
          ? t("sub_header.field_supervisor")
          : FieldOfficer?.detail.role === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER
          ? t("sub_header.field_manager")
          : FieldOfficer?.detail.role === FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD
          ? t("sub_header.regional_head")
          : typeof id !== "undefined"
          ? t("sub_header.fo")
          : t("menu.quick_access")
    },

    {
      title:
        typeof id !== "undefined"
          ? t("sub_header.edit_operational_team")
          : t("sub_header.add_ops_team")
    }
  ]

  useEffect(() => {
    if (!id) {
      if (!stepsTitle.includes(t("label.preview"))) {
        stepsTitle.push(t("label.preview"))
        setStepsTitle([...stepsTitle])
      }
    }
    subHeader.setBreadcrumbs(breadcrumbData)
    subHeader.setTitle(
      typeof id !== "undefined"
        ? t("sub_header.edit_operational_team")
        : t("sub_header.add_ops_team")
    )
  }, [id, FieldOfficer])

  useEffect(async () => {
    dispatch(clearDetailFo())
    if (id) {
      dispatch(getDetail(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType !== GENERAL.UPLOAD) {
      swal(swalMessage, {
        icon: swalIcon
      }).then(() => {
        if (id) {
          tab
            ? history.push(RoutesConstants.FIELD_OFFICER.DETAIL_FO_QA(role, tab, id))
            : history.push(RoutesConstants.FIELD_OFFICER.DETAIL_FO(role, id))
        } else {
          history.push(successNavigationPath)
        }
      })
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    dispatch(getListRoleFO())
    dispatch(getListRegionalByUserLogin())
    dispatch(getListRegionalHeadByUserLogin())
    dispatch(getListFieldManagerByUserLogin())
    dispatch(getListFieldSupervisorByUserLogin())
  }, [])

  useEffect(async () => {
    if (isSubmit) {
      if (window.location.href.includes("edit")) {
        subHeader.setButton(
          <Button
            variant="primary"
            onClick={() => {
              if (formRef.current) {
                formRef.current?.handleSubmit()
              }
            }}>
            UPDATE
          </Button>
        )
      }
    }
  }, [isSubmit])

  useEffect(async () => {
    if (!id) {
      subHeader.setButton(
        <Button
          variant="primary"
          disabled={activeStep !== renderComponent().steps.length - 1}
          onClick={() => {
            if (formRef.current) {
              formRef.current?.handleSubmit()
            }
          }}>
          SUBMIT
        </Button>
      )
    } else {
      if (window.location.href.includes("new")) {
        subHeader.setButton(false)
      }
    }
  }, [id, activeStep])

  useEffect(() => {
    const state = FieldOfficer?.detail
    if (state?.account_id && id) {
      setInitialData({
        avatar: state?.url_profile,
        email: state?.email,
        foProvince: state?.province_id,
        foRegency: state?.regency_id,
        foDistrict: state?.district_id,
        foSubDistrict: state?.sub_district_id,
        foPostalCode: state?.postal_code,
        foAddress: state?.address,
        name: state?.name,
        phone: state?.phone_number,
        roleFO: state?.role,
        regional: state?.regional_id,
        fieldSupervisor: state?.field_supervisor_id,
        fieldManager: state.field_manager_id,
        regionalHead: state?.regional_head_id,
        regionalId: loadUserLogin().regional_id,
        subRegional: state?.sub_regional_list
          ? state?.sub_regional_list.map((item) => item.id)
          : undefined,
        subRegionalByFm: state?.sub_regional_list ? state.sub_regional_list[0]?.id : undefined
      })
      setRole(state?.role === "FIELD_OFFICER" ? FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER : state?.role)
    }
  }, [FieldOfficer?.detail])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, getMinimumErrorString(3))
      .max(100, getMaximumErrorString(100))
      .required(getStringErrorMassage(t("label.name"), FORM_NEED.harusDiIsi)),
    email: Yup.string()
      .email("Invalid Email")
      .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Harus Angka")
      .min(8, getMinimumErrorString(8))
      .max(15, getMaximumErrorString(15))
      .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi)),
    roleFO: Yup.string()
      .required(getStringErrorMassage(t("label.role"), FORM_NEED.harusDiIsi))
      .nullable(),
    regional: Yup.string()
      .nullable()
      .when("roleFO", {
        is: FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD,
        then: Yup.string().required(
          getStringErrorMassage(t("label.regional"), FORM_NEED.harusDiIsi)
        )
      }),

    fieldManager: Yup.string()
      .nullable()
      .when("roleFO", {
        is: FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR,
        then: Yup.string().required(
          getStringErrorMassage(t("label.field_manager"), FORM_NEED.harusDiIsi)
        )
      }),
    fieldSupervisor: Yup.string()
      .nullable()
      .when("roleFO", {
        is: FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER,
        then: Yup.string().required(
          getStringErrorMassage(t("label.field_supervisor"), FORM_NEED.harusDiIsi)
        )
      }),
    regionalHead: Yup.string()
      .nullable()
      .when("roleFO", {
        is: FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER,
        then: Yup.string().required(
          getStringErrorMassage(t("label.regional_head"), FORM_NEED.harusDiIsi)
        )
      }),
    subRegionalByFm: Yup.string()
      .nullable()
      .when("roleFO", {
        is: FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR,
        then: Yup.string().required(
          getStringErrorMassage(t("label.sub_regional"), FORM_NEED.harusDiIsi)
        )
      }),
    subRegional: Yup.array()
      .nullable()
      .when("roleFO", {
        is: FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER,
        then: (schema) =>
          schema.min(1, getStringErrorMassage(t("label.sub_regional"), FORM_NEED.harusDiIsi)),
        otherwise: (schema) => schema.notRequired()
      }),
    foProvince: Yup.string().required(getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)),
    foRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
    foDistrict: Yup.string().required(getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)),
    foSubDistrict: Yup.string().required(getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)),
    foPostalCode: Yup.string(),
    foAddress: Yup.string()
      .required(getStringErrorMassage("Alamat ", FORM_NEED.harusDiIsi))
      .max(255, getMaximumErrorString(255))
  })

  const handleSpace = (e) => {
    if (formRef.current.values.name === "" || e.target.selectionStart === 0) {
      e.keyCode === 32 && e.preventDefault()
    }
  }

  const renderComponent = () => {
    const steps = ["Data Operational Team"]
    const stepsDescription = [t("label.user_information"), t("label.review_and_submit")]
    const flow = [<DataFo key={0} isReadOnly={false} onKeyDown={handleSpace} />]

    if (!id) {
      if (!steps.includes(t("label.preview"))) {
        steps.push(t("label.preview"))
        stepsDescription.push("ReviewIndividual & Submit")
        flow.push(<PreviewFieldOfficer formData={formData} />)
      }
    }

    return {
      steps,
      stepsDescription,
      flow
    }
  }

  const onSubmit = async ({ submited, values }) => {
    const provinces = MasterData?.provinceList ?? []
    const province_name = provinces?.find((e) => e?.id === values?.foProvince)?.name ?? "-"
    const regencies = MasterData?.regencyList ?? []
    const regency_name = regencies?.find((e) => e?.id === values?.foRegency)?.name ?? "-"
    const districts = MasterData?.districtList ?? []
    const district_name = districts?.find((e) => e?.id === values?.foDistrict)?.name ?? "-"
    const subDistricts = MasterData?.subDistrictList ?? []
    const sub_district_name =
      subDistricts?.find((e) => e?.id === values?.foSubDistrict)?.name ?? "-"
    const roleFO = FieldOfficer?.role?.find((e) => e?.name === values?.roleFO)
    const regional = MasterData.listRegionalAll?.find((e) => e?.id === values?.regional)
    const subRegionalByFm = MasterData.listSubRegionalByFm?.find(
      (e) => e?.id === values?.subRegionalByFm
    )
    const fieldSupervisor = MasterData.listFieldSupervisorByUserLogin?.find(
      (e) => e?.id === values?.fieldSupervisor
    )
    const fieldManager = MasterData.listFieldManagerByUserLogin?.find(
      (e) => e?.id === values?.fieldManager
    )
    const subRegional = MasterData.listSubRegionalByRh.filter((e) =>
      values.subRegional.includes(e.id)
    )
    const regionalHead = MasterData.listRegionalHeadByUserLogin?.find(
      (e) => e?.id === values?.regionalHead
    )
    setFormData({
      name: values?.name ?? "-",
      phone_number: values?.phone ?? "-",
      email: values?.email ?? "-",
      province_id: values?.foProvince,
      province_name,
      regency_id: values?.foRegency,
      regency_name,
      district_id: values?.foDistrict,
      district_name,
      sub_district_id: values?.foSubDistrict,
      sub_district_name,
      postal_code: values?.foPostalCode,
      address: values?.foAddress ?? "-",
      avatar: values?.avatar,
      roleFO: roleFO,
      regional: regional,
      regionalHead: regionalHead,
      subRegional:
        values?.roleFO === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR
          ? [subRegionalByFm]
          : subRegional,
      fieldManager: fieldManager,
      fieldSupervisor: fieldSupervisor
    })

    if (submited) {
      const payload = {
        name: values?.name,
        phone_number: values?.phone,
        email: values?.email,
        province_id: values?.foProvince,
        regency_id: values?.foRegency,
        district_id: values?.foDistrict,
        sub_district_id: values?.foSubDistrict,
        postal_code: values?.foPostalCode,
        full_address: values?.foAddress,
        url_profile: values?.avatar,
        regional_id: values?.regional,
        regional_head_id: values?.regionalHead,
        field_supervisor_id: values?.fieldSupervisor,
        role: values?.roleFO,
        sub_regional_ids:
          values?.roleFO === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR
            ? [values.subRegionalByFm]
            : values?.subRegional,
        field_manager_id: values?.fieldManager
      }

      if (id) {
        payload.field_officer_id = id
        dispatch(updateFo(payload))
        if (payload.role === FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER) {
          setSwalMessage(t("messages.response.edit_field_manager_success"))
        } else if (payload.role === FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD) {
          setSwalMessage(t("messages.response.edit_regional_head_success"))
        } else if (payload.role === FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR) {
          setSwalMessage(t("messages.response.edit_field_supervisor_success"))
        } else if (payload.role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA) {
          setSwalMessage(t("messages.response.edit_fo_qa_success"))
        } else if (payload.role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST) {
          setSwalMessage(t("messages.response.edit_fo_agronomist_success"))
        } else if (payload.role === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER) {
          setSwalMessage(t("messages.response.edit_fo_success"))
        } else {
          setSwalMessage(t("messages.response.edit_ops_team_success"))
        }
        setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_OPS_TEAM_EDIT))
        setRole(
          values?.roleFO === FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER
            ? FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER
            : values?.roleFO
        )
      } else {
        dispatch(createFo(payload))
        switch (values?.roleFO) {
          case FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER:
            setSwalMessage(t("messages.title.add_field_manager_successfully"))
            setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.SUCCESS_ASSIGN_GENERAL))
            setSuccessNavigationPath(
              RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_MANAGER)
            )
            return
          case FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD:
            setSwalMessage(t("messages.title.add_regional_head_successfully"))
            setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.SUCCESS_ASSIGN_GENERAL))
            setSuccessNavigationPath(
              RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD)
            )
            return
          case FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR:
            setSwalMessage(t("messages.title.add_field_supervisor_successfully"))
            setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.SUCCESS_ASSIGN_GENERAL))
            setSuccessNavigationPath(
              RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_SUPERVISOR)
            )
            return
          case FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER:
            setSwalMessage(t("messages.title.add_field_officer_successfully"))
            setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.SUCCESS_ASSIGN_GENERAL))
            setSuccessNavigationPath(
              RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER)
            )
            return
          default: {
            setSwalMessage(t("messages.response.add_ops_team_success"))
            setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.SUCCESS_ASSIGN_GENERAL))
            setSuccessNavigationPath(
              RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.REGIONAL_HEAD)
            )
            return
          }
        }
      }
    }
  }

  return (
    <>
      <TabsInputComponent
        isEditTabs={[undefined, null, ""].indexOf(id) < 0}
        steps={renderComponent().steps}
        stepDescription={renderComponent().stepsDescription}
        innerRef={formRef}
        formInitialValues={initialData}
        formId="formFo"
        componentTab={renderComponent().flow}
        validationSchema={[validationSchema]}
        onSubmitForm={onSubmit}
        onChangeStep={(activeStep) => {
          setActiveStep(activeStep)
        }}
      />
    </>
  )
}
