import React from "react"
import { MenuBack } from "../../../../component/atoms/MenuBack/index.jsx"
import { Stepper } from "../../../../component/atoms/Stepper/index.jsx"
import { useSettingTargetPage } from "./useSettingTargetPage.js"
import { useHistory } from "react-router-dom"
import { t } from "i18next"
import { Button, Card, Row } from "react-bootstrap"
import { CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls/Card.js"
import { Container, Divider } from "@material-ui/core"
import { NoteView, TextInput } from "../../../../component/index.jsx"
import { FormikProvider } from "formik"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"
import { checkValue } from "../../../helpers/TextHelper.js"

function SettingTargetPage() {
  const page = useSettingTargetPage()
  const history = useHistory()

  function header() {
    return (
      <div
        className={
          "bg-white d-flex justify-content-between align-content-center p-6 border-1 border-bottom border-bottom-light-dark"
        }>
        <MenuBack
          title={t("label.yearly_target")}
          onClick={() => {
            page.uiService.setExitFullPage()
            history.goBack()
          }}
        />
        <Stepper
          step={page.labelStep}
          activeStep={page.activeForm}
          setActiveStep={page.setActiveForm}
        />
      </div>
    )
  }

  function formRegional() {
    return (
      <Card>
        <CardBody grayBackround>
          <div className={"d-flex justify-content-between align-items-center "}>
            <CardHeaderTitle>
              {`1. ${t("label.target_regional").toString().toUpperCase()}`}
              {page?.activeForm !== 0 && (
                <img className={"pl-4"} src={ICON_CONSTANS.IC_CHECKLIST} alt={"checklist"} />
              )}
            </CardHeaderTitle>
            {page.activeForm !== 0 && (
              <div>
                <Button onClick={() => page.setActiveForm(0)} variant={"link"}>
                  {t("label.edit")}
                </Button>
              </div>
            )}
          </div>
        </CardBody>
        <Divider />
        <CardBody>
          {page.activeForm === 0 ? (
            <div style={{ display: "grid", gap: 8 }}>
              <NoteView title={t("label.setting_target_note_view_create_edit")} />
              <TextInput type={"number"} endIcon={<img src={ICON_CONSTANS.IC_CALENDER_INPUT} alt="caledar" />} required label={t("label.year")} name={"year"} editable={false} />
              <div className="row">
                {page.formik.values.target.map((item, i) => (
                  <div key={i} className="col-12 col-sm-6 col-md-4">
                    <TextInput
                      placeholder={"0"}
                      endIcon={"Ha"}
                      type={"number"}
                      errorMessage={
                        page?.formik?.touched?.target &&
                        page?.formik?.touched?.target[i]?.value &&
                        page?.formik?.errors?.target &&
                        page?.formik?.errors?.target[i]?.value
                      }
                      required
                      editable={true}
                      label={checkValue(item?.label)}
                      name={`target[${i}].value`}
                    />
                  </div>
                ))}
              </div>
              <div className="mt-8 mb-8">
                <Divider />
              </div>
              <div style={{ gap: 8 }} className="d-flex justify-content-end">
                <button onClick={page.onResetRegionalForm} className="btn btn-outline-maxxi">
                  {t("button.reset")}
                </button>
                <Button
                  disabled={page.checkDisableButtonRegional()}
                  onClick={() => page.onNextFormRegional()}>
                  {t("button.furthermore")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="row  ">
                {page.formik.values.target.map((item, i) => (
                  <div
                    key={i}
                    className={`col  ${i !== page.dataTargetRegional.length - 1 ? "border-right" : ""
                      }`}>
                    <div className="text-muted">{checkValue(item?.label)}</div>
                    <div className="font-weight-bold">{checkValue(item?.value)} Ha</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    )
  }

  function formSubRegional() {
    return (
      <Card>
        <CardBody grayBackround>
          <div className={"d-flex justify-content-between align-items-center "}>
            <CardHeaderTitle>
              {`2. ${t("label.target_sub_regional").toString().toUpperCase()}`}
              {page.isDoneSubRegional && page?.activeForm === 1 && (
                <img className={"pl-4"} src={ICON_CONSTANS.IC_CHECKLIST} alt={"checklist"} />
              )}
            </CardHeaderTitle>
            {page.isDoneSubRegional && page.activeForm === 1 && (
              <div>
                <Button onClick={page.onClickEditSubRegional} variant={"link"}>
                  {t("label.edit")}
                </Button>
              </div>
            )}
          </div>
        </CardBody>
        {page.isDoneRegionalForm && page.activeForm === 1 && (
          <>
            <Divider />
            <CardBody>
              <div style={{ display: "grid", gap: 24 }}>
                <>
                  <NoteView title={t("label.setting_target_note_view_create_edit")} />
                  <div className="d-flex w-100 justify-content-between">
                    <div>
                      <h5 className="font-weight-bold">{t("label.total_target_sub_regional")}</h5>
                      <p className="text-muted">
                        {t("label.note_view_setting_target_sub_regional_description")}
                      </p>
                    </div>
                    <div className="row w-50 ">
                      {page.groupSubRegional.map((item, i) => (
                        <div key={i} className="col border-left">
                          <div className="text-muted">{checkValue(item?.label)}</div>
                          <h3 className="font-weight-bold">{checkValue(item?.value)} ha</h3>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
                {!page.isPreviewSubRegional ? (
                  <>
                    {page.formik.values.sub_regionals.map((item, i) => (
                      <div key={i}>
                        <h2>{checkValue(item?.name)}</h2>
                        <div className="mb-8">{checkValue(item?.regency_list)}</div>
                        <Row>
                          {item.target &&
                            item.target.map((e, idx) => (
                              <div
                                key={item?.id + e.plant_type_id}
                                className="col-12 col-sm-6 col-md-4">
                                <TextInput
                                  required
                                  type="number"
                                  placeholder={"0"}
                                  editable={true}
                                  label={e.label}
                                  name={`sub_regionals[${i}].target[${idx}].value`}
                                />
                              </div>
                            ))}
                        </Row>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {page.formik.values.sub_regionals.map((item, i) => (
                      <div key={i} className="d-flex w-100 justify-content-between">
                        <div>
                          <h5 className="font-weight-bold">{item.name}</h5>
                          <p className="text-muted">{item.regency_list}</p>
                        </div>
                        <div className="row w-50 ">
                          {item.target.map((v, i) => (
                            <div key={i} className="col border-left">
                              <div className="text-muted">{v.label}</div>
                              <h3 className="font-weight-bold">{v.value} ha</h3>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {!page?.isPreviewSubRegional && (
                  <div className="d-flex justify-content-end mt-8" style={{ gap: 4 }}>
                    <Button onClick={page.onResetSubRegional} variant="outline-primary">
                      {t("button.reset")}
                    </Button>
                    <Button disabled={!page?.checkValue() && page?.checkDisableButtonSubRegional()} onClick={page.onSubmitSubRegional}>{t("button.furthermore")}</Button>
                  </div>
                )}
              </div>
            </CardBody>
          </>
        )}
      </Card>
    )
  }

  return (
    <div>
      {header()}
      <div className="mt-8">
        <FormikProvider value={page.formik}>
          <Container>
            {formRegional()}
            <div style={{ height: 48 }}></div>
            {formSubRegional()}
            <div style={{ height: 48 }}></div>
            <Button
              onClick={() => page.formik.handleSubmit()}
              disabled={page.checkDisableButtonSubmiFinal()}
              className={"w-100"}>
              {t("button.submit")}
            </Button>
          </Container>
        </FormikProvider>
      </div>
    </div>
  )
}

export default SettingTargetPage
