import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Search, TabsComponent } from "../../../../component/index.jsx"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { FoQaTabActive } from "./FoQaTabActive.jsx"
import { FoQaTabInActive } from "./FoQaTabInActive.jsx"
import { useHistory } from "react-router-dom"
import { useLocation, useParams } from "react-router"
import {
  GetListFoQAActive,
  GetListFoQAInactive
} from "../../../../redux/actions/FieldOfficerAction.jsx"
import { useDispatch } from "react-redux"
import { setDataTabMultiple } from "../../../../redux/actions/FilterAction.js"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"

export function ListFoQaPage() {
  const subHeader = useSubheader()
  const formRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const { tab } = useParams()
  const location = useLocation()
  const { t } = useTranslation()

  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const urlParams = useUrlParam()
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [search, setSearch] = useState({ keyword: "" })
  const [tabActive, setTabActive] = useState(tab === ROUTING_STATUS.STATUS_STRING.ACTIVE ? 0 : 1)

  useEffect(() => {
    setTabActive(tab === ROUTING_STATUS.STATUS_STRING.ACTIVE ? 0 : 1)
  }, [tab])

  useEffect(() => {
    subHeader.setTitle(t("sub_header.fo_qa").toUpperCase())
    subHeader.setButton(null)
  }, [])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search]
  )
  const changeTabs = (data) => {
    dispatch(setDataTabMultiple(data))
    setTabActive(data)
    let urlParam = ""
    if (search?.keyword) {
      let param = {
        ...initialFilterTable,
        keyword: search.keyword
      }
      urlParam = getUrlParsingPageFilter(param)
    }
    if (data === 1) {
      history.push(
        RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.INACTIVE) +
          urlParam ?? ""
      )
    } else {
      history.push(
        RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.ACTIVE) + urlParam ??
          ""
      )
    }
  }

  function onSearch(value) {
    setSearch({ keyword: value.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      keyword: value.keyword
    }
    const url = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: url })
  }

  function resetSearch() {
    let param = {
      ...initialFilterTable,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ keyword: "" })
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const getTabsContents = () => {
    const contents = [
      <FoQaTabActive
        changePage={changePage}
        isActiveSearch={isActiveSearch}
        key={0}
        history={history}
        initialFilterTable={initialFilterTable}
      />,
      <FoQaTabInActive
        changePage={changePage}
        isActiveSearch={isActiveSearch}
        key={1}
        history={history}
        initialFilterTable={initialFilterTable}
      />
    ]

    const labels = [t("label.active").toUpperCase(), t("label.inactive").toUpperCase()]

    return {
      labels,
      contents
    }
  }
  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setIsActiveSearch(true)
    }
  }, [queryStringObj])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  const fetchData = (param) => {
    if (tabActive === 0) {
      return dispatch(GetListFoQAActive(param))
    } else if (tabActive === 1) {
      return dispatch(GetListFoQAInactive(param))
    }
  }
  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  return (
    <>
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={t("placeholder.insert_fo_qa_search")}
            onSubmitForm={onSearch}
            initialValues={search}
            name={"keyword"}
            innerRef={formRef}
            trigerReset={resetSearch}
          />
        </div>
      </div>

      <RowModule>
        <TabsComponent
          changeTab={changeTabs}
          labelTab={getTabsContents().labels}
          componentTab={getTabsContents().contents}
          initValue={tabActive}
        />
      </RowModule>
    </>
  )
}
