import React from "react"
import Select from "react-select"
import { useFormikContext } from "formik"

export const TextFieldAutoComplete = ({
  name,
  label,
  options,
  required,
  value,
  placeholder,
  editable = true
}) => {
  const { setFieldValue, touched, errors } = useFormikContext()

  const handleChange = (selectedOptions) => {
    if (editable)
      setFieldValue(name, selectedOptions ? selectedOptions.map((opt) => opt.value) : [])
  }

  const getValue = () => {
    if (options && options.length > 0) {
      let data = []
      if (value) {
        data = options.filter((opt) => value.includes(opt.value))
      } else {
        data = options.filter((opt) => value?.includes(opt.value))
      }
      return data
    } else {
      return []
    }
  }

  return (
    <div className="multi-select-container">
      <label>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>

      <Select
        isMulti
        name={name}
        options={options}
        value={getValue() || []}
        onChange={handleChange}
        className="react-select-container"
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        isClearable={true}
        isDisabled={!editable}
        hideSelectedOptions={false}
        filterOption={(option, inputValue) => {
          const label = option.data?.label ? String(option.data?.label).toLowerCase() : ""
          return label.includes(inputValue.toLowerCase())
        }}
        getOptionLabel={(e) => (
          <div className="custom-option">
            <div className="checkbox-container">
              <input type="checkbox" checked={value?.includes(e.value)} readOnly />
              <span className="checkmark"></span>
            </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <span
                className="font-black fw-normal "
                style={{ fontWeight: "normal", color: "black" }}>
                {e.label}
              </span>
              {e.description && <span className="mt-1">{e.description}</span>}
            </div>
          </div>
        )}
        components={{
          MultiValueContainer: () => null,
          MultiValueRemove: () => null,
          MultiValue: (props) => {
            return (
              <div>
                {props.index !== 0 && ", "}
                {props?.data?.label}{" "}
              </div>
            )
          }
        }}
        placeholder={placeholder || ""}
        styles={{
          option: (base) => ({
            ...base,
            backgroundColor: "white"
          })
        }}
      />

      <div
        className="chip-container"
        style={{ marginTop: "10px", display: "flex", gap: "8px", flexWrap: "wrap" }}>
        {getValue().map((opt) => (
          <div
            key={opt.value}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#E8F5E9",
              color: "#4CAF50",
              padding: "6px 12px",
              borderRadius: "20px",
              fontSize: "14px",
              fontWeight: "normal",
              border: "1px solid #4CAF50"
            }}>
            {opt.label}
            <span
              onClick={() => handleChange(getValue().filter((item) => item.value !== opt.value))}
              style={{
                marginLeft: "8px",
                cursor: "pointer",
                fontWeight: "bold"
              }}>
              ✕
            </span>
          </div>
        ))}
      </div>

      {touched[name] && errors[name] && (
        <div className="error-text" style={{ color: "red", marginTop: "5px" }}>
          {errors[name]}
        </div>
      )}
    </div>
  )
}
